import React from 'react';
import { withTranslation } from 'react-i18next';
import CustomStore from 'devextreme/data/custom_store';
import { sendRequest } from '../../utils/utils';
import { HOST_API } from "../../env";

import Accordion from 'devextreme-react/accordion';
import DataGrid, {
  Column,
  Pager,
  Paging,
  // FilterRow,
  // Lookup,
  // Form,
  // Toolbar,
  Editing,
  // Popup,
  // RequiredRule,
  // PatternRule,
  // MasterDetail
} from 'devextreme-react/data-grid';

function personnelShifts({t, clientId}) {
  const CustomItem = () => {
    return (
      <DataGrid
        className={'dx-card wide-card'}
        keyExpr="sh_id_sh"
        dataSource={ShiftStore}
        showBorders={true}
        remoteOperations={false}
        focusedRowEnabled={true}
        // defaultFocusedRowIndex={0}
        rowAlternationEnabled={true}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        showColumnHeaders={false}
        >
        <Editing
          mode="raw"
          allowAdding={true} 
          allowUpdating={true}
          allowDeleting={true}>
        </Editing>
        
        <Paging defaultPageSize={10} />
        <Pager
          showPageSizeSelector={true}
          showInfo={true}
          allowedPageSizes={[10,50,100]}
        />
        <Column dataField={'sh_shift'} caption={t('shift')} />
        {/*
        <Toolbar className="GridToolbar">
          <Item location="before">
            <h5>{t('shifts')}</h5>
          </Item>
          <Item location="after" name="addRowButton" groupIndex={0}/>
          <Item name="columnChooserButton"/>
        </Toolbar> */}
      </DataGrid>
    );
  }

  const ShiftStore = new CustomStore({
    key: 'sh_id_sh',
    load: (loadOptions) => sendRequest(`${HOST_API}/v/shifts`,loadOptions,'GET',{filter: ['["sh_id_com","=",'+clientId+']'], sort: ['[{"selector":"sh_shift","asc":true}]']}),
    insert: (values) => {
      return sendRequest(`${HOST_API}/shifts`, null, 'POST', {...values, sh_id_com:clientId })
    },
    update: function(key, values){
      let res = sendRequest(`${HOST_API}/shifts/update`, null, 'POST', {...values,sh_id_com:clientId,sh_id_sh:key})
      // console.log("res = " , res)
      // return "Error";
      return res;
    },
    remove: (key) => sendRequest(`${HOST_API}/shifts`,null, 'DELETE', {
      key,
    }),
  })

  return (
    <React.Fragment>
        <Accordion
          className={"TeamsShiftsAccordion"}
          dataSource={[{ID: 1}]}
          collapsible={true}
          //animationDuration={animationDuration}
          itemTitleRender={e =>{
            return <span>{t('shifts')}</span>
          }}
          itemRender={CustomItem}
          onContentReady={e => {
            e.component.collapseItem(0);
          }}
        />
    </React.Fragment>
  )
}

export default withTranslation()(personnelShifts);