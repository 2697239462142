// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./dark-mode.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./light-mode.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".switch-mode-customize {\r\n  align-self: center;\r\n}\r\n\r\n.night-mode-switch-customize {\r\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") center no-repeat;\r\n  background-size: 1.5em 1.5em;\r\n  /*background-color: #ff5722;*/\r\n  width: 27px;\r\n  height: 26px;\r\n  display: inline-block;\r\n  margin-right: 5px;\r\n  vertical-align: middle;\r\n}\r\n\r\n.light-mode-switch-customize{\r\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") center no-repeat;\r\n  background-size: 1.5em 1.5em;\r\n  /*background-color: #ff5722;*/\r\n  width: 27px;\r\n  height: 26px;\r\n  display: inline-block;\r\n  margin-right: 5px;\r\n  vertical-align: middle;\r\n  \r\n}\r\n\r\n.btn-customize:not(:disabled):not(.disabled) {\r\n  cursor: pointer;\r\n}", ""]);
// Exports
module.exports = exports;
