import './styles.scss';
import React, {useEffect, useState} from 'react';
import { withTranslation } from 'react-i18next';

import Chart, {
  CommonSeriesSettings,
  Crosshair,
  Export,
  Grid,
  HorizontalLine,
  Label,
  Legend,
  LoadingIndicator,
  Margin,
  Pane,
  Point,
  Series,
  Title,
  Tooltip,
  ValueAxis,
} from 'devextreme-react/chart';

import { Item } from 'devextreme-react/responsive-box';

import DateBox from 'devextreme-react/date-box';
import Toolbar from 'devextreme-react/toolbar';
// import CustomStore from 'devextreme/data/custom_store';
// import { activityOverviewData } from './test_data.js';
import { formatDate } from 'devextreme/localization';

import { HOST_API } from "../../../env";
import { sendRequest } from '../../../utils/utils';

import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';

function ActivityOverviewChart({t}) {

  const [activeMonth, setActiveMonth] = useState(0);
  
  const currentDate = new Date();
  const testDate = new Date(2022, 0, 1, 0, 0, 0);
  
  useEffect(() => {
    setActiveMonth(formatDate(testDate, "yyyy-MM"));
  }, []);
  
  const chartDataSource = new CustomStore({
    load: (loadOptions) => sendRequest(`${HOST_API}/v/fuelActivity/${activeMonth}`, loadOptions, 'GET'),
    onLoaded: function (result) {
      // clientTripDetailsStoreData = result.data;
    }
  })
  const onMonthValueChanged = (data) => {
    setActiveMonth(formatDate(data.value, "yyyy-MM"));
  };

  const formatNumber = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 0,
  }).format;

  const ActivityOverviewChartTooltip = (info) => {
    let fuelrefillText = ""
    if(info.point.data.fuelrefill) fuelrefillText = formatNumber(info.point.data.fuelrefill)+" "+t('MAD');

    let totalrevText = ""
    if(info.point.data.totalrev) totalrevText = formatNumber(info.point.data.totalrev)+" "+t('MAD');

    return (
      <div className="ao-chart-tooltip">
        <div className="day">
          <span className="day">{t('day')}</span>: {info.point.data.day}
        </div>
        <div className="fuel">
          <span className="fuel">{t('fuel')}</span>: {fuelrefillText}
        </div>
        <div className="trips">
          <span className="trips">{t('trips')}</span>: {info.point.data.totaltrips}
        </div>
        <div className="revenue">
          <span className="revenue">{t('revenue')}</span>: {totalrevText}
        </div>
      </div>
    );
  }
  
  return (
    <React.Fragment>
      <div id="ActivityOverviewChartContainer" className={'dx-card responsive-paddings'}>
        <Toolbar>
          <Item>
            <h4>{t("activity overview")}</h4>
          </Item>
          <Item location="after">
          {/* <span className="label">{t("choose a month:")}</span> */}
          </Item>
          <Item location="after">
            <DateBox
              type="date"
              defaultValue={currentDate}
              acceptCustomValue={false}
              displayFormat="monthAndYear"
              calendarOptions={
                {
                  maxZoomLevel: 'year', 
                  minZoomLevel: 'century',
                }
              }
              onValueChanged={onMonthValueChanged}
            />
          </Item>
        </Toolbar>
        <Chart
          palette="Soft Blue"
          dataSource={chartDataSource}
          defaultPane="TripsPane"
          // title="Activity overview"
          >
          <Margin right={10} />
          <LoadingIndicator enabled={true} />
          <CommonSeriesSettings argumentField="day">
            <Point visible={true} />
          </CommonSeriesSettings>
          <Series
            pane="FuelPane"
            name={t('fuel refill')}
            type="line"
            color="#76adec"
            valueField="fuelrefill"
            >
            {/* <Label
              visible={true}
              // customizeText={fuelCustomizeText}
            /> */}
          </Series>
          <Series
            pane="TripsPane"
            name={t('total trips')}
            type="bar"
            color="#ffb7b7"
            valueField="totaltrips"
            >
            <Label visible={false} />
          </Series>
          <Series
            pane="RevPane"
            name={t('total revenue')}
            type="line"
            color="#ffc493"
            valueField="totalrev"
            >
            <Label visible={false} />
          </Series>

          <Pane name="FuelPane" />
          <Pane name="RevPane" margin={-10}>
            <Label visible={false} />
            
            <Margin left={0} top={-10} />
          </Pane>
          <Pane name="TripsPane" />

          <ValueAxis pane="FuelPane">
            <Grid visible={true} />
            <Title text={t('fuel refill')} />
          </ValueAxis>

          <ValueAxis pane="RevPane">
            <Grid visible={true} />
            <Title text={t('revenue')} />
          </ValueAxis>

          <ValueAxis pane="TripsPane">
            <Grid visible={true} />
            <Title text={t('total trips')} />
          </ValueAxis>
          
          <Legend visible={false} />
          
          <Tooltip
            enabled={true}
            shared={true}
            contentRender={ActivityOverviewChartTooltip}
            />
          <Crosshair enabled={true}>
            <HorizontalLine visible={false}/>
          </Crosshair>
        </Chart>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(ActivityOverviewChart);