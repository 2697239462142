import React /*, {useState} */ from 'react';
import { withTranslation } from 'react-i18next';
import tripDetailView from './tripDetailView';
import 'devextreme/data/odata/store';
import DataGrid, {
  Column,
  Format,
  Button,
  Pager,
  Paging,
  FilterRow,
  ColumnChooser,
  Lookup,
  Form,
  Editing,
  Popup,
  RequiredRule,
  PatternRule,
  MasterDetail,
  Toolbar,
  Item,
  LoadPanel
} from 'devextreme-react/data-grid';

import { GroupItem, SimpleItem, EmptyItem, Label} from 'devextreme-react/form';

/////// 

import CustomStore from 'devextreme/data/custom_store';
// import DataSource from 'devextreme/data/data_source';
// import { Item } from 'devextreme-react/form';
import { formatDate } from 'devextreme/localization';
import 'whatwg-fetch';
import { HOST_API } from "../../env";
// import $ from "jquery";
///////


const allowedPageSizes = [8, 12, 20];

function isNotEmpty(value) {
  return value !== undefined && value !== null && value !== '';
}

const   sendRequest = async (url, loadOptions = null, method = 'GET', data = {})  =>{
  //console.log("")
 logRequest(method, url, data);
//$('.com_id_ct_test :input');
 let token  = localStorage.getItem("token");
  if (method === 'GET') {
    let params = '?';
    [
      'skip',
      'take',
      'requireTotalCount',
      'requireGroupCount',
      'sort',
      'filter',
      'totalSummary',
      'group',
      'groupSummary',
    ].forEach((i) => {

      //console.log("i+++",i, loadOptions[i])
      if (i in loadOptions && isNotEmpty(loadOptions[i])) {
        params += `${i}=${JSON.stringify(loadOptions[i])}&`;

         }
    });
    for (const [key, value] of Object.entries(data)) {
      //console.log(`${key}: ${value}`);
      if(key === "filter"){
          params += `${key}=[${value}]&`;
      }
    }
    params = params.slice(0, -1);
    return fetch(url+params, {
      method,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token
      }
    }).then((result) => result.json().then((json) => {
      console.log(json)
      if (result.ok) {
        return {
          data: json.data,
          totalCount: json.totalCount,
          summary: json.summary,
          groupCount: json.groupCount,
        };
      }
      throw json.Message;
    }));
  }

  // const params = Object.keys(data).map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`).join('&');
  if(method === "DELETE"){
    url = `${url}/${data.key}`
  }
  return fetch(url, {
    method,
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    }
  }).then((result) => {
    if (result.ok) {
      return result.text().then((text) => text && JSON.parse(text));
    }
    return result.json().then((json) => {
      throw json.Message;
    });
  });
}
const  logRequest = (method, url, data)  => {
  const args = Object.keys(data || {}).map((key) => `${key}=${data[key]}`).join(' ');

  const time = formatDate(new Date(), 'HH:mm:ss');
  const request = [time, method, url.slice(URL.length), args].join(' ');
  console.log(request)
}
//const notesEditorOptions = { height: 100 };

  var clientIdTmp = 0
  var filterReq = ''
function Trips({t}) {

  const dxDateBoxOptions = {
    displayFormat: "dd/MM/yyyy HH:mm",
    dateSerializationFormat: "yyyy-MM-dd HH:mm",
    type: "datetime",
    showClearButton: true
  };

  const CompanySitesStore = new CustomStore({
    key: "cos_id_cos",
    loadMode: "raw",
    load: (loadOptions) => sendRequest(`${HOST_API}/v/sites`, loadOptions, 'GET', {skip:0, take:99999, requireTotalCount: "false", /*filter:['["cos_id_com","=","'+clientId+'"]'],*/ sort: ['[{"selector":"cos_name","asc":true}]'] })
  });
  
  const CompaniesStore = new CustomStore({
    key: "com_id_com",
    loadMode: "raw",
    load: (loadOptions) => sendRequest(`${HOST_API}/v/companies`, loadOptions, 'GET', {skip:0, take:99999, requireTotalCount: "false",/* filter:['["ct_type","!=","client"]'],*/ sort: ['[{"selector":"com_name","asc":true}]'] })
  });

  const getFilteredVehicles = new CustomStore({
    key: "v_id_v",
    loadMode: "raw",
    //load: (loadOptions) => sendRequest(`${HOST_API}/v/geopoints`, loadOptions, 'GET', {skip:0, take:99999, requireTotalCount: "false", filter:['["gp_id_compv","=","'+clientIdTmp+'"]'], sort: ['[{"selector":"gp_name","asc":true}]'] })
    load: (loadOptions) => sendRequest(`${HOST_API}/v/vehicles`, loadOptions, 'GET', {skip:0, take:99999, requireTotalCount: "false", sort: ['[{"selector":"v_label","asc":true}]'] })
  });
  
  const  getFilteredDrivers = (options) => {
    let driverFilter = options.data ? '["p_id_com","=","'+options.data.v_id_com+'"]' : null;
    return {
      store: new CustomStore({
        key: "v_id_pdrv",
        loadMode: "raw",
        load: (options) => sendRequest(`${HOST_API}/v/drivers`, options, 'GET', {skip:0, take:99999, requireTotalCount: "false", filter:[driverFilter], sort: ['[{"selector":"p_first_name","asc":true}]'] })
      }),
      filter: options.data ? ['p_id_com', '=', options.data.v_id_com] : null,
    };
  }

  const store  = new CustomStore({
    load: (loadOptions) => sendRequest(`${HOST_API}/v/trips`,loadOptions,'GET', filterReq),
    insert: (values) => {
    },
    key: 'tr_id_tr',
    update: function(key, values){
    },
    remove: (key) => sendRequest(`${HOST_API}/trips`,null, 'DELETE', {
      key,
    }),
  })

  const CompanieStore = new CustomStore({
    key: "com_id_com",
    loadMode: "raw",
    load: (loadOptions) => {
      return sendRequest(`${HOST_API}/v/companies`, loadOptions, 'GET', {skip:0, take:99999, requireTotalCount: "false", sort: ['[{"selector":"com_name","asc":true}]'] });
    },
  });

  const TripDirectionStore = [
    {directionTitle: t('in'), directionVal: 'in'},
    {directionTitle: t('out'), directionVal: 'out'}
  ]

  const TripPriceStore = [
    {cpr_name: t('default'), cpr_price: 90},
    // {directionTitle: t('out'), directionVal: 'out'}
  ]

  function setCompanyValue(rowData, value){
    rowData.tr_id_p_dr = null;
    rowData.tr_id_v = null;
    this.defaultSetCellValue(rowData, value);
  }
  // clientIdTmp  = clientId;
  // if(clientIdTmp !== 0){
  //     filterReq = '{"filter":["cos_id_com","=",'+clientIdTmp+']}';
  // }
  
  return (
    <React.Fragment>
      <h2 className={'content-block'}>{t('trips')}</h2>
        {/* <div className={'dx-card responsive-paddings'}> */}
          <DataGrid
            className={'dx-card wide-card'}
            keyExpr="tr_id_tr"
            dataSource={store}
            cacheEnabled={false}
            showBorders={true}
            remoteOperations={true}
            focusedRowEnabled={true}
            // defaultFocusedRowIndex={0}
            rowAlternationEnabled={true}
            columnAutoWidth={true}
            columnHidingEnabled={true}
            allowColumnReordering={true}
            allowColumnResizing={true}
            
            // onRowExpanding={function(e){
            //     e.component.collapseAll(-1);
            //   }
            // }
            >
            <MasterDetail
              enabled={true}
              component={tripDetailView}
            /> 
            <Editing
              mode="popup"
              allowAdding={true}
              allowUpdating={true}
              allowDeleting={true}>
              <Popup title={t('trip')} showTitle={true} shading={true} resizeEnabled={true} dragEnabled={true} height={500} />
              <Form>
                {/* <GroupItem colCount={3} colSpan={2}>
                  <SimpleItem dataField="tr_id_cos"/>
                  <SimpleItem dataField="tr_direction"/>
                  <EmptyItem/>
                </GroupItem>
                <GroupItem colCount={3} colSpan={2}>
                  <SimpleItem dataField="tr_name"/>
                  <SimpleItem dataField="tr_datetime" editorType="dxDateBox" editorOptions={dxDateBoxOptions} />
                  <SimpleItem dataField="tr_price" />
                </GroupItem>
                <GroupItem colCount={3} colSpan={2}>
                  <SimpleItem dataField="v_id_com" />
                  <SimpleItem dataField="tr_id_v"/>
                  <SimpleItem dataField="tr_id_p_dr"/>
                </GroupItem> */}
              </Form>
            </Editing>
            
            <Paging defaultPageSize={10} />
            <Pager
              showPageSizeSelector={true}
              showInfo={true} 
              showPageSizeSelector={true}
              allowedPageSizes={[10, 25, 50, 100, 500, 1000]}/>
            <FilterRow visible={true} />

            <Column dataField={'com_name'} 	caption={t('company name')} hidingPriority={4} />
            <Column dataField={'cos_name'} 	caption={t('site name')} hidingPriority={5} />
            <Column dataField={'tr_name'}   caption={t('trip name')} hidingPriority={10} >
              <RequiredRule />
            </Column>
            <Column dataField={'tr_datetime'} dataType="datetime" format="dd/MM/yyyy HH:mm" caption={t('trip time')} hidingPriority={10} >
              <RequiredRule />
            </Column>
            <Column dataField={'tr_direction'} caption={t('direction')} hidingPriority={8}>
              <RequiredRule />
              <Lookup
                dataSource={TripDirectionStore}
                displayExpr="directionTitle"
                valueExpr="directionVal" 
                // defaultValue={TripDirectionStore[0]}
                searchEnabled={false}
                />
            </Column>
            <Column dataField={'p_phone'} caption={t('driver phone')} hidingPriority={7}/>
            <Column dataField={'p_first_name'}			caption={t('first name')} hidingPriority={3} />
            <Column dataField={'p_last_name'}		caption={t('last name')} hidingPriority={2} />
            <Column dataField={'v_label'} 		caption={t('label')} hidingPriority={6} />
            <Column dataField={'v_registration_plate'} 		caption={t('registration_plate')} hidingPriority={1} /> 
            
            <Toolbar>
              <Item location="after">
                <Button
                  icon='refresh'
                  // onClick={this.refreshDataGrid}
                  />
              </Item>
              <Item
                name="addRowButton"
              />
            </Toolbar>
            <Paging defaultPageSize={8} />
            <Pager
              showPageSizeSelector={true}
              allowedPageSizes={allowedPageSizes}
            />
            </DataGrid>        
      {/* </div> */}
    </React.Fragment>
)}

export default withTranslation()(Trips);

