// import React, {useState} from 'react';
import { formatDate } from "devextreme/localization";

export function isNotEmpty(value) {
  return value !== undefined && value !== null && value !== "";
}

export const logRequest = (method, url, data) => {
  const args = Object.keys(data || {})
    .map((key) => `${key}=${data[key]}`)
    .join(" ");
  const time = formatDate(new Date(), "HH:mm:ss");
  const request = [time, method, url.slice(URL.length), args].join(" ");
  console.log("request:::", request);
};

export const sendRequest = async (
  url,
  loadOptions = null,
  method = "GET",
  data = {}
) => {
  logRequest(method, url, data);
  // console.log("loadOptions")
  // console.log(loadOptions)
  let params = "";
  if (data)
    params = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
      )
      .join("&");

  let fetchOptions = {
    method,
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  if (method === "GET") {
    delete fetchOptions.body; // remove the POST data for all GET requests
    if (isNotEmpty(loadOptions)) {
      [
        "skip",
        "take",
        "requireTotalCount",
        "requireGroupCount",
        "sort",
        "filter",
        "totalSummary",
        "group",
        "groupSummary",
      ].forEach((i) => {
        if (i in loadOptions && isNotEmpty(loadOptions[i])) {
          // params += `&${i}=${JSON.stringify(loadOptions[i])}`;

          if (i !== "filter" || loadOptions[i][2].length > 0) {
            // console.log(loadOptions[i][2].length+"+++");
            params += `&${i}=${JSON.stringify(loadOptions[i])}`;
          }
        }
      });
    }
    if (params !== "") {
      if (params.match(/^&/)) params = params.slice(1);
      if (url.split("?").length <= 1) params = "?" + params;
      url = url + params;
    }
  } else if (method === "DELETE") {
    // url = `${url}/${data.key}`
    console.log("data::::", data);
    url = url + "/" + data.key;
  }

  return fetch(url, fetchOptions).then((result) =>
    result.json().then((json) => {
      if (result.ok) {
        return json;
        // return {
        //   data: json.data,
        //   totalCount: json.totalCount,
        //   summary: json.summary,
        //   groupCount: json.groupCount,
        // };
      }
      throw json.Message;
    })
  );
};
