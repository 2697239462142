
import React, { useState, useRef, useCallback, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  CustomRule,
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import notify from 'devextreme/ui/notify';
import { changePassword, logOut } from '../../api/auth';
import { HOST_API } from "../../env";
import './profile.scss';
/*import React, { useState } from 'react';
import Form from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import ChangePasswordForm from "../../components/change-password-form/change-password-form";*/
//https://codepen.io/JavaScriptJunkie/pen/jvRGZy

function Profile({t}) {
// export default function Profile({t}) {
  const userScolet = {
    gp_address: "--",
    gp_city: "--",
    gp_description: "--",
    gp_geofence: "--",
    gp_lat: "--",
    gp_lng: "--",
    gp_name: "--",
    gp_point: "--",
    p_address: "--",
    p_cnie: "--",
    p_district: "--",
    p_email: "--",
    p_first_name: "--",
    p_id_p: "--",
    p_last_name: "--",
    p_phone: "--",
    sh_shift: "--",
    tm_team: "--",
  }
  const [user, setUser] = useState({...userScolet});
  const [loading, setLoading] = useState(false);
  const formData = useRef({});

  const oldPasswordEditorOptions = { stylingMode: 'filled', placeholder: t('old password'), mode: 'password' };
  const passwordEditorOptions = { stylingMode: 'filled', placeholder: t('password'), mode: 'password' };
  const confirmedPasswordEditorOptions = { stylingMode: 'filled', placeholder: t('confirm password'), mode: 'password' };


  useEffect(() => {
    // declare the data fetching function
    const fetchData = async () => {
      let token = localStorage.getItem("token");
      const resDdata = await fetch(`${HOST_API}/v/personnels/profile`, {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
      });
      if (resDdata.status == 200) {
        let data = await resDdata.json()
        let o = Object.fromEntries(Object.entries(data[0]).filter(([_, v]) => v != null));
        setUser({ ...userScolet, ...o })
      }else{
        setUser({...userScolet})
      }
    }
  
    // call the function
    fetchData()
  }, [])
  console.log("user:::",user)
  const showFormRestPassword = (e) => {
    e.preventDefault();
    let card =  document.querySelector('.js-profile-card');
    card.classList.add('active');
    //#################Close##################
    var closeBtn = document.querySelectorAll('.js-message-close');
    closeBtn.forEach(function (element, index) {
      console.log(element);
       element.addEventListener('click',function (e) {
           e.preventDefault();
           card.classList.remove('active');
       });
   });
  }



  const onSubmit = useCallback(async (e) => {
    e.preventDefault();
    const { password, confirmedPassword, old_password  } = formData.current;

    console.log(password, confirmedPassword, old_password)
    console.log(formData)
    if(old_password == undefined || password == undefined || confirmedPassword == undefined ){
      notify("Please check your form", 'error', 2000);
      return;
    }
  
    if(password.length < 6 ||  old_password.length < 6 || confirmedPassword.length < 6 || confirmedPassword != password ){
      notify("Please check your form!", 'error', 2000);
      return;
    }
    setLoading(true);
    console.log("formData:::",formData)
    const result = await changePassword(old_password, password);
    setLoading(false);

    if (result.isOk) {
     // history.push('/login');
     //console.log("isOk::", result.isOk)
      notify(result.message, 'success', 2000);
      logOut()
      let card =  document.querySelector('.js-profile-card');
      card.classList.remove('active');

      window.location.reload()

    } else {
      notify(result.message, 'error', 2000);
    }
  }, []);

  const confirmPassword = useCallback(
    ({ value }) => value === formData.current.password,
    []
  );


  return (
    <React.Fragment>
      <h2 className={'content-block'}>{t('profile')}</h2>

      <div className="wrapper">
  <div className="profile-card js-profile-card">
    <div className="profile-card__img">
      <img src="https://img.favpng.com/8/0/5/computer-icons-user-profile-avatar-png-favpng-6jJk1WU2YkTBLjFs4ZwueE8Ub.jpg" alt="profile card"/>
    </div>

    <div className="profile-card__cnt js-profile-cnt">
      <div className="profile-card__name">{user.p_first_name} {user.p_last_name}</div>
      <div className="profile-card__txt">{user.p_email}</div>
      <div className="profile-card-loc">
        <span className="profile-card-loc__icon">
          <svg className="icon"><use xlinkHref="#icon-location"></use></svg>
        </span>

        <span className="profile-card-loc__txt">
        {user.gp_name}, {user.gp_address}
        </span>
      </div>

      <div className="profile-card-inf">
        <div className="profile-card-inf__item">
          <div className="profile-card-inf__txt">{t('team')}</div>
          <div className="profile-card-inf__title">{user.tm_team}</div>
        </div>

        <div className="profile-card-inf__item">
          <div className="profile-card-inf__txt">{t('shift')}</div>
          <div className="profile-card-inf__title">{user.sh_shift}</div>
        </div>

        <div className="profile-card-inf__item">
          <div className="profile-card-inf__txt">CNIE</div>
          <div className="profile-card-inf__title">{user.p_cnie}</div>
        </div>

        <div className="profile-card-inf__item">
          <div className="profile-card-inf__txt">{t('phone')}</div>
          <div className="profile-card-inf__title">{user.p_phone}</div>
        </div>
      </div>

      <div className="profile-card-ctr">
        <button className="profile-card__button button--blue js-message-btn" onClick={showFormRestPassword}>{t('change password')}</button>
      </div>
    </div>

    <div className="profile-card-message js-message">
      <form className="profile-card-form" >
        <div className="profile-card-form__container">
        <Form formData={formData.current} disabled={loading}>
        <Item
          dataField={'old_password'}
          editorType={'dxTextBox'}
          editorOptions={oldPasswordEditorOptions}
        >
          <RequiredRule message="Password is required" />
          <Label visible={false} />
        </Item>
        <Item
          dataField={'password'}
          editorType={'dxTextBox'}
          editorOptions={passwordEditorOptions}
        >
          <RequiredRule message="Password is required" />
          <Label visible={false} />
        </Item>
        <Item
          dataField={'confirmedPassword'}
          editorType={'dxTextBox'}
          editorOptions={confirmedPasswordEditorOptions}
        >
          <RequiredRule message="Password is required" />
          <CustomRule
            message={'Passwords do not match'}
            validationCallback={confirmPassword}
          />
          <Label visible={false} />
        </Item>
  
      </Form>
        </div>

        <div className="profile-card-form__bottom">
          <button className="profile-card__button button--blue " onClick={onSubmit}>
            <span className="dx-button-text">
                {
                  loading
                    ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                    : t('save')
                }
              </span>
          </button>

          <button className="profile-card__button button--gray js-message-close" >
            {t('cancel')}
          </button>
        </div>
      </form>

      <div className="profile-card__overlay js-message-close"></div>
    </div>

  </div>

</div>

<svg hidden="hidden">
  <defs>
    <symbol id="icon-location" viewBox="0 0 32 32">
      <title>location</title>
      <path d="M16 31.68c-0.352 0-0.672-0.064-1.024-0.16-0.8-0.256-1.44-0.832-1.824-1.6l-6.784-13.632c-1.664-3.36-1.568-7.328 0.32-10.592 1.856-3.2 4.992-5.152 8.608-5.376h1.376c3.648 0.224 6.752 2.176 8.608 5.376 1.888 3.264 2.016 7.232 0.352 10.592l-6.816 13.664c-0.288 0.608-0.8 1.12-1.408 1.408-0.448 0.224-0.928 0.32-1.408 0.32zM15.392 2.368c-2.88 0.192-5.408 1.76-6.912 4.352-1.536 2.688-1.632 5.92-0.288 8.672l6.816 13.632c0.128 0.256 0.352 0.448 0.64 0.544s0.576 0.064 0.832-0.064c0.224-0.096 0.384-0.288 0.48-0.48l6.816-13.664c1.376-2.752 1.248-5.984-0.288-8.672-1.472-2.56-4-4.128-6.88-4.32h-1.216zM16 17.888c-3.264 0-5.92-2.656-5.92-5.92 0-3.232 2.656-5.888 5.92-5.888s5.92 2.656 5.92 5.92c0 3.264-2.656 5.888-5.92 5.888zM16 8.128c-2.144 0-3.872 1.728-3.872 3.872s1.728 3.872 3.872 3.872 3.872-1.728 3.872-3.872c0-2.144-1.76-3.872-3.872-3.872z"></path>
      <path d="M16 32c-0.384 0-0.736-0.064-1.12-0.192-0.864-0.288-1.568-0.928-1.984-1.728l-6.784-13.664c-1.728-3.456-1.6-7.52 0.352-10.912 1.888-3.264 5.088-5.28 8.832-5.504h1.376c3.744 0.224 6.976 2.24 8.864 5.536 1.952 3.36 2.080 7.424 0.352 10.912l-6.784 13.632c-0.32 0.672-0.896 1.216-1.568 1.568-0.48 0.224-0.992 0.352-1.536 0.352zM15.36 0.64h-0.064c-3.488 0.224-6.56 2.112-8.32 5.216-1.824 3.168-1.952 7.040-0.32 10.304l6.816 13.632c0.32 0.672 0.928 1.184 1.632 1.44s1.472 0.192 2.176-0.16c0.544-0.288 1.024-0.736 1.28-1.28l6.816-13.632c1.632-3.264 1.504-7.136-0.32-10.304-1.824-3.104-4.864-5.024-8.384-5.216h-1.312zM16 29.952c-0.16 0-0.32-0.032-0.448-0.064-0.352-0.128-0.64-0.384-0.8-0.704l-6.816-13.664c-1.408-2.848-1.312-6.176 0.288-8.96 1.536-2.656 4.16-4.32 7.168-4.512h1.216c3.040 0.192 5.632 1.824 7.2 4.512 1.6 2.752 1.696 6.112 0.288 8.96l-6.848 13.632c-0.128 0.288-0.352 0.512-0.64 0.64-0.192 0.096-0.384 0.16-0.608 0.16zM15.424 2.688c-2.784 0.192-5.216 1.696-6.656 4.192-1.504 2.592-1.6 5.696-0.256 8.352l6.816 13.632c0.096 0.192 0.256 0.32 0.448 0.384s0.416 0.064 0.608-0.032c0.16-0.064 0.288-0.192 0.352-0.352l6.816-13.664c1.312-2.656 1.216-5.792-0.288-8.352-1.472-2.464-3.904-4-6.688-4.16h-1.152zM16 18.208c-3.424 0-6.24-2.784-6.24-6.24 0-3.424 2.816-6.208 6.24-6.208s6.24 2.784 6.24 6.24c0 3.424-2.816 6.208-6.24 6.208zM16 6.4c-3.072 0-5.6 2.496-5.6 5.6 0 3.072 2.528 5.6 5.6 5.6s5.6-2.496 5.6-5.6c0-3.104-2.528-5.6-5.6-5.6zM16 16.16c-2.304 0-4.16-1.888-4.16-4.16s1.888-4.16 4.16-4.16c2.304 0 4.16 1.888 4.16 4.16s-1.856 4.16-4.16 4.16zM16 8.448c-1.952 0-3.552 1.6-3.552 3.552s1.6 3.552 3.552 3.552c1.952 0 3.552-1.6 3.552-3.552s-1.6-3.552-3.552-3.552z"></path>
    </symbol>
  </defs>
</svg>
    </React.Fragment>
  );
}

export default withTranslation()(Profile);