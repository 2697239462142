import './styles.scss';
import React, {useEffect, useState} from 'react';
import { withTranslation } from 'react-i18next';

import Chart, {
  Export,
  Label,
  Legend,
  LoadingIndicator,
  Series,
  Size,
  Title,
  Tooltip,
} from 'devextreme-react/chart';

import PieChart, {
  SmallValuesGrouping,
  Connector,
} from 'devextreme-react/pie-chart';

// import { Item } from 'devextreme-react/responsive-box';

// import DateBox from 'devextreme-react/date-box';
// import Toolbar from 'devextreme-react/toolbar';
// import CustomStore from 'devextreme/data/custom_store';
// import { activityOverviewData } from './test_data.js';
import { formatDate } from 'devextreme/localization';

// import { HOST_API } from "../../../env";
// import { sendRequest } from '../../../utils/utils';

// import CustomStore from 'devextreme/data/custom_store';

import { TripsPieData } from '../../../pages/home/test_data.js';

function MTDTripsChart({t}) {
  // const [activeMonth, setActiveMonth] = useState(0);
  
  // const currentDate = new Date();
  // const testDate = new Date(2022, 0, 1, 0, 0, 0);

  // useEffect(() => {
    // setActiveMonth(formatDate(testDate, "yyyy-MM"));
  // }, []);
  
  // const chartDataSource = new CustomStore({
  //   load: (loadOptions) => sendRequest(`${HOST_API}/v/fuelActivity/${activeMonth}`, loadOptions, 'GET'),
  //   onLoaded: function (result) {
  //     // clientTripDetailsStoreData = result.data;
  //   }
  // })

  // const onMonthValueChanged = (data) => {
  //   setActiveMonth(formatDate(data.value, "yyyy-MM"));
  // };

  const customizePieLabel = point => {
    return `${point.argumentText}: ${point.valueText} (${point.percentText})`;
  }
  
  
  return (
    <React.Fragment>
      <div id="TripsPieChartContainer" className={'dx-card responsive-paddings'}>
        <PieChart
          id="TripsPieChart"
          type="doughnut"
          title={t('trips by clients - mtd')}
          palette="Soft Blue" // 'Material', 'Soft Pastel', 'Harmony Light', 'Pastel', 'Bright', 'Soft', 'Ocean', 'Office', 'Vintage', 'Violet', 'Carmine', 'Dark Moon', 'Soft Blue', 'Dark Violet', 'Green Mist'
          paletteExtensionMode="blend" // "alternate","blend","extrapolate"
          dataSource={TripsPieData}
          >
          {/* <AdaptiveLayout height={220} width={450}/> */}
          <LoadingIndicator enabled={true} />
          <Size height={250} />
          <Series argumentField="language" valueField="percent">
            <SmallValuesGrouping mode="topN" topCount={10} />
            <Label
              visible={true}
              format="fixedPoint"
              customizeText={customizePieLabel}
              backgroundColor="none"
              >
              <Connector visible={true} width={1} />
            </Label>
          </Series>
          {/* <Export enabled={true} /> */}
          <Legend visible={false}/>
          <Tooltip enabled={true} />
        </PieChart>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(MTDTripsChart);