import './home.scss';
import React from 'react';
import { withTranslation } from 'react-i18next';
import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import { formatDate } from 'devextreme/localization';
import CustomStore from 'devextreme/data/custom_store';
import { HOST_API } from "../../env";
import { sendRequest } from '../../utils/utils';
import HomeClient from '../../components/home/client';
import HomeAdmin from '../../components/home/admin';
import Calendar from 'devextreme-react/calendar';

import GPSMap from '../../components/dashboard/gps-map/gps-map';
import ActivityOverviewChart from '../../components/dashboard/activity-overview-chart/activity-overview-chart';
import TripsTimelineChart from '../../components/dashboard/trips-timeline-chart/trips-timeline-chart';
import MTDTripsChart from '../../components/dashboard/trips-pie-chart/trips-pie-chart';

// const GPSMap = React.lazy(() => import('../../components/dashboard/gps-map/gps-map'));
// const ActivityOverviewChart = React.lazy(() => import('../../components/dashboard/activity-overview-chart/activity-overview-chart'));
// const TripsTimelineChart = React.lazy(() => import('../../components/dashboard/trips-timeline-chart/trips-timeline-chart'));
// const MTDTripsChart = React.lazy(() => import('../../components/dashboard/trips-pie-chart/trips-pie-chart'));



function Home({t}) {
  let dateAction = formatDate(new Date(), "yyyy-MM-dd");
  let level = localStorage.getItem("level");
  // console.log("---------------------------------------");
  // console.log("level : ", level);
  // console.log("---------------------------------------");
  const screen = (width) => {
    return (width < 700) ? 'sm' : 'lg';
  }

  function store({t, clientId}) {
    const tripStore = new CustomStore({
      loadMode: "raw",
      load: (loadOptions) => sendRequest(`${HOST_API}/v/fuelActivity/${dateAction}`, loadOptions, 'GET')
    });
  }

  // const store = new CustomStore({
  //   // load: (loadOptions) => sendRequest(`${HOST_API}/v/tripdetails/day/2022-03-20`, loadOptions, 'GET'),
  //   load: (loadOptions) => sendRequest(`${HOST_API}/v/fuelActivity/${dateAction}`, loadOptions, 'GET'),
  //   onLoaded: function (result) {
  //     // clientTripDetailsStoreData = result.data;
  //     console.log("result.data:",result.data);
  //   }
  // })
  const onChanged = (data) => {
    console.log("data:",formatDate(data.value, "yyyy-MM-dd"));
    console.log("today:",dateAction);
    let tripStore = new CustomStore({
      loadMode: "raw",
      load: (loadOptions) => sendRequest(`${HOST_API}/v/fuelActivity/${dateAction}`, loadOptions, 'GET')
    });
    console.log("store:",tripStore);

    // setActiveMonth(formatDate(data.value, "yyyy-MM"));
  };
  function home_level_2(){
    return (
      <HomeAdmin></HomeAdmin>
    )
  }
  function home_level_10(){
    return (
      <HomeClient level={level}></HomeClient>
      // <Calendar
      //   firstDayOfWeek={1}
      //   onValueChanged={onChanged}
      // > 
      // <h1>hello calendar</h1>
      // </Calendar>
     
    )
  }

  function home_else_level(){
    return (
      <React.Fragment>
        hello else
      </React.Fragment>
    )
  }


  if(level === "2") return home_level_2();
  else if(level === "10") return home_level_10();
  //return home_level_2();
  else return home_else_level();
}

export default withTranslation ()(Home);
