export const months = [{
  id: 1,
  name: 'January',
}, {
  id: 2,
  name: 'February',
}, {
  id: 3,
  name: 'March',
}, {
  id: 4,
  name: 'April',
}, {
  id: 5,
  name: 'May',
}, {
  id: 6,
  name: 'June',
}, {
  id: 7,
  name: 'July',
}, {
  id: 8,
  name: 'August',
}, {
  id: 9,
  name: 'September',
}, {
  id: 10,
  name: 'October',
}, {
  id: 11,
  name: 'November',
}, {
  id: 12,
  name: 'December',
}];


export const TripsPieData = [ {
  language: 'Others',
  percent: 32,
},{
  language: 'ToolPresse-TJ',
  percent: 337,
}, {
  language: 'EcorRecyclage',
  percent: 56,
}, {
  language: 'Delfingen',
  percent: 549,
}, {
  language: 'Shai And More',
  percent: 40,
},{
  language: 'Operating Media',
  percent: 283,
}];


export const activityOverviewData = [
  {day: '01', fuelrefill: 0,    totaltrips: 10, totalrev: 900},
  {day: '02', fuelrefill: 500,  totaltrips: 18, totalrev: 1620},
  {day: '03', fuelrefill: 1500, totaltrips: 70, totalrev: 5300},
  {day: '04', fuelrefill: 1500, totaltrips: 74, totalrev: 5600},
  {day: '05', fuelrefill: 1500, totaltrips: 76, totalrev: 5750},
  {day: '06', fuelrefill: 1000, totaltrips: 80, totalrev: 6000},
  {day: '07', fuelrefill: 2000, totaltrips: 82, totalrev: 6180},
  {day: '08', fuelrefill: 1000, totaltrips: 24, totalrev: 2160},
  {day: '09', fuelrefill: 1500, totaltrips: 18, totalrev: 1620},
  {day: '10', fuelrefill: 2000, totaltrips: 88, totalrev: 6600},
  {day: '11', fuelrefill: 1000, totaltrips: 72, totalrev: 5400},
  {day: '12', fuelrefill: 1500, totaltrips: 72, totalrev: 5400},
  {day: '13', fuelrefill: 1000, totaltrips: 72, totalrev: 5400},
  {day: '14', fuelrefill: 1000, totaltrips: 66, totalrev: 4900},
  {day: '15', fuelrefill: 1500, totaltrips: 24, totalrev: 2160},
  {day: '16', fuelrefill: 0,    totaltrips: 18, totalrev: 1620},
  {day: '17', fuelrefill: 2000, totaltrips: 76, totalrev: 5750},
  {day: '18', fuelrefill: 1000, totaltrips: 76, totalrev: 5750},
  {day: '19', fuelrefill: 1500, totaltrips: 74, totalrev: 5600},
  {day: '20', fuelrefill: 1000, totaltrips: 76, totalrev: 5750},
  {day: '21', fuelrefill: 1000, totaltrips: 76, totalrev: 5800},
  {day: '22', fuelrefill: 500,  totaltrips: 24, totalrev: 2160},
  {day: '23', fuelrefill: 1000, totaltrips: 16, totalrev: 1440},
  {day: '24', fuelrefill: 2000, totaltrips: 88, totalrev: 6600},
  {day: '25', fuelrefill: 1500, totaltrips: 80, totalrev: 6000},
  {day: '26', fuelrefill: 1000, totaltrips: 80, totalrev: 6000},
  {day: '27', fuelrefill: 1500, totaltrips: 72, totalrev: 5400},
  {day: '28', fuelrefill: 1000, totaltrips: 72, totalrev: 5400},
  {day: '29', fuelrefill: 1000, totaltrips: 56, totalrev: 4200},
  {day: '30', fuelrefill: 500,  totaltrips: 18, totalrev: 1440},
  {day: '31', fuelrefill: 2000, totaltrips: 88, totalrev: 6600}
]


export const TripsTimelineData = [
  {vehicle: 'S1 (87494-A-40)', start: new Date(2022, 2, 27, 5, 10, 0), end: new Date(2022, 2, 27, 5, 45, 0), client: 'all', direction: 'in', status:'confirmed'},
  {vehicle: 'S1 (87494-A-40)', start: new Date(2022, 2, 27, 6, 10, 0), end: new Date(2022, 2, 27, 6, 35, 0), client: 'all', direction: 'out', status:'confirmed'},
  {vehicle: 'S1 (87494-A-40)', start: new Date(2022, 2, 27, 7, 10, 0), end: new Date(2022, 2, 27, 8, 0, 0), client: 'all', direction: 'in', status:'confirmed'},
  {vehicle: 'S1 (87494-A-40)', start: new Date(2022, 2, 27, 13, 0, 0), end: new Date(2022, 2, 27, 13, 45, 0), client: 'all', direction: 'in', status:'confirmed'},
  {vehicle: 'S1 (87494-A-40)', start: new Date(2022, 2, 27, 14, 10, 0), end: new Date(2022, 2, 27, 14, 55, 0), client: 'all', direction: 'out', status:'confirmed'},
  {vehicle: 'S1 (87494-A-40)', start: new Date(2022, 2, 27, 18, 0, 0), end: new Date(2022, 2, 27, 18, 45, 0), client: 'all', direction: 'out', status:'confirmed'},
  {vehicle: 'S1 (87494-A-40)', start: new Date(2022, 2, 27, 21, 5, 0), end: new Date(2022, 2, 27, 21, 45, 0), client: 'all', direction: 'in', status:'planned'},
  {vehicle: 'S1 (87494-A-40)', start: new Date(2022, 2, 27, 22, 15, 0), end: new Date(2022, 2, 27, 22, 50, 0), client: 'all', direction: 'out', status:'planned'},
  {vehicle: 'S2 (91861-A-40)', start: new Date(2022, 2, 27, 5, 15, 0), end: new Date(2022, 2, 27, 5, 45, 0), client: 'all', direction: 'in', status:'confirmed'},
  {vehicle: 'S2 (91861-A-40)', start: new Date(2022, 2, 27, 6, 10, 0), end: new Date(2022, 2, 27, 6, 35, 0), client: 'all', direction: 'out', status:'confirmed'},
  {vehicle: 'S2 (91861-A-40)', start: new Date(2022, 2, 27, 7, 45, 0), end: new Date(2022, 2, 27, 8, 30, 0), client: 'all', direction: 'in', status:'confirmed'},
  {vehicle: 'S2 (91861-A-40)', start: new Date(2022, 2, 27, 13, 0, 0), end: new Date(2022, 2, 27, 13, 45, 0), client: 'all', direction: 'in', status:'confirmed'},
  {vehicle: 'S2 (91861-A-40)', start: new Date(2022, 2, 27, 14, 10, 0), end: new Date(2022, 2, 27, 14, 55, 0), client: 'all', direction: 'out', status:'confirmed'},
  {vehicle: 'S2 (91861-A-40)', start: new Date(2022, 2, 27, 16, 0, 0), end: new Date(2022, 2, 27, 16, 35, 0), client: 'all', direction: 'out', status:'confirmed'},
  {vehicle: 'S2 (91861-A-40)', start: new Date(2022, 2, 27, 17, 45, 0), end: new Date(2022, 2, 27, 18, 15, 0), client: 'all', direction: 'out', status:'confirmed'},
  {vehicle: 'S2 (91861-A-40)', start: new Date(2022, 2, 27, 21, 5, 0), end: new Date(2022, 2, 27, 21, 45, 0), client: 'all', direction: 'in', status:'planned'},
  {vehicle: 'S2 (91861-A-40)', start: new Date(2022, 2, 27, 22, 15, 0), end: new Date(2022, 2, 27, 22, 50, 0), client: 'all', direction: 'out', status:'planned'},
  {vehicle: 'S3 (92811-A-40)', start: new Date(2022, 2, 27, 5, 15, 0), end: new Date(2022, 2, 27, 5, 45, 0), client: 'all', direction: 'in', status:'confirmed'},
  {vehicle: 'S3 (92811-A-40)', start: new Date(2022, 2, 27, 6, 10, 0), end: new Date(2022, 2, 27, 6, 35, 0), client: 'all', direction: 'out', status:'confirmed'},
  {vehicle: 'S3 (92811-A-40)', start: new Date(2022, 2, 27, 7, 10, 0), end: new Date(2022, 2, 27, 7, 45, 0), client: 'all', direction: 'in', status:'confirmed'},
  {vehicle: 'S3 (92811-A-40)', start: new Date(2022, 2, 27, 8, 0, 0), end: new Date(2022, 2, 27, 8, 20, 0), client: 'all', direction: 'out', status:'confirmed'},
  {vehicle: 'S3 (92811-A-40)', start: new Date(2022, 2, 27, 13, 0, 0), end: new Date(2022, 2, 27, 13, 45, 0), client: 'all', direction: 'in', status:'confirmed'},
  {vehicle: 'S3 (92811-A-40)', start: new Date(2022, 2, 27, 14, 10, 0), end: new Date(2022, 2, 27, 14, 55, 0), client: 'all', direction: 'out', status:'confirmed'},
  {vehicle: 'S3 (92811-A-40)', start: new Date(2022, 2, 27, 18, 0, 0), end: new Date(2022, 2, 27, 18, 45, 0), client: 'all', direction: 'out', status:'confirmed'},
  {vehicle: 'S3 (92811-A-40)', start: new Date(2022, 2, 27, 21, 5, 0), end: new Date(2022, 2, 27, 21, 45, 0), client: 'all', direction: 'in', status:'planned'},
  {vehicle: 'S3 (92811-A-40)', start: new Date(2022, 2, 27, 22, 15, 0), end: new Date(2022, 2, 27, 22, 50, 0), client: 'all', direction: 'out', status:'planned'},
  {vehicle: 'Vito1 (62246-B-40)', start: new Date(2022, 2, 27, 5, 20, 0), end: new Date(2022, 2, 27, 5, 50, 0), client: 'all', direction: 'in', status:'confirmed'},
  {vehicle: 'Vito1 (62246-B-40)', start: new Date(2022, 2, 27, 6, 10, 0), end: new Date(2022, 2, 27, 6, 35, 0), client: 'all', direction: 'out', status:'confirmed'},
  {vehicle: 'Vito1 (62246-B-40)', start: new Date(2022, 2, 27, 7, 20, 0), end: new Date(2022, 2, 27, 8, 0, 0), client: 'all', direction: 'in', status:'confirmed'},
  {vehicle: 'Vito1 (62246-B-40)', start: new Date(2022, 2, 27, 16, 0, 0), end: new Date(2022, 2, 27, 16, 30, 0), client: 'all', direction: 'out', status:'confirmed'},
  {vehicle: 'Vito1 (62246-B-40)', start: new Date(2022, 2, 27, 18, 0, 0), end: new Date(2022, 2, 27, 18, 40, 0), client: 'all', direction: 'out', status:'confirmed'},
  {vehicle: 'Crafter1 (1695-B-40)', start: new Date(2022, 2, 27, 5, 10, 0), end: new Date(2022, 2, 27, 5, 45, 0), client: 'all', direction: 'in', status:'confirmed'},
  {vehicle: 'Crafter1 (1695-B-40)', start: new Date(2022, 2, 27, 7, 10, 0), end: new Date(2022, 2, 27, 8, 0, 0), client: 'all', direction: 'in', status:'confirmed'},
  {vehicle: 'Crafter1 (1695-B-40)', start: new Date(2022, 2, 27, 13, 0, 0), end: new Date(2022, 2, 27, 13, 45, 0), client: 'all', direction: 'in', status:'confirmed'},
  {vehicle: 'Crafter1 (1695-B-40)', start: new Date(2022, 2, 27, 14, 10, 0), end: new Date(2022, 2, 27, 14, 55, 0), client: 'all', direction: 'out', status:'confirmed'},
  {vehicle: 'Crafter1 (1695-B-40)', start: new Date(2022, 2, 27, 18, 0, 0), end: new Date(2022, 2, 27, 18, 45, 0), client: 'all', direction: 'out', status:'confirmed'},
  {vehicle: 'Crafter1 (1695-B-40)', start: new Date(2022, 2, 27, 21, 5, 0), end: new Date(2022, 2, 27, 21, 45, 0), client: 'all', direction: 'in', status:'planned'},
  {vehicle: 'Crafter1 (1695-B-40)', start: new Date(2022, 2, 27, 22, 15, 0), end: new Date(2022, 2, 27, 22, 50, 0), client: 'all', direction: 'out', status:'planned'},
  {vehicle: '', start: new Date(2022, 2, 27, 0, 0, 0), end: new Date(2022, 2, 27, 0, 0, 0), client: 'all', direction: '', status:'SYS'},
  {vehicle: '', start: new Date(2022, 2, 27, 23, 59, 59, 0), end: new Date(2022, 2, 27, 23, 59, 58, 0), client: 'all', direction: 'SYS', status:''}
]

export const TripsTimelineData_KEEP = [
  {
    vehicle: 'S1 (87494-A-40)',
    start: new Date(2022, 2, 27, 5, 10, 0),
    end: new Date(2022, 2, 27, 5, 45, 0),
    client: 'all',
    direction: 'in',
  },
  {
    vehicle: 'S1 (87494-A-40)',
    start: new Date(2022, 2, 27, 6, 10, 0),
    end: new Date(2022, 2, 27, 6, 35, 0),
    client: 'all',
    direction: 'out',
  },
  {
    vehicle: 'S1 (87494-A-40)',
    client: 'all',
    direction: 'in',
    start: new Date(2022, 2, 27, 7, 10, 0),
    end: new Date(2022, 2, 27, 8, 0, 0),
  },
  {
    vehicle: 'S1 (87494-A-40)',
    start: new Date(2022, 2, 27, 13, 0, 0),
    end: new Date(2022, 2, 27, 13, 45, 0),
    client: 'all',
    direction: 'in',
  },
  {
    vehicle: 'S1 (87494-A-40)',
    start: new Date(2022, 2, 27, 14, 10, 0),
    end: new Date(2022, 2, 27, 14, 55, 0),
    client: 'all',
    direction: 'out',
  },
  {
    vehicle: 'S1 (87494-A-40)',
    start: new Date(2022, 2, 27, 18, 0, 0),
    end: new Date(2022, 2, 27, 18, 45, 0),
    client: 'all',
    direction: 'out',
  },
  {
    vehicle: 'S1 (87494-A-40)',
    start: new Date(2022, 2, 27, 21, 5, 0),
    end: new Date(2022, 2, 27, 21, 45, 0),
    client: 'all',
    direction: 'in',
  },
  {
    vehicle: 'S1 (87494-A-40)',
    start: new Date(2022, 2, 27, 22, 15, 0),
    end: new Date(2022, 2, 27, 22, 50, 0),
    client: 'all',
    direction: 'out',
  },


  
  {
    vehicle: 'S2 (91861-A-40)',
    start: new Date(2022, 2, 27, 5, 15, 0),
    end: new Date(2022, 2, 27, 5, 45, 0),
    client: 'all',
    direction: 'in',
  },
  {
    vehicle: 'S2 (91861-A-40)',
    start: new Date(2022, 2, 27, 6, 10, 0),
    end: new Date(2022, 2, 27, 6, 35, 0),
    client: 'all',
    direction: 'out',
  },
  {
    vehicle: 'S2 (91861-A-40)',
    start: new Date(2022, 2, 27, 7, 45, 0),
    end: new Date(2022, 2, 27, 8, 30, 0),
    client: 'all',
    direction: 'in',
  },
  {
    vehicle: 'S2 (91861-A-40)',
    start: new Date(2022, 2, 27, 13, 0, 0),
    end: new Date(2022, 2, 27, 13, 45, 0),
    client: 'all',
    direction: 'in',
  },
  {
    vehicle: 'S2 (91861-A-40)',
    start: new Date(2022, 2, 27, 14, 10, 0),
    end: new Date(2022, 2, 27, 14, 55, 0),
    client: 'all',
    direction: 'out',
  },
  {
    vehicle: 'S2 (91861-A-40)',
    start: new Date(2022, 2, 27, 16, 0, 0),
    end: new Date(2022, 2, 27, 16, 35, 0),
    client: 'all',
    direction: 'out',
  },
  {
    vehicle: 'S2 (91861-A-40)',
    start: new Date(2022, 2, 27, 17, 45, 0),
    end: new Date(2022, 2, 27, 18, 15, 0),
    client: 'all',
    direction: 'out',
  },
  {
    vehicle: 'S2 (91861-A-40)',
    start: new Date(2022, 2, 27, 21, 5, 0),
    end: new Date(2022, 2, 27, 21, 45, 0),
    client: 'all',
    direction: 'in',
  },
  {
    vehicle: 'S2 (91861-A-40)',
    start: new Date(2022, 2, 27, 22, 15, 0),
    end: new Date(2022, 2, 27, 22, 50, 0),
    client: 'all',
    direction: 'out',
  },


  {
    vehicle: 'S3 (92811-A-40)',
    start: new Date(2022, 2, 27, 5, 15, 0),
    end: new Date(2022, 2, 27, 5, 45, 0),
    client: 'all',
    direction: 'in',
  },
  {
    vehicle: 'S3 (92811-A-40)',
    start: new Date(2022, 2, 27, 6, 10, 0),
    end: new Date(2022, 2, 27, 6, 35, 0),
    client: 'all',
    direction: 'out',
  },
  {
    vehicle: 'S3 (92811-A-40)',
    start: new Date(2022, 2, 27, 7, 10, 0),
    end: new Date(2022, 2, 27, 7, 45, 0),
    client: 'all',
    direction: 'in',
  },
  {
    vehicle: 'S3 (92811-A-40)',
    start: new Date(2022, 2, 27, 8, 0, 0),
    end: new Date(2022, 2, 27, 8, 20, 0),
    client: 'all',
    direction: 'out',
  },
  {
    vehicle: 'S3 (92811-A-40)',
    start: new Date(2022, 2, 27, 13, 0, 0),
    end: new Date(2022, 2, 27, 13, 45, 0),
    client: 'all',
    direction: 'in',
  },
  {
    vehicle: 'S3 (92811-A-40)',
    start: new Date(2022, 2, 27, 14, 10, 0),
    end: new Date(2022, 2, 27, 14, 55, 0),
    client: 'all',
    direction: 'out',
  },
  {
    vehicle: 'S3 (92811-A-40)',
    start: new Date(2022, 2, 27, 18, 0, 0),
    end: new Date(2022, 2, 27, 18, 45, 0),
    client: 'all',
    direction: 'out',
  },
  {
    vehicle: 'S3 (92811-A-40)',
    start: new Date(2022, 2, 27, 21, 5, 0),
    end: new Date(2022, 2, 27, 21, 45, 0),
    client: 'all',
    direction: 'in',
  },
  {
    vehicle: 'S3 (92811-A-40)',
    start: new Date(2022, 2, 27, 22, 15, 0),
    end: new Date(2022, 2, 27, 22, 50, 0),
    client: 'all',
    direction: 'out',
  },



  {
    vehicle: 'Vito1 (62246-B-40)',
    start: new Date(2022, 2, 27, 5, 20, 0),
    end: new Date(2022, 2, 27, 5, 50, 0),
    client: 'all',
    direction: 'in',
  },
  {
    vehicle: 'Vito1 (62246-B-40)',
    start: new Date(2022, 2, 27, 6, 10, 0),
    end: new Date(2022, 2, 27, 6, 35, 0),
    client: 'all',
    direction: 'out',
  },
  {
    vehicle: 'Vito1 (62246-B-40)',
    start: new Date(2022, 2, 27, 7, 20, 0),
    end: new Date(2022, 2, 27, 8, 0, 0),
    client: 'all',
    direction: 'in',
  },
  {
    vehicle: 'Vito1 (62246-B-40)',
    start: new Date(2022, 2, 27, 16, 0, 0),
    end: new Date(2022, 2, 27, 16, 30, 0),
    client: 'all',
    direction: 'out',
  },
  {
    vehicle: 'Vito1 (62246-B-40)',
    start: new Date(2022, 2, 27, 18, 0, 0),
    end: new Date(2022, 2, 27, 18, 40, 0),
    client: 'all',
    direction: 'out',
  },




  {
    vehicle: 'Crafter1 (1695-B-40)',
    start: new Date(2022, 2, 27, 5, 10, 0),
    end: new Date(2022, 2, 27, 5, 45, 0),
    client: 'all',
    direction: 'in',
  },
  {
    vehicle: 'Crafter1 (1695-B-40)',
    start: new Date(2022, 2, 27, 7, 10, 0),
    end: new Date(2022, 2, 27, 8, 0, 0),
    client: 'all',
    direction: 'in',
  },
  {
    vehicle: 'Crafter1 (1695-B-40)',
    start: new Date(2022, 2, 27, 13, 0, 0),
    end: new Date(2022, 2, 27, 13, 45, 0),
    client: 'all',
    direction: 'in',
  },
  {
    vehicle: 'Crafter1 (1695-B-40)',
    start: new Date(2022, 2, 27, 14, 10, 0),
    end: new Date(2022, 2, 27, 14, 55, 0),
    client: 'all',
    direction: 'out',
  },
  {
    vehicle: 'Crafter1 (1695-B-40)',
    start: new Date(2022, 2, 27, 18, 0, 0),
    end: new Date(2022, 2, 27, 18, 45, 0),
    client: 'all',
    direction: 'out',
  },
  {
    vehicle: 'Crafter1 (1695-B-40)',
    start: new Date(2022, 2, 27, 21, 5, 0),
    end: new Date(2022, 2, 27, 21, 45, 0),
    client: 'all',
    direction: 'in',
  },
  {
    vehicle: 'Crafter1 (1695-B-40)',
    start: new Date(2022, 2, 27, 22, 15, 0),
    end: new Date(2022, 2, 27, 22, 50, 0),
    client: 'all',
    direction: 'out',
  },


  
  {
    vehicle: '',
    start: new Date(2022, 2, 27, 0, 0, 0),
    end: new Date(2022, 2, 27, 0, 0, 0),
    client: 'all',
    direction: '',
  },
  {
    vehicle: '',
    start: new Date(2022, 2, 27, 23, 59, 59),
    end: new Date(2022, 2, 27, 23, 59, 58),
    client: 'all',
    direction: '',
  },
];


// export const continentSources = [
//   { value: 'africa', name: 'Africa' },
//   { value: 'asia', name: 'Asia' },
//   { value: 'europe', name: 'Europe' },
//   { value: 'latinamerica', name: 'Latin America & Caribbean' },
//   { value: 'northamerica', name: 'Northern America' },
//   { value: 'oceania', name: 'Oceania' },
// ];

// export const populationData = [{
//   year: '1750',
//   africa: 106000000,
//   asia: 502000000,
//   europe: 163000000,
//   latinamerica: 16000000,
//   northamerica: 2000000,
//   oceania: 2000000,
//   total: 791000000,
// }, {
//   year: '1800',
//   africa: 107000000,
//   asia: 635000000,
//   europe: 203000000,
//   latinamerica: 24000000,
//   northamerica: 7000000,
//   oceania: 2000000,
//   total: 978000000,
// }, {
//   year: '1850',
//   africa: 111000000,
//   asia: 809000000,
//   europe: 276000000,
//   latinamerica: 38000000,
//   northamerica: 26000000,
//   oceania: 2000000,
//   total: 1262000000,
// }, {
//   year: '1900',
//   africa: 133000000,
//   asia: 947000000,
//   europe: 408000000,
//   latinamerica: 74000000,
//   northamerica: 82000000,
//   oceania: 6000000,
//   total: 1650000000,
// }, {
//   year: '1950',
//   africa: 229895000,
//   asia: 1403388000,
//   europe: 547287000,
//   latinamerica: 167368000,
//   northamerica: 171614000,
//   oceania: 12675000,
//   total: 2532227000,
// }, {
//   year: '2000',
//   africa: 811101000,
//   asia: 3719044000,
//   europe: 726777000,
//   latinamerica: 521419000,
//   northamerica: 313289000,
//   oceania: 31130000,
//   total: 6122770000,
// }, {
//   year: '2050',
//   africa: 2191599000,
//   asia: 5142220000,
//   europe: 719257000,
//   latinamerica: 750956000,
//   northamerica: 446862000,
//   oceania: 55223000,
//   total: 9306128000,
// }];