// import React, { Suspense } from 'react';

import { withNavigationWatcher } from './contexts/navigation';
import { levels } from "./contexts/levels";
import { HomePage, ProfilePage } from './pages';
import { CompaniesPage, ClientsPage, SubcontractorsPage } from './pages';
import { PersonnelPage, VehiclesPage, PickupPointsPage, TripsPage, GPSPlatformsPage } from './pages';
// import { } from './pages';

// const CompaniesPage = React.lazy(() => import('./pages/companies/companies'));
// const ClientsPage = React.lazy(() => import('./pages/clients/clients'));
// const SubcontractorsPage = React.lazy(() => import('./pages/subcontractors/subcontractors'));

// const PersonnelPage = React.lazy(() => import('./pages/personnel/personnel'));
// const VehiclesPage = React.lazy(() => import('./pages/vehicles/vehicles'));
// const PickupPointsPage = React.lazy(() => import('./pages/PickupPoints/PickupPoints'));
// const TripsPage = React.lazy(() => import('./pages/trips/trips'));
// const GPSPlatformsPage = React.lazy(() => import('./pages/gpsplatforms/gpsplatforms'));

// const PickupPointsPage = React.lazy(
//   () => import('./pages/PickupPoints/PickupPoints').then(module => ({ default: module.PickupPoints }))
// );


// const CompaniesPage = React.lazy(() => import('./pages/companies/companies'));
// const ClientsPage = React.lazy(() => import('./pages/companies/companies'));


//const levels = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
const lvl  = parseInt(window.localStorage.getItem("level")) || 0;
const routes = [
  {
    path: '/home',
    component: HomePage
  },
  {
    path: '/profile',
    component: ProfilePage
  },
  {
    path: '/companies',
    component: CompaniesPage
  },
  {
    path: '/clients',
    component: ClientsPage
  },
  {
    path: '/subcontractors',
    component: SubcontractorsPage
  },
  {
    path: '/personnel',
    component: PersonnelPage
  },
  {
    path: '/vehicles',
    component: VehiclesPage
  },
  {
    path: '/geopoints',
    component: PickupPointsPage
  },
  {
    path: '/pickuppoints',
    component: PickupPointsPage
  },
  {
    path: '/trips',
    component: TripsPage
  },
  {
    path: '/gpsplatforms',
    component: GPSPlatformsPage
  }
];
const routesWithLevels = routes.map(route => {
  let lvl = levels.filter( l => l.path === route.path);
  // console.log("****************************************************");
  // console.log(levels.filter( l => l.path == route.path));
  // console.log("****************************************************");
  return {...route, level: [...lvl[0].level]}
})
//console.log("routesWithLevels:::",routesWithLevels)
export default routesWithLevels.filter(route => {
  //console.log("route::",route.level.includes(lvl))
  if(route.level.includes(lvl) || route.level.includes(0)){
    return {
      ...route,
      component: withNavigationWatcher(route.component)
    };
  }
  
});

