import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { SingleCard } from './layouts';
import { LoginForm, ResetPasswordForm, ChangePasswordForm, CreateAccountForm } from './components';
import { isMobile } from 'react-device-detect';



export default function UnauthenticatedContent() {

  const renderContent = () => {
    console.log("isMobile::",isMobile)
    let stylesForm = {}
    if (isMobile) {
      stylesForm = {
        marginTop:'18%', 
        marginBottom:'80%', 
        width: '80%', 
        alignSelf:'center'
      }
    }
    return  <div className="app ${screenSizeClass} background-body" title="NaveTrack"  >
              <SingleCard title="NaveTrack" style={stylesForm}>
              <LoginForm />
              </SingleCard>
            </div>
  }
  return (
    <Switch>
      <Route exact path='/login' >
         {renderContent}
      </Route>
      <Route exact path='/reset-password' >
        <SingleCard
          title="Reset Password"
          description="Please enter the email address that you used to register, and we will send you a link to reset your password via Email."
        >
          <ResetPasswordForm />
        </SingleCard>
      </Route>
      <Route exact path='/change-password/:recoveryCode' >
        <SingleCard title="Change Password">
          <ChangePasswordForm />
        </SingleCard>
      </Route>
      <Redirect to={'/login'} />
    </Switch>
  );
}
