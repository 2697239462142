import React, {useEffect, useState} from 'react';
import { withTranslation } from 'react-i18next';
import Form, { SimpleItem, GroupItem} from 'devextreme-react/form';
import ResponsiveBox, { Row, Col, Location } from 'devextreme-react/responsive-box';
import Chart, {
  CommonSeriesSettings,
  Crosshair,
  Export,
  Grid,
  HorizontalLine,
  Label,
  Legend,
  LoadingIndicator,
  Margin,
  Pane,
  Point,
  Series,
  Title,
  Tooltip,
  ValueAxis,
} from 'devextreme-react/chart';

import { Item } from 'devextreme-react/responsive-box';
import Calendar from 'devextreme-react/calendar';

import DateBox from 'devextreme-react/date-box';
import Toolbar from 'devextreme-react/toolbar';
// import CustomStore from 'devextreme/data/custom_store';
// import { activityOverviewData } from './test_data.js';
import { formatDate } from 'devextreme/localization';

import { sendRequest } from '../../utils/utils';
import { HOST_API } from "../../env";

import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';

import $, { readyException } from "jquery";

function ActivityOverviewChart({t}) {

  const [activeMonth, setActiveMonth] = useState(0);
  const [dataTrip, setdataTrip] = useState(null);
  const [currentDate, setCurrentDate] = useState(null);
  const [cellTemplate, setcellTemplate] = useState(null);

  const testDate = new Date(2022, 0, 1, 0, 0, 0);
  let dateAction = formatDate(new Date(), "yyyy-MM-dd");

  const screen = (width) => {
    return (width < 700) ? 'sm' : 'lg';
  } 

  function CustomCell(cellInfo, index) {
    return (
      <span style={{ fontWeight: index === 5 || index === 6 ? 'bold' : '' , color: index === 5 || index === 6 ? 'blue' : '' }}>
        { cellInfo.text }
      </span>
    );
  }

  useEffect(() => {
    setCurrentDate(new Date());
  }, []);
  
  const chartDataSource = new CustomStore({
    load: (loadOptions) => sendRequest(`${HOST_API}/v/fuelActivity/${activeMonth}`, loadOptions, 'GET'),
    onLoaded: function (result) {
      // clientTripDetailsStoreData = result.data;
    }
  })

  const StoreTripDetail = new CustomStore({
    load: (loadOptions) => {
      let res = sendRequest(`${HOST_API}/v/tripdetails/day/${dateAction}`, loadOptions, 'GET');
      console.log(" resr : ", res);
      return res;
    } ,
    onLoaded: function (result) {
      console.log(" result.data : ", result.data);
    }
  })

  const onChanged = () => {
    let dateActionChange = formatDate(new Date(), "yyyy-MM-dd");
    console.log("**** date selected : ",dateActionChange);
    sendRequest(`${HOST_API}/v/tripdetails/day/${dateActionChange}`, {}, 'GET')
    .then(
      response => {
      console.log("**** response.data");
      console.log(response.data);
      DisplayTripDetails(response.data)
      return true;
    })
  };

  const onMonthValueChanged = (data) => {
    dateAction = formatDate(data.value, "yyyy-MM-dd");
    setCurrentDate(data.value);
    console.log("date selected : ",dateAction);
    sendRequest(`${HOST_API}/v/tripdetails/day/${dateAction}`, {}, 'GET')
    .then(response => {
      console.log("response.data");
      console.log(response.data);
      DisplayTripDetails(response.data)
    })
  };

  const DisplayTripDetails = (tripDetails) => {
    if( Object.keys(tripDetails).length === 0 ){
      setdataTrip(
        <div className="filter-container">
        <br/>
        <br/>
          <div className="formmm">
          <div className="dx-fieldsetmm">
            <div className="dx-fieldmm"> 
              <br/>
              <label style={{ color:'red'}}>{t('please enter a valid day')}</label>
            </div>
          </div>
        </div>
        </div>
      );
    }
    for (const [key, value] of Object.entries(tripDetails)) {
     setdataTrip( 
      <div>
        <br/>
        <br/>
        <div className="filter-container">
          <div className="dx-fieldset">
            <div className="dx-field"> 
              <div className="dx-field-label">{t('trip hour')}</div>
              <div className="dx-field-value-static-keep">{value.trip_hour}</div>
            </div>
            <div className="dx-field"> 
              <div className="dx-field-label">{t('driver name')}</div>
              <div className="dx-field-value-static-keep">{value.driver_name}</div>
            </div>
            <div className="dx-field"> 
              <div className="dx-field-label">{t('point time')}</div>
              <div className="dx-field-value-static-keep">{value.point_time}</div>
            </div>
            <div className="dx-field"> 
              <div className="dx-field-label">{t('point name')}</div> 
              <div className="dx-field-value-static-keep">{value.point_name}</div>
            </div>
          </div>
        </div>
      </div>
    )}
  }
  
  return (
    <React.Fragment>
      <ResponsiveBox
        singleColumnScreen="sm"
        screenByWidth={screen}
      >
        <Row ratio={1}></Row>
        <Row ratio={2} screen="xs"></Row>
        <Row ratio={2}></Row>
        <Row ratio={1}></Row>
        <Col ratio={1}></Col>
        <Col ratio={1} screen="lg"></Col>
        <Col ratio={1}></Col>
        <Col ratio={1}></Col>
        <Item>
          <Location row={1} col={0} screen="lg" ></Location>
          <Location row={2} col={0} screen="sm" ></Location>
          <div>
          <br/>
            <div id="calendar-demo111">
              <div className="widget-container111">
                <Calendar
                  className="dx-fieldset111"
                  firstDayOfWeek={1}
                  // onContentReady={onChanged}
                  defaultValue={currentDate}
                  acceptCustomValue={false}
                  cellRender={CustomCell}
                  calendarOptions={
                    {
                      maxZoomLevel: 'year', 
                      minZoomLevel: 'century',
                    }
                  }
                  onValueChanged={onMonthValueChanged}
                >
                </Calendar>
              </div> 
            </div> 

          </div>          
        </Item>
        <Item>
          <Location row={1} col={1}             screen="lg" ></Location>
          <Location row={1} col={0} colspan={2} screen="sm" ></Location>           
          {dataTrip}
        </Item>
        <Item>
          <Location row={1}  col={2}  screen="lg" ></Location>
          <Location row={2}  col={1}  screen="sm" ></Location>
          {/* <div id={"client_TD_Map_11"} className="dx-card client_TD_Map"></div> */}
        </Item>
      </ResponsiveBox>


    </React.Fragment>
  )
}

export default withTranslation()(ActivityOverviewChart);