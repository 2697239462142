// import 'devextreme/dist/css/dx.common.css';
// import './themes/generated/theme.base.css';
// import './themes/generated/theme.additional.css';
import React, { Suspense } from 'react';
import { HashRouter as Router } from 'react-router-dom';
import './dx-styles.scss';
import LoadPanel from 'devextreme-react/load-panel';
import { NavigationProvider } from './contexts/navigation';
import { AuthProvider, useAuth } from './contexts/auth';
import { useScreenSizeClass } from './utils/media-query';

import frMessages from 'devextreme/localization/messages/fr.json';
import arMessages from './locales/ar/devetreme_ar_beta.json';
import { /*locale,*/ loadMessages /*, formatMessage */ } from 'devextreme/localization';


import Content from './Content';
// const Content = React.lazy(() => import('./Content'));
import UnauthenticatedContent from './UnauthenticatedContent';
// const UnauthenticatedContent = React.lazy(() => import('./UnauthenticatedContent'));
function App() {
  const { user, loading } = useAuth();

  if (loading) {
    return <LoadPanel visible={true} />;
  }

  if (user !== undefined && user.token !== null) {
    return <Suspense fallback={<LoadPanel visible={true} />} ><Content /></Suspense>;
  }

  return <Suspense fallback={<LoadPanel visible={true} />} ><UnauthenticatedContent /></Suspense>;
}

export default function Root() {
  const screenSizeClass = useScreenSizeClass();
  const lang = window.localStorage.getItem("lang") || "en"

  // let locale = new Intl.Locale(lang, { numberingSystem: "latn" });
  // console.log(locale.numberingSystem); // Prints "latn"

  // locale(lang);
  loadMessages(frMessages);
  loadMessages(arMessages);
  const rtl = lang === 'ar' ? 'dx-rtl' : ''
  return (
    <Router>
      <AuthProvider>
        <NavigationProvider>
          <div className={`app ${screenSizeClass} ${rtl}`}>
            <App />
          </div>
        </NavigationProvider>
      </AuthProvider>
    </Router>
  );
}
