import React from 'react';
import { withTranslation } from 'react-i18next';
import { sendRequest } from '../../utils/utils';
import { HOST_API } from "../../env";

import CustomStore from 'devextreme/data/custom_store';
import { GroupItem, SimpleItem ,Item } from 'devextreme-react/form';
import { NumberBox } from 'devextreme-react/number-box';

import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  Lookup,
  Form,
  Toolbar,
  Editing,
  Popup,
  RequiredRule,
  PatternRule
} from 'devextreme-react/data-grid';

function ClientContractPrices(props) {
  const {t, data} = props;
  const rowData = data.row.data;
  const contractId = rowData.cn_id_cn;

  const dxDateBoxOptions = {
    displayFormat: "dd/MM/yyyy",
    dateSerializationFormat: "yyyy-MM-dd"
  };
  
  const ContractPricesStore  = new CustomStore({
    key: 'cpr_id_cpr',
    load: (loadOptions) => sendRequest(`${HOST_API}/v/contract/price`,loadOptions,'GET',{filter: ['["cpr_id_cn","=",'+contractId+']']}),
    insert: (values) => {
      if(values.cpr_status !== null){
        values.cpr_status = (values.cpr_status === true) ? '1' : '0';
      }
      return sendRequest(`${HOST_API}/contract/price`, null, 'POST', {...values, cpr_id_cn:contractId })
    },
    update: function(key, values){
      if(values.cpr_status  && values.cpr_status !== null){
        values.cpr_status = (values.cpr_status === true) ? '1' : '0';
      }
      let res = sendRequest(`${HOST_API}/contract/price/update`, null, 'POST', {...values,cpr_id_cn:contractId,cpr_id_cpr:key})
      return res;
    },
    remove: (key) => sendRequest(`${HOST_API}/contract/price`,null, 'DELETE', {
      key,
    }),
  })

  const prepareStatusValue = rowData => {
    return rowData.cpr_status === '1' ? true : false;
  };

  return (
    <React.Fragment>
      <DataGrid
        className={'dx-card wide-card'}
        keyExpr="cpr_id_cpr"
        dataSource={ContractPricesStore}
        showBorders={true}
        remoteOperations={false}
        focusedRowEnabled={true}
        // defaultFocusedRowIndex={0}
        rowAlternationEnabled={true}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        showColumnHeaders={true}
      >
      <Editing 
        mode="raw"
        allowAdding={true} 
        allowUpdating={true}
        allowDeleting={true}
        />
      <Toolbar className="GridToolbar">
        <Item location="before">
          <h5>{t('contract prices')}</h5>
        </Item>
        <Item location="after" name="addRowButton"/>
        {/* <Item name="columnChooserButton"/> */}
      </Toolbar>
        <Column
          dataField={'cpr_name'}
          caption={t('designation')}
          sortIndex={0}
          sortOrder="asc"
        />
        <Column
          dataField={'cpr_price'}
          caption={t('price')}
          editorType="dxNumberBox"
          calculateDisplayValue={function(rowData) {
              if(rowData.cpr_price === 0) return "-";
              return rowData.cpr_price+ ' '+t('MAD');
            }
          }
          editorOptions={
            {
              showSpinButtons: true,
              showClearButton: true,
              step: 5
            }
          }
        />
        <Column
          dataField={'cpr_status'}
          caption={t('status')}
          encodeHtml={false}
          calculateCellValue={prepareStatusValue}
          editorType="dxCheckBox"
          isValid={true}
        />
        <Column
          dataField={'cpr_note'}
          caption={t('note')}
        />
      </DataGrid>
  </React.Fragment>
)}

export default withTranslation()(ClientContractPrices);

