// import "./styles.scss";
import "leaflet/dist/leaflet.css";
import "leaflet-geosearch/dist/geosearch.css";
import "react-leaflet-fullscreen/dist/styles.css";
import "leaflet.locatecontrol/dist/L.Control.Locate.min.css";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";

import React, { useState} from 'react';
import { withTranslation } from 'react-i18next';
// import {ErrorBoundary} from 'react-error-boundary';

// import ReactDOM from 'react-dom';
// import { MapContainer, useMap } from "react-leaflet";
import L from 'leaflet';
// import MarkerClusterGroup from "react-leaflet-markercluster";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
// import { FullscreenControl } from "react-leaflet-fullscreen";
// import Locate from "leaflet.locatecontrol";
import "leaflet-routing-machine";
// import { Button } from 'devextreme-react/button';
// import { LoadPanel } from 'devextreme-react/load-panel';
// import { Popup, ToolbarItem } from 'devextreme-react/popup';
import DataGrid, {
  Column,
  // Grouping,
  // GroupPanel,
  // GroupItem,
  // SimpleItem,
  // Summary,
  Pager,
  Paging,
  FilterRow,
  Lookup,
  Toolbar,
  // Form,
  Editing,
  RequiredRule,
  // PatternRule,
  // RemoteOperations
} from 'devextreme-react/data-grid';

import { Button } from 'devextreme-react/button';
import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import CustomStore from 'devextreme/data/custom_store';
// import DataSource from 'devextreme/data/data_source';
// import 'devextreme/data/odata/store';

// import 'devextreme-react/text-area';
import { HOST_API } from "../../env";
// import $ from "jquery";
import { sendRequest } from '../../utils/utils';
import TripDetailsManager from '../../components/TripDetails/TripDetailsManager';


function ClientTripDetails(props) {
  const {t, data} = props;
  const tripData = data.row.data;
  const clientId = tripData.com_id_com;
  const tripId = tripData.tr_id_tr;
  
  // console.log("rowData")
  // console.log(rowData)
  // console.log("props")
  // console.log(props)

  const [isTDsPopupOpen, setTDsPopupVisibility] = useState(false);
  

  // let focusedRowChanged = false;

  const dxDateBoxOptions = {
    displayFormat: "dd/MM/yyyy",
    dateSerializationFormat: "yyyy-MM-dd"
  };

  let PickupPointsMap = null;
  let MapInitialized = false;
  let clientTripDetailsStoreData = null;
  let PickupPointsMapMarkers = null;
  // let MapOnEditMode = false;
  // let GridMapSyncEventSource = null;
  let mapContainer = null;

  const screen = (width) => {
    return (width < 700) ? 'sm' : 'lg';
  }

  var MarkerIcon = L.Icon.extend({
    options: {
      customId: "",
      iconUrl: '//raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
      shadowUrl: '//cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
      iconSize: [17, 28],
      iconAnchor: [8, 28],
      popupAnchor: [1, -34],
      shadowSize: [28, 28]
    }
  });

  let redMarker = new MarkerIcon({iconUrl: "//raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png"});
  let searchMarker = new MarkerIcon({iconUrl: "//raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-orange.png"});

  const routingControl = L.Routing.control({
    // waypoints: arrayOfLatLngs,
    waypoints: [],
    lineOptions: {
      // styles: [{ color: "#DF5D34", weight: 4 }]
      styles: [{
        color: "#FF7B00",
        className: 'animate'
      }] // Adding animate class
    },
    createMarker: function() { return null; },
    show: false,
    addWaypoints: false,
    routeWhileDragging: true,
    draggableWaypoints: true,
    fitSelectedRoutes: true,
    showAlternatives: false
  });

  const clientTripDetailsStore = new CustomStore({
    key: 'trd_id_trd',
    load: (loadOptions) => sendRequest(`${HOST_API}/v/tripdetails/${tripId}`, loadOptions, 'GET', {}),
    onLoaded: function (result) {
      clientTripDetailsStoreData = result.data;
      bindPointsMarkers(result.data);
    },
    insert: (values) => {
      if(!values.hasOwnProperty('trd_id_p')){
        values.trd_id_p = '0';
      }
      return sendRequest(`${HOST_API}/tripdetails`, null, 'POST', {...values, trd_id_tr:tripId})
    },
    update: function(key, values){
      let res = sendRequest(`${HOST_API}/tripdetails/update`, null, 'POST', {...values, trd_id_trd:key, trd_id_tr:clientId})
      return res;
    },
    remove: function(key){
      // return sendRequest(`${HOST_API}/geopoints`, null, 'DELETE', {key})
      return null;
    }
  })

  const clientPPsStore = new CustomStore({
    key: 'cp_id_cp',
    load: (loadOptions) => sendRequest(`${HOST_API}/v/contractspoints`, loadOptions, 'GET',{filter: ['["cn_id_comcl","=",'+clientId+']'], sort: ['[{"selector":"gp_name","asc":true}]'] })
  })
  
  // const clientPersonnelStore = new CustomStore({
  //   key: 'p_id_p',
  //   load: (loadOptions) => sendRequest(`${HOST_API}/v/personnels`,loadOptions,'GET',{filter: ['["p_id_com","=",'+clientId+']'], sort: ['[{"selector":"p_last_name","asc":true}]']})
  // })

  const InitializeMap = () => {
    // console.log("InitializeMap");
    // const { t } = withTranslation();
    mapContainer = document.getElementById('client_TD_Map_'+tripId);
    // PickupPointsMapMarkers = L.markerClusterGroup();
    PickupPointsMapMarkers = L.layerGroup();
    
    let attribution = '&copy; <a href="https://navetrack.com">NaveTrack</a>';
    let baseLayers = {
      "OSM Mapnik": L.tileLayer('//{s}.tile.osm.org/{z}/{x}/{y}.png', {attribution: attribution}),
      "Satellite ArcGIS": L.tileLayer("//server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}", {attribution: attribution}),
      "Dark": L.tileLayer('//{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png', {attribution: attribution}),
      // "Stadia Dark": L.tileLayer('//tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png', {attribution: attribution}),
      // "Landscape": L.tileLayer('//{s}.tile.thunderforest.com/landscape/{z}/{x}/{y}.png', {attribution: attribution}),
    };
    
    try {
      if (PickupPointsMap && PickupPointsMap.remove) {
        PickupPointsMap.off();
        PickupPointsMap.remove();
      }
    } catch (error) {
      console.log("Error: mapContainer appears to be not yet initialized");
    }
    
    PickupPointsMap = L.map(mapContainer, {
      fullscreenControl: true,
      attributionControl: false,
      center: [35.7545,-5.8324],
      zoom: 13,
      layers: [baseLayers["OSM Mapnik"]]
    });
    L.control.layers(baseLayers).addTo(PickupPointsMap);

    // PickupPointsMap.on('moveend zoomend', (e) => { 
    //   console.log("Map moveend/zoomend");
    // });
    
    // Setup Search option
    const search = new GeoSearchControl({
      provider: new OpenStreetMapProvider(),
        marker: {
          icon: searchMarker
        }
    });
    PickupPointsMap.addControl(search);
    
    // Setup GeoLocation option
    const locateOptions = {
      position: 'topleft', //topright
      strings: {
        title: 'Get my current location'
      },
      onActivate: () => {} 
    }
    PickupPointsMap.addControl(L.control.locate(locateOptions));
    
    // let mapLoadPanelObject = mapLoadPanel.current.instance;
    // mapLoadPanelObject.dispose();

    
    routingControl.addTo(PickupPointsMap);

    MapInitialized = true;
  }
  
  const bindPointsMarkers = markersData => {
    // return null;
    // GridMapSyncEventSource = "Grid";
    let arrayOfLatLngs = [];
    if(MapInitialized === false || PickupPointsMap === null){
      InitializeMap()
    }
    try {
      PickupPointsMapMarkers.clearLayers();
    } catch (error) {
      InitializeMap();
    }
    PickupPointsMapMarkers.clearLayers();
    // routingControl.
    if(Object.keys(markersData).length === 0) return null;
    
    markersData.map((item, i) => {
      if(item.gp_lat !== null && item.gp_lng !== null){
        arrayOfLatLngs.push([item.gp_lat, item.gp_lng]);
        // L.marker([item.gp_lat, item.gp_lng], {icon: redMarker, title:item.gp_name}).addTo(PickupPointsMap).bindTooltip(item.gp_name);
        PickupPointsMapMarkers.addLayer(
          L.marker(
          [item.gp_lat, item.gp_lng],
          {
            customId:item.gp_id_gp,
            icon: redMarker,
            geofence:item.gp_geofence,
          })
          .on('click', markerOnClick)
          .bindPopup("<h6>"+item.gp_name+"</h6><ul>"+
          "<li><b>Time:</b> "+item.trd_time.replace(/:00$/, "")+"</li>"+
          "<li><b>Description:</b> "+item.gp_description+"</li>"+
          "<li><b>District:</b> "+item.gp_district+", <b>City:</b> "+item.gp_city+"</li>"+
          "<li><b>GeoPoint:</b> "+item.gp_lat+", "+item.gp_lng+", <b>GeoFence:</b> "+item.gp_geofence+"</li>"+
          "</ul>")
          .bindTooltip('<div class="FocusedMarkerTooltip">'+item.gp_name+' [ '+item.trd_time.replace(/.* (.*):00/, "$1")+' ]</div>')
        );
        // PickupPointsMapMarkers.addLayer(L.circle([item.gp_lat, item.gp_lng], 150, {color: 'blue',fillColor: 'blue'}));
      }
    });
    if(arrayOfLatLngs.length>0){
      let bounds = new L.LatLngBounds(arrayOfLatLngs);
      PickupPointsMap.addLayer(PickupPointsMapMarkers);
      routingControl.getPlan().setWaypoints(arrayOfLatLngs);
      // const path = antPath(routingControl, {
      //   "delay": 800,
      //   "dashArray": [
      //     10,
      //     20
      //   ],
      //   "weight": 5,
      //   "color": "#005FFF",
      //   "pulseColor": "#FFFFFF",
      //   "paused": false,
      //   "reverse": false,
      //   "hardwareAccelerated": true
      // });
      // myMap.addLayer(path);
      PickupPointsMap.flyToBounds(bounds, {padding: [10, 10], duration:0.30} );
    }
    PickupPointsMap.invalidateSize();


  }
  
  const markerOnClick = e => {
    // let DataGridObject = DataGridRef.current.instance;
    // DataGridObject.navigateToRow(e.target.options.customId);
    // DataGridObject.option('focusedRowKey', e.target.options.customId)
  }
  
  // const getMapClickLatLng = loc => {
  //   PickupPointsMapMarkers.clearLayers();
  //   PickupPointsMapMarkers.addLayer(L.marker([loc.latlng.lat, loc.latlng.lng], {icon: redMarker}));

  //   let DataGridObject = DataGridRef.current.instance;
  //   let rowKey = DataGridObject.option('focusedRowKey')
  //   if(rowKey !== null){
  //     let row_index = DataGridObject.getRowIndexByKey(rowKey);
  //     DataGridObject.cellValue(row_index, DataGridObject.getVisibleColumnIndex("gp_lat"), loc.latlng.lat);
  //     DataGridObject.cellValue(row_index, DataGridObject.getVisibleColumnIndex("gp_lng"), loc.latlng.lng);
  //   }else{
  //     LatEditor.setValue( loc.latlng.lat );
  //     LngEditor.setValue( loc.latlng.lng );
  //   }
  // }
  
  // const onInitNewRow = e => {
  //   let DataGridObject = DataGridRef.current.instance;
  //   DataGridObject.option('focusedRowKey', null)
  //   PrepareMapLocationPicker(e);
  // }

  // const PrepareMapLocationPicker = e => {
  //   PickupPointsMapMarkers.clearLayers();
  //   if(Object.keys(e.data).length > 0){
  //     bindPointsMarkers([e.data])
  //   }
  //   PickupPointsMap.on("click", getMapClickLatLng);
  //   L.DomUtil.addClass(PickupPointsMap._container, 'crosshair-cursor-enabled');

  //   MapOnEditMode = true;
  // };

  
  // const onRowClick = e => {
  //   if(!focusedRowChanged){
  //     const dataRow = e.data;
  //     var dataGrid = e.component;
  //     dataGrid.clearSelection();
  //     dataGrid.option("focusedRowIndex", -1);
  //   }
  //   focusedRowChanged = false;
  // }

  // const onFocusedRowChanged = e => {
  //   focusedRowChanged = true;
  //   GridMapSyncEventSource = "Grid";
  //   if(e.rowIndex >= 0){
  //     const dataRow = e.row && e.row.data;
  //     PickupPointsMapMarkers.eachLayer(function(layer){
  //       if(layer.options.customId === dataRow.gp_id_gp){
  //         let layerLatLng = [layer._latlng.lat, layer._latlng.lng];
  //         PickupPointsMap.flyTo(layerLatLng, 15, {duration:1} );
  //         setTimeout(() => {
  //           layer.openPopup()
  //         }, 1000);
  //       }else{
  //         layer.closePopup();
  //       }
  //     });
  //   }else{
  //     bindPointsMarkers(clientTripDetailsStoreData); // to be optimized (a correct rezoom will be enough)
  //   }
  // }

  
  const ManageTrip = (e) => {
    console.log("ManageTrip")
    console.log(e)
    setTDsPopupVisibility(true);
  };

  const ParentCallBack = (response) => {
    // console.log("This is an alert from the Child Component")
    console.log(response)
    setTDsPopupVisibility(false);
  }

  return (
    <React.Fragment>

      {
      isTDsPopupOpen ? 
        <TripDetailsManager
          isTDsPopupOpen={isTDsPopupOpen}
          clientId={clientId}
          tripId={tripId}
          tripData={tripData}
          editMode={"full"}
          // returnTDs={false}
          // callBack={ParentCallBack}
          callBack={ParentCallBack}
        />
      : null
      }

      <ResponsiveBox
        singleColumnScreen="sm"
        screenByWidth={screen}>
        <Row ratio={1.2} screen="xs"></Row>
        <Row ratio={1}></Row>
        <Col ratio={1}></Col>
        <Col ratio={1} screen="lg"></Col>
        <Item>
          <Location row={0} col={0} screen="lg"></Location>
          <Location row={1} col={0} screen="sm"></Location>
          <DataGrid
            key={'client_TD_Grid_'+tripId}
            className={'dx-card wide-card client_TD_Grid'}
            // ref={DataGridRef}
            dataSource={clientTripDetailsStore}
            cacheEnabled={false}
            showBorders={true}
            remoteOperations={true}
            focusedRowEnabled={true}
            // defaultFocusedRowIndex={0}
            // autoNavigateToFocusedRow={true}
            allowColumnResizing={true}
            showColumnLines={true}
            rowAlternationEnabled={true}
            // columnResizingMode={"widget"}
            // columnAutoWidth={true}
            columnHidingEnabled={true}
            allowColumnReordering={true}

            // onInitNewRow={onInitNewRow}
            // onEditingStart={PrepareMapLocationPicker}
            // onRowInserted={ResetMap}
            // onEditCanceled={ResetMap}
            // onRowInserted={ResetMap}
            // onRowUpdated={ResetMap}
            // onRowRemoved={ResetMap}
            // onRowUpdated={ResetMap}
            // onEditorPrepared={onEditorPrepared}
            // onRowClick={onRowClick}
            // onFocusedRowChanged={onFocusedRowChanged}

            // onRowInserting={CheckMapUpdate}
            // onRowUpdating={updateRow}
            // onSaving={CheckMapUpdate2}
            // onSaved={CheckMapUpdate}
            >
            <Editing
              refreshMode="reshape"
              allowAdding={true}
              allowDeleting={true}
              >
              {/* <Form>
                <GroupItem colCount={1} colSpan={2}>
                  <SimpleItem dataField="gp_name" validationRules />
                  <SimpleItem dataField="gp_description" />
                  <SimpleItem dataField="gp_address" />
                </GroupItem>
                <GroupItem colCount={2} colSpan={2}>
                  <SimpleItem dataField="gp_district" />
                  <SimpleItem dataField="gp_city"/>
                </GroupItem>
                <GroupItem colCount={3} colSpan={2}>
                  <Item dataField="gp_lat" editorOptions={{ readOnly: true }}/>
                  <SimpleItem dataField="gp_lng" editorOptions={{ readOnly: true }} />
                  <SimpleItem dataField="gp_geofence"/>
                </GroupItem>
              </Form> */}
            </Editing>
            {/* <GroupPanel visible={true} />
            <Grouping autoExpandAll={true} /> */}
            <Paging defaultPageSize={100}/>
            <Pager
              visible={true}
              displayMode="compact"
              showPageSizeSelector={true}
              showInfo={true}
              infoText={2}
              allowedPageSizes={[10, 25, 50, 100, 500, 1000]}
              />
            <Toolbar className="GridToolbar">
              <Item location="after">
                <Button icon="preferences" text={t("Manage")} onClick={ManageTrip}/>
              </Item>
              {/* <Item location="after" name="addRowButton"/> */}
            </Toolbar>
            <FilterRow visible={true} />
            <Column
              dataField={'trd_id_cp'}
              caption={t('point name')}
              width={180}
              hidingPriority={0}
              // groupIndex={0}
              >
              <Lookup
                dataSource={clientPPsStore}
                displayExpr="gp_name"
                valueExpr="cp_id_cp"
              />
              <RequiredRule />
            </Column>
            <Column
              dataField={'p_first_name'}
              caption={t('first name')}
              className="capitalize-names"
              width={150}
              hidingPriority={0}
              />
            <Column
              dataField={'p_last_name'}
              caption={t('last name')}
              className="capitalize-names"
              width={150}
              hidingPriority={0}
              />
            {/* <Column dataField={'trd_id_p'} caption={t('Full name')}>
              <Lookup
                dataSource={clientPersonnelStore}
                displayExpr={function(item) {
                  return item && item.p_first_name + ' ' + item.p_last_name;
                }}
                valueExpr="p_id_p"
              />
            </Column> */}
            <Column
              dataField={'trd_time'}
              caption={t('pick-up time')}
              dataType="datetime"
              format="HH:mm"
              dateSerializationFormat="yyyy-MM-dd HH:mm"
              defaultSortIndex={0}
              defaultSortOrder="asc"
              />
            {/* <Summary>
              <GroupItem
                column="trd_id_trd"
                summaryType="count"
                showInColumn="trd_time"
                displayFormat={t("Number of personnel: ")+"{0}"}
                // customizeText={t("Count: ")+"{0}"}
                />
            </Summary> */}
          </DataGrid>
        </Item>
        <Item>
          <Location row={0} col={1} screen="lg"></Location>
          <Location row={2} col={0} screen="sm"></Location>
          <div id={"client_TD_Map_"+tripId} className="dx-card client_TD_Map">
            {/* <LoadPanel
              ref={mapLoadPanel}
              position={{ of: '#client_TD_Map_'+tripId }}
              shading={true}
              shadingColor="rgba(0,0,0,0.4)"
              visible={true}
              /> */}
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
}
export default withTranslation()(ClientTripDetails);