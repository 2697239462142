import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './i18nextConf'
import App from './App';

import themes from "devextreme/ui/themes";
let defaultTheme = "material.orange.light";
defaultTheme = "material.orange.light.compact";

let currentTheme = window.localStorage.getItem("dx-theme") || defaultTheme;
if(currentTheme !== defaultTheme && currentTheme !== defaultTheme.replace('light', 'dark')){
  // console.log("currentTheme:"+currentTheme+" defaultTheme:"+defaultTheme)
  currentTheme = defaultTheme;
}

themes.ready(() => {
  ReactDOM.render(<App />, document.getElementById('root'));
})
themes.current(currentTheme);
