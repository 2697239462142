import React from 'react';
import { withTranslation } from 'react-i18next';
import ClientContracts from './contracts';
import ClientSites from './sites';
import { HOST_API } from "../../env";
import { sendRequest } from '../../utils/utils';
import { LoadPanel } from 'devextreme-react/load-panel';
import Form, {Item, SimpleItem, GroupItem, Label} from 'devextreme-react/form';

class ClientInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clientInfo: {},
      isLoading: true,
      //positionOf: ''
    };
  }

  componentDidMount() {
    sendRequest(`${HOST_API}/v/companies/info/${this.props.clientId}`, null, 'GET', null)
    .then(response => {
      try{
        this.setState({
          clientInfo: response.data[0],
          isLoading: false
         });
      }catch(err){
        console.log(err);
      }
    });
  }
  
  render() {
    const clientInfoJSON = this.state.clientInfo;
    const { t } = this.props;
    
    const clientInfoCardOptions = {
      stylingMode: "underlined",
      readOnly: "true"
    };

    return (
      <React.Fragment>
        <div id={`clientInfoContainer_${this.props.clientId}`}>
          <Form colCount={3} formData={clientInfoJSON}>
            <GroupItem>              
              {/*<InfoCard data={clientInfoJSON}/> 
              <SimpleItem render={this.logoRender}></SimpleItem>
              <SimpleItem render={this.renderInfo}></SimpleItem> */}
              <GroupItem colCount={2}>
                <SimpleItem dataField="com_name" editorOptions={clientInfoCardOptions}>
                  <Label text={t('company name')}></Label>
                </SimpleItem>
                <SimpleItem dataField="com_ICE" editorOptions={clientInfoCardOptions}>
                  <Label text={t('ice')}></Label>
                </SimpleItem>
              </GroupItem>
              <GroupItem colCount={2}>
                <SimpleItem dataField="com_activity" editorOptions={clientInfoCardOptions}>
                  <Label text={t('activity')}></Label>
                </SimpleItem>
                <SimpleItem dataField="com_district" editorOptions={clientInfoCardOptions}>
                  <Label text={t('district')}></Label>
                </SimpleItem>
              </GroupItem>
              <GroupItem colCount={2}>
                <SimpleItem dataField="com_phone" editorOptions={clientInfoCardOptions}>
                  <Label text={t('phone')}></Label>
                </SimpleItem>
                <SimpleItem dataField="com_email" editorOptions={clientInfoCardOptions}>
                  <Label text={t('email')}></Label>
                </SimpleItem>
              </GroupItem>
              <SimpleItem dataField="Address" editorOptions={clientInfoCardOptions}>
                <Label text={t('address')}></Label>
              </SimpleItem>
              <SimpleItem dataField="com_note" editorType="dxTextArea" editorOptions={clientInfoCardOptions}>
                <Label text={t('note')}></Label>
              </SimpleItem>
            </GroupItem>
            <GroupItem colCount={2} colSpan={2}>  
              <GroupItem colSpan={2}>
                <ClientContracts clientId={this.props.clientId} />
              </GroupItem>
              <GroupItem colSpan={2}>
                <ClientSites clientId={this.props.clientId} />
              </GroupItem>
            </GroupItem>
          </Form>
        </div>

        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          //position={this.state.positionOf}
          visible={this.state.isLoading}
          showIndicator={true}
          shading={true}
          showPane={true}
          closeOnOutsideClick={false}
        />
      </React.Fragment>
    );
  }
}

export default withTranslation()(ClientInfo);