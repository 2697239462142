export { default as HomePage } from './home/home';
export { default as ProfilePage } from './profile/profile';
export { default as TasksPage } from './tasks/tasks';
export { default as CompaniesPage } from './companies/companies';
export { default as ClientsPage } from './clients/clients';
export { default as SubcontractorsPage } from './subcontractors/subcontractors';
export { default as VehiclesPage } from './vehicles/vehicles';
export { default as PersonnelPage } from './personnel/personnel';
export { default as PickupPointsPage } from './PickupPoints/PickupPoints';
export { default as TripsPage } from './trips/trips';
export { default as GPSPlatformsPage } from './gpsplatforms/gpsplatforms';