import React, {useState} from 'react';
import { withTranslation } from 'react-i18next';
import DataGrid, {
  Column,
  Format,
  Button,
  Pager,
  Paging,
  FilterRow,
  ColumnChooser,
  Lookup,
  Form,
  Editing,
  Popup,
  RequiredRule,
  PatternRule,
  MasterDetail,
  Toolbar,
  Item,
  LoadPanel
} from 'devextreme-react/data-grid';

import CustomStore from 'devextreme/data/custom_store';
// import DataSource from 'devextreme/data/data_source';
// import 'devextreme/data/odata/store';

import { GroupItem, SimpleItem, EmptyItem, Label} from 'devextreme-react/form';

import { formatDate } from 'devextreme/localization';
import clientTripDetails from './clientTripDetails';
// import 'whatwg-fetch';
import { HOST_API } from "../../env";
// import $ from "jquery";
import DateBox from 'devextreme-react/date-box';
import {logRequest, sendRequest, isNotEmpty} from '../../utils/utils';


function ClientTrips({t, clientId}) {
  
  const [isTDsPopupOpen, setTDsPopupVisibility] = useState(false);
  
  const dxDateBoxOptions = {
    displayFormat: "dd/MM/yyyy HH:mm",
    dateSerializationFormat: "yyyy-MM-dd HH:mm",
    type: "datetime",
    showClearButton: true
  };

  const TripDirectionStore = [
    {directionTitle: t('in'), directionVal: 'in'},
    {directionTitle: t('out'), directionVal: 'out'}
  ]

  const TripPriceStore = [
    {cpr_name: t('default'), cpr_price: 90},
    // {directionTitle: t('out'), directionVal: 'out'}
  ]

  const clientTripsStore  = new CustomStore({
    key: 'tr_id_tr',
    load: (loadOptions) => sendRequest(`${HOST_API}/v/trips`,loadOptions,'GET', {filter:['["cos_id_com","=","'+clientId+'"]']}),
    insert: (values) => {
      return sendRequest(`${HOST_API}/trips`, null, 'POST', {...values })
    },
    update: function(key, values){
      let res = sendRequest(`${HOST_API}/trips/update`, null, 'POST', {...values, tr_id_tr:key})
      console.log("res")
      console.log(res)
      // return "Error";
      return res;
    },
    remove: (key) => sendRequest(`${HOST_API}/trips`,null, 'DELETE', {
      key,
    }),
  })

  const CompaniesStore = new CustomStore({
    key: "com_id_com",
    loadMode: "raw",
    load: (loadOptions) => sendRequest(`${HOST_API}/v/companies`, loadOptions, 'GET', {skip:0, take:99999, requireTotalCount: "false", filter:['["ct_type","!=","client"]'], sort: ['[{"selector":"com_name","asc":true}]'] })
  });
  
  const CompanySitesStore = new CustomStore({
    key: "cos_id_cos",
    loadMode: "raw",
    load: (loadOptions) => sendRequest(`${HOST_API}/v/sites`, loadOptions, 'GET', {skip:0, take:99999, requireTotalCount: "false", filter:['["cos_id_com","=","'+clientId+'"]'], sort: ['[{"selector":"cos_name","asc":true}]'] })
  });

  // const VehiclesStore = new CustomStore({
  //   key: "v_id_v",
  //   loadMode: "raw",
  //   load: (loadOptions) => sendRequest(`${HOST_API}/v/vehicles`, loadOptions, 'GET', {skip:0, take:99999, requireTotalCount: "false", sort: ['[{"selector":"v_label","asc":true}]'] })
  // });
  
  const  getFilteredVehicles = (options) => {
    let vehiclesFilter = options.data ? '["v_id_com","=","'+options.data.v_id_com+'"]' : null;
    return {
      store: new CustomStore({
        key: "v_id_v",
        loadMode: "raw",
        load: (options) => sendRequest(`${HOST_API}/v/vehicles`, options, 'GET', {skip:0, take:99999, requireTotalCount: "false", filter:[vehiclesFilter], sort: ['[{"selector":"v_label","asc":true}]'] })
      }),
      filter: options.data ? ['v_id_com', '=', options.data.v_id_com] : null,
    };
  }

  const  getFilteredDrivers = (options) => {
    let driverFilter = options.data ? '["p_id_com","=","'+options.data.v_id_com+'"]' : null;
    return {
      store: new CustomStore({
        key: "v_id_pdrv",
        loadMode: "raw",
        load: (options) => sendRequest(`${HOST_API}/v/drivers`, options, 'GET', {skip:0, take:99999, requireTotalCount: "false", filter:[driverFilter], sort: ['[{"selector":"p_first_name","asc":true}]'] })
      }),
      filter: options.data ? ['p_id_com', '=', options.data.v_id_com] : null,
    };
  }
  
  function setCompanyValue(rowData, value){
    rowData.tr_id_p_dr = null;
    rowData.tr_id_v = null;
    this.defaultSetCellValue(rowData, value);
  }

  return (
    <React.Fragment>
      {/*<div className={'dx-card responsive-paddings'}>*/}
        <DataGrid
          className={'dx-card wide-card'}
          keyExpr="tr_id_tr"
          dataSource={clientTripsStore}
          cacheEnabled={false}
          showBorders={true}
          remoteOperations={true}
          focusedRowEnabled={true}
          // defaultFocusedRowIndex={0}
          rowAlternationEnabled={true}
          columnAutoWidth={true}
          columnHidingEnabled={true}
          allowColumnReordering={true}
          allowColumnResizing={true}
          
          onRowExpanding={function(e){
              e.component.collapseAll(-1);
            }
          }
          >
          {/* <ColumnChooser enabled={true} /> */}
          <MasterDetail
            enabled={true}
            component={clientTripDetails}
            />
          <Editing
            mode="popup"
            allowAdding={true}
            allowUpdating={true}
            allowDeleting={true}>
            <Popup title={t('Trip info')} showTitle={true} shading={true} resizeEnabled={true} dragEnabled={true} height={400} />
            <Form>
              <GroupItem colCount={3} colSpan={2}>
                <SimpleItem dataField="tr_id_cos"/>
                <SimpleItem dataField="tr_direction"/>
                <EmptyItem/>
              </GroupItem>
              <GroupItem colCount={3} colSpan={2}>
                <SimpleItem dataField="tr_name"/>
                <SimpleItem dataField="tr_datetime" editorType="dxDateBox" editorOptions={dxDateBoxOptions} />
                <SimpleItem dataField="tr_price" />
              </GroupItem>
              <GroupItem colCount={3} colSpan={2}>
                <SimpleItem dataField="v_id_com" />
                <SimpleItem dataField="tr_id_v"/>
                <SimpleItem dataField="tr_id_p_dr"/>
              </GroupItem>
            </Form>
          </Editing>
          
          <Paging defaultPageSize={10} />
          <Pager
            showPageSizeSelector={true}
            showInfo={true} 
            showPageSizeSelector={true}
            allowedPageSizes={[10, 25, 50, 100, 500, 1000]}/>
          <FilterRow visible={true} />
          <Column dataField={'tr_id_cos'} caption={t('site name')} hidingPriority={8}>
            <RequiredRule />
            <Lookup dataSource={CompanySitesStore} displayExpr="cos_name" valueExpr="cos_id_cos"/>
          </Column>
          <Column dataField={'tr_name'} caption={t('trip name')} hidingPriority={10} >
            <RequiredRule />
          </Column>
          <Column dataField={'tr_datetime'} dataType="datetime" format="dd/MM/yyyy HH:mm" caption={t('trip time')} hidingPriority={10} >
            <RequiredRule />
          </Column>
          <Column dataField={'tr_direction'} caption={t('direction')} hidingPriority={8}>
            <RequiredRule />
            <Lookup
              dataSource={TripDirectionStore}
              displayExpr="directionTitle"
              valueExpr="directionVal" 
              // defaultValue={TripDirectionStore[0]}
              searchEnabled={false}
              />
          </Column>
          <Column
            dataField={'tr_price'}
            caption={t('trip price')}
            hidingPriority={8}
            calculateDisplayValue={function(rowData) {
                if(rowData.tr_price === 0) return "-";
                let displayValue = rowData.tr_price+ ' '+t('MAD');
                TripPriceStore.forEach((item) => {
                  if (item.cpr_price === rowData.tr_price) {
                    displayValue = item.cpr_name + ' (' + item.cpr_price+ ' '+t('MAD')+')';
                    return;
                  }
                });
                return displayValue;
              }
            }
            editorOptions={
              {
                showClearButton: true,
                acceptCustomValue: true,
                onCustomItemCreating: function(args){
                  return { cpr_name: args.text, cpr_price: args.text  };
                }
              }
            }
            >
            <RequiredRule />
            <Lookup
              dataSource={TripPriceStore}
              displayExpr={function(item) {
                console.log("item")
                console.log(item)
                if(item && item.cpr_name !== undefined) return item.cpr_name + ' (' + item.cpr_price+ ' MAD)';
                else return item;
              }}
              valueExpr="cpr_price"
              searchEnabled={false}
              />
          </Column>
          
          
          <Column dataField={'v_id_com'} caption={t('service provider')} setCellValue={setCompanyValue} hidingPriority={7} >
            <RequiredRule />
            <Lookup dataSource={CompaniesStore} displayExpr="com_name" valueExpr="com_id_com" defaultValue={CompaniesStore[0]}/>
          </Column>
          <Column dataField={'tr_id_v'} caption={t('vehicle')} hidingPriority={3}>
            <Lookup dataSource={getFilteredVehicles} displayExpr="v_label" valueExpr="v_id_v"/>
          </Column>
          <Column dataField={'tr_id_p_dr'} caption={t('driver name')} hidingPriority={2}>
            <Lookup 
              dataSource={getFilteredDrivers}
              displayExpr={function(item) {
                return item && item.p_first_name + ' ' + item.p_last_name;
              }}
              valueExpr="p_id_p"
            />
          </Column>
          <Column dataField={'p_phone'} caption={t('driver phone')} hidingPriority={7}/>



          <Toolbar>
            <Item location="after">
              <Button
                icon='refresh'
                // onClick={this.refreshDataGrid}
                />
            </Item>
            <Item
              name="addRowButton"
            />
        </Toolbar>
        </DataGrid>
        
      {/*  </div>  */}
    </React.Fragment>
)}

export default withTranslation()(ClientTrips);
