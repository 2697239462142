import "./styles.scss";
import "leaflet/dist/leaflet.css";
import "leaflet-geosearch/dist/geosearch.css";
import "react-leaflet-fullscreen/dist/styles.css";
import "leaflet.locatecontrol/dist/L.Control.Locate.min.css";

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { withTranslation } from 'react-i18next';
// import ReactDOM from 'react-dom';
import { MapContainer, TileLayer, Marker, useMap } from "react-leaflet";
import L, {LatLng, latLngBounds, FeatureGroup} from 'leaflet';
import MarkerClusterGroup from "react-leaflet-markercluster";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
import { FullscreenControl } from "react-leaflet-fullscreen";
import Locate from "leaflet.locatecontrol";
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import { Button } from 'devextreme-react/button';
import { Switch } from 'devextreme-react/switch';
import { LoadPanel } from 'devextreme-react/load-panel';
import { confirm } from 'devextreme/ui/dialog';

import DataGrid, {
  // Column,
  // Editing,
  // FilterRow,
  // HeaderFilter,
  // Form,
  // Lookup,
  // Pager,
  // Paging,
  // PatternRule,
  // RequiredRule,
  // RemoteOperations,
  // Selection
} from 'devextreme-react/data-grid';

import TreeList, {
  Column,
  ColumnChooser,
  Editing,
  FilterRow,
  HeaderFilter,
  Form,
  Lookup,
  Pager,
  Paging,
  PatternRule,
  RequiredRule,
  RemoteOperations,
  SearchPanel,
  Selection,
  Toolbar,
} from 'devextreme-react/tree-list';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import CustomStore from 'devextreme/data/custom_store';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';

// import 'devextreme/data/odata/store';
import { GroupItem, SimpleItem, Label} from 'devextreme-react/form';
import DateBox from 'devextreme-react/date-box';

// import 'devextreme-react/text-area';
import { HOST_API } from "../../env";
import $ from "jquery";

import {logRequest, sendRequest, isNotEmpty} from '../../utils/utils';

function TripDetailsManager(props) {
  const { t, isPopupOpen, clientId, tripId, tripData, editMode, callBack } = props;
  console.log("tripData");
  console.log(tripData);
  // const [markers, setMarkers] = useState(0);
  // let TPsLoaded = true;

  const TDMResultList = useRef(null);
  let TDMSavedPointTimes = [];

  // let TPsLatEditor = useRef(null);
  // let TPsLngEditor = useRef(null);
  // let TPsMapLoadPanel = useRef(null);
  let TPsPopupValidate = useRef(null);

  let TPsFocusedRowChanged = false;
  let TPsMap = null;
  let TPsMapInitialized = false;
  let TPsStoreData = null;
  let TPsMapMarkers = null;
  let TPsMapOnEditMode = false;
  let TPsActivePointData = null;
  // let GridMapSyncEventSource = null;
  
  const TPsScreen = (width) => {
    return (width < 700) ? 'sm' : 'lg';
  }

  const TPsPopupSwitchButtons = (disabled) => {
    let TPsPopupValidateObject = TPsPopupValidate.current.instance;
    // let TPsPopupClearObject = TPsPopupClear.current.instance;
    TPsPopupValidateObject.option("disabled", disabled);
    // TPsPopupClearObject.option("disabled", disabled);
  }
  
  const TPsonClearButtonClicked = (e) => {
    console.log("e");
  }
  
  // const TPsStore = new CustomStore({
  //   key: 'gp_id_gp',
  //   load: (loadOptions) => sendRequest(`${HOST_API}/v/geopoints`,loadOptions,'GET',{sort: ['[{"selector":"gp_name","asc":true}]'] }),
  //   onLoaded: function (result) {
  //     TPsStoreData = result.data;
  //     TPsBindPointsMarkers(result.data);
  //     TPsActivePointData = null;
  //     TPsPopupSwitchButtons(true);
  //   },
  //   insert: (values) => {
  //     return sendRequest(`${HOST_API}/geopoints`, null, 'POST', {...values })
  //   },
  //   update: function(key, values){
  //     let res = sendRequest(`${HOST_API}/geopoints/update`, null, 'POST', {...values, gp_id_gp:key})
  //     return res;
  //   },
  //   remove: function(key){
  //     return sendRequest(`${HOST_API}/geopoints`, null, 'DELETE', {key})
  //   }
  // })
  
  const preCallBack = data => {
    try {
      // let TPsDataGridObject = TPsDataGridRef.current.instance;
      // TPsDataGridObject.cancelEditData()
    } catch (error) {}
    callBack(data);
  }
  
  
  const closePopup = e => {
    // let TPsDataGridObject = TPsDataGridRef.current.instance;
    // const focusedRowKey = TPsDataGridObject.option('focusedRowKey');
    preCallBack("test data ");
  }
  


  /***************************************************************************************************** */
  const PersonnelsLevelsStore = new CustomStore({
    key: "pl_id_pl",
    loadMode: "raw",
    load: (loadOptions) => sendRequest(`${HOST_API}/v/personnelslevels`, loadOptions, 'GET', {skip:0, take:99999, requireTotalCount: "false", filter:['["pl_id_ct","=","3"]'], sort: ['[{"selector":"pl_level","asc":true}]'] })
  });

  const TeamsStore = new CustomStore({
    key: "tm_id_tm",
    loadMode: "raw",
    load: (loadOptions) => sendRequest(`${HOST_API}/v/teams`, loadOptions, 'GET', {skip:0, take:99999, requireTotalCount: "false", filter:['["tm_id_com","=","'+clientId+'"]'], sort: ['[{"selector":"tm_team","asc":true}]'] })
  });

  const ShiftsStore = new CustomStore({
    key: "sh_id_sh",
    loadMode: "raw",
    load: (loadOptions) => sendRequest(`${HOST_API}/v/shifts`, loadOptions, 'GET', {skip:0, take:99999, requireTotalCount: "false", filter:['["sh_id_com","=","'+clientId+'"]'], sort: ['[{"selector":"sh_shift","asc":true}]'] })
  });
  
  const GeoPointStore = new CustomStore({
    key: "gp_id_gp",
    loadMode: "raw",
    load: (loadOptions) => sendRequest(`${HOST_API}/v/geopoints`, loadOptions, 'GET', {skip:0, take:99999, requireTotalCount: "false", sort: ['[{"selector":"gp_name","asc":true}]'] })
  });


  const PersonnelsStore = new CustomStore({
    key: 'p_id_p',
    load: (loadOptions) => sendRequest(`${HOST_API}/v/personnels`,loadOptions,'GET',{filter: ['["p_id_com","=",'+clientId+']']})
  })

  const PersonnelByPickupPoint = new CustomStore({
    key: 'uid',
    load: (loadOptions) => sendRequest(`${HOST_API}/personnels/pickUpPoints/${clientId}`,loadOptions,'GET'),
    onLoaded: function (result) {
      console.log("PersonnelByPickupPoint loaded data")
      console.log(result)
      sendRequest(`${HOST_API}/v/tripdetails/${tripId}`, null, 'GET', {skip:0, take:99999, requireTotalCount: "false"})
      .then(response => {
        console.log("tripdetails "+tripId+" response")
        console.log(response.data)
        // if (!response.ok) {
        //   throw new Error(`HTTP error: ${res.status} ${res.statusText}`);
        // }
        // return response.json();
      })
    }
  })

  const prepareStatusValue = rowData => {
    return rowData.u_active === '1' ? true : false;
  };
  

  /***************************************************************************************************** */
  
  
  const renderPointName = (obj) => {
    if(obj.data.gp_name === 'UNDEFINED') obj.data.gp_name = t('Undefined');
    if(obj.data.count_personnel === undefined) return <div>{obj.data.gp_name}</div>;
    else return <div>{obj.data.gp_name} (<b>{obj.data.count_personnel}</b>)</div>;
  };
  

  const renderResultListPointTime = (obj) => {
    if(obj.data.parentId !== 0) return <></>;
    // console.log("renderResultListPointTime obj.data")
    // console.log(obj.data)
    if(obj.data.point_time === '') obj.data.point_time = null;
    return <div>
      <DateBox
        defaultValue={obj.data.point_time}
        type="time"
        pickerType="list"
        interval={5}
        width={100}
        onValueChanged={onPointTimeValueChanged}
        uid={obj.data.uid}
      />
    </div>
    // if(obj.data.gp_name === 'UNDEFINED') obj.data.gp_name = t('Undefined');
    // if(obj.data.count_personnel === undefined) return <div>{obj.data.gp_name}</div>;
    // else return <div>{obj.data.gp_name} (<b>{obj.data.count_personnel}</b>)</div>;
  };
  
  const onPointTimeValueChanged = (e) => {
    let key = e.component.option("uid");
    TDMSavedPointTimes[key] = e.value;
    let TDMResultListDataSourceObj = TDMResultList.current.instance.option('dataSource');
    const store = TDMResultListDataSourceObj.store();
    store.update(key, { point_time: e.value })
    .then(
      (dataObj) => {
        TDMResultListDataSourceObj.reload();
      },
      (error) => { /* ... */ }
    );
  }

  const onSelectionChanged = (e) => {
    console.log("onSelectionChanged")
    console.log(e)

    let selectedData = [];
    let selectedPersonnelByPoint = {}
    let list = e.component.getSelectedRowKeys("leavesOnly");
    for (var i = 0; i < list.length; i++) {
      let node = e.component.getNodeByKey(list[i]);
      if(node.level>0){
        let nodeParent = node.parent;
        if(selectedData.indexOf(nodeParent.data) === -1) {
          selectedPersonnelByPoint[nodeParent.data.uid] = 0;
          selectedData.push(nodeParent.data)
        }
        selectedPersonnelByPoint[nodeParent.data.uid] += 1;
      }else{
        selectedPersonnelByPoint[node.data.uid] = 0;
      }
      selectedData.push(node.data)
    }

    $.each(selectedData, function (index, item) {
      if(item.parentId === 0){
        item.count_personnel = selectedPersonnelByPoint[item.uid];
        if(item.point_time === null && TDMSavedPointTimes[item.uid] !== undefined){
          selectedData[index].point_time = TDMSavedPointTimes[item.uid];
        }
      }
    });

    // console.log("selectedData")
    // console.log(selectedData)

    const dataSource = new DataSource({
      store: new ArrayStore({
        data: selectedData,
        key: "uid"
      }),
      key: 'uid',
    })
    TDMResultList.current.instance.option('dataSource', dataSource)

  }
  

  return (
    <React.Fragment>
      <Popup
        visible={true}
        title={t("Manage trip details")}
        showCloseButton={false}
        height={"75vh"}
        resizeEnabled={true}
        showTitle={true}
        >
        <ToolbarItem location="after">
          <Button icon="close"
            onClick={(e) => {
              preCallBack(null);
            }}
          />
        </ToolbarItem>
        <ToolbarItem
          toolbar="bottom"
          location="after"
          visible={true}
          >
          {/* <Button
            ref={TPsPopupClear}
            text={t("Clear Selection")}
            stylingMode="contained"
            onClick={TPsonClearButtonClicked}
          /> */}
          <Button
            ref={TPsPopupValidate}
            text={t("Validate")}
            type="default"
            stylingMode="contained"
            onClick={(e) => {
              preCallBack(TPsActivePointData);
            }}
          />
        </ToolbarItem>

        <ScrollView width='100%' height='100%'>
          <ResponsiveBox
            singleColumnScreen="sm"
            screenByWidth={TPsScreen}>
            <Row ratio={1.3} screen="xs"></Row>
            <Row ratio={2}></Row>
            <Col ratio={1.2}></Col>
            <Col ratio={2} screen="lg"></Col>
            <Item>
              <Location row={0} col={0} screen="lg"></Location>
              <Location row={1} col={0} screen="sm"></Location>
              <TreeList
                className={'dx-card wide-card'}
                id="TDMSourceList"
                keyExpr="uid"
                // parentIdExpr="cp_id_cp"
                dataSource={PersonnelByPickupPoint}
                // cacheEnabled={false}
                showBorders={true}
                showRowLines={true}
                columnAutoWidth={true}
                // focusedRowEnabled={true}
                allowColumnResizing={true}
                rowAlternationEnabled={true}
                columnHidingEnabled={true}
                allowColumnReordering={true}
                itemsExpr="personnel"
                dataStructure="tree"
                onSelectionChanged={onSelectionChanged}
                >
                <RemoteOperations filtering={false} sorting={false} grouping={false} />
                
                <Toolbar className="GridToolbar">
                    <Item location="before">
                      <h6>{t('Select pickup-point/personnel')}</h6>
                    </Item>
                    <Item name="columnChooserButton"  location="after"/>
                    <Item name="searchPanel"  location="after"/>
                </Toolbar>
                
                <SearchPanel visible={true} />
                <ColumnChooser enabled={true}/>
                <FilterRow visible={true} />
                <Selection recursive={true} mode="multiple" />
                {/* <Paging defaultPageSize={100}/> */}
                <Pager
                  visible={false}
                  displayMode="compact"
                  showPageSizeSelector={true}
                  showInfo={true}
                  infoText={2}
                  allowedPageSizes={[10, 25, 50, 100, 500, 1000]}/>
                <Column dataField="cp_id_cp" visible={false} />
                <Column dataField="cp_id_gp" visible={false} />
                <Column dataField="gp_name" caption={t('point name')} cellRender={renderPointName}/>
                <Column dataField="point_time" caption={t('point time')} visible={false} />
                <Column dataField="p_id_p" visible={false}/>
                <Column dataField="p_full_name" caption={t('full name')} />
                <Column dataField="p_first_name" caption={t('first name')}  visible={false}/>
                <Column dataField="p_last_name" caption={t('last name')}  visible={false}/>
                <Column dataField="p_phone" caption={t('phone')} hidingPriority={3} />
                <Column dataField={'p_id_tm'} caption={t('team')} hidingPriority={1} >
                  <Lookup dataSource={TeamsStore} displayExpr="tm_team" valueExpr="tm_id_tm" />
                </Column>
                <Column dataField={'p_id_sh'} caption={t('shift_short')} hidingPriority={2}>
                  <Lookup dataSource={ShiftsStore} displayExpr="sh_shift" valueExpr="sh_id_sh" />
                </Column>

              </TreeList>

              


              
            </Item>
            <Item>
              <Location row={0} col={1} screen="lg"></Location>
              <Location row={2} col={0} screen="sm"></Location>

              
              <TreeList
                className={'dx-card wide-card'}
                ref={TDMResultList}
                id="TDMResultList"
                keyExpr="uid"
                rootValue={0}
                parentIdExpr="parentId"
                // dataSource={TDMResultListStore}
                repaintChangesOnly={true}
                showBorders={true}
                showRowLines={true}
                columnAutoWidth={true}
                // focusedRowEnabled={true}
                allowColumnResizing={true}
                rowAlternationEnabled={true}
                columnHidingEnabled={true}
                allowColumnReordering={true}
                // itemsExpr="personnel"
                // dataStructure="tree"
                >
                {/* <RemoteOperations filtering={false} sorting={false} grouping={false} /> */}
                
                <Toolbar className="GridToolbar">
                    <Item location="before">
                      <h6>{t('current selection')}</h6>
                    </Item>
                    {/* <Item location="after">
                      <Button icon="preferences" text={t("Manage")} />
                    </Item> */}
                </Toolbar>
                
                <FilterRow visible={true} />
                <SearchPanel visible={true} />
                <Pager
                  visible={false}
                  displayMode="compact"
                  showPageSizeSelector={true}
                  showInfo={true}
                  infoText={2}
                  allowedPageSizes={[10, 25, 50, 100, 500, 1000]}/>
                <Column dataField="cp_id_cp" visible={false} />
                <Column dataField="cp_id_gp" visible={false} />
                <Column dataField="gp_name" caption={t('point name')} cellRender={renderPointName}/>
                <Column dataField="point_time" caption={t('point time')} defaultSortIndex={0} defaultSortOrder="asc" cellRender={renderResultListPointTime} />
                <Column dataField="p_id_p" visible={false}/>
                <Column dataField="p_full_name" caption={t('full name')} />
                <Column dataField="p_first_name" caption={t('first name')}  visible={false}/>
                <Column dataField="p_last_name" caption={t('last name')}  visible={false}/>
                <Column dataField="p_phone" caption={t('phone')} hidingPriority={1} />

              </TreeList>

              {/* <DataGrid
                className={'dx-card wide-card'}
                keyExpr="p_id_p"
                dataSource={PersonnelsStore}
                showBorders={true}
                remoteOperations={false}
                focusedRowEnabled={true}
                defaultFocusedRowIndex={0}
                allowColumnResizing={true}
                // columnResizingMode={"widget"}
                // columnAutoWidth={true}
                columnHidingEnabled={true}
                allowColumnReordering={true}
                cacheEnabled={false}
              >
                <Paging defaultPageSize={10} />
                <Pager
                  showPageSizeSelector={true}
                  showInfo={true}
                  allowedPageSizes={[10, 25, 50, 100, 500, 1000]}/>
                <FilterRow visible={true} />
                <Column dataField={'p_first_name'}		  caption={t('first name')}	 />
                <Column dataField={'p_last_name'}		    caption={t('last name')}   />
                <Column dataField={'p_id_gp'} caption={t('pick-up point')}  hidingPriority={11} >
                  <Lookup dataSource={GeoPointStore} displayExpr="gp_name" valueExpr="gp_id_gp"  /> 
                </Column>
                <Column dataField={'p_phone'}		    	  caption={t('phone')}      hidingPriority={9}     />
                <Column dataField={'p_id_tm'}           caption={t('team')}  hidingPriority={5} >
                  <Lookup  dataSource={TeamsStore}      displayExpr="tm_team"      valueExpr="tm_id_tm"  />
                </Column>
                <Column dataField={'p_id_sh'}           caption={t('shift_short')}  hidingPriority={7}>
                  <Lookup  dataSource={ShiftsStore}     displayExpr="sh_shift"     valueExpr="sh_id_sh"  />
                </Column>
                <Column dataField={'p_district'}  caption={t('district')} hidingPriority={4}  />
                <Column dataField={'p_city'}  caption={t('city')} hidingPriority={1}  />
                <Column dataField={'p_address'}   caption={t('address')}  hidingPriority={3}  />
              </DataGrid> */}
              
            </Item>
          </ResponsiveBox>
        </ScrollView>
      </Popup>
    </React.Fragment>
  );
}
export default withTranslation()(TripDetailsManager);