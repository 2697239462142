import React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { Switch, Route, Redirect } from 'react-router-dom';
import routes from './app-routes';
import { SideNavOuterToolbar as SideNavBarLayout } from './layouts';
import { Footer } from './components';

function Content({t}) {
  return (
    <SideNavBarLayout title={t('app_title')}>
      <Switch>
        {routes.map(({ path, component }) => (
          <Route
            exact
            key={path}
            path={path}
            component={component}
          />
        ))}
        <Redirect to={'/home'} />
      </Switch>
      <Footer>
        {/* Copyright © 2020-{new Date().getFullYear()} {<Trans>{appInfo.title}</Trans>} . */}
        {t('copyright')} © 2020-{new Date().getFullYear()} {t('app_title')} .
        <br />
        {t('trademarks_copyright')}
      </Footer>
    </SideNavBarLayout>
  );
}

export default withTranslation ()(Content);