import i18n from "i18next";
import { initReactI18next } from 'react-i18next';
import translationAR from './locales/ar/translation.json';
import translationEN from './locales/en/translation.json';
import translationFR from './locales/fr/translation.json';


// the translations
const resources = {
  ar: {
    translation: translationAR
  },
  en: {
    translation: translationEN
  },
  fr: {
    translation: translationFR
  }
};
const lang = window.localStorage.getItem("lang") || "en"
console.log("==>", lang);

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: lang,
  // debug only when not in production
  // debug: process.env.NODE_ENV !== 'production',
  //ns: ['translations'],
  //defaultNS: 'translations',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
    formatSeparator: ',',
  },
  react: {
    wait: true,
  },
});

export default i18n;
