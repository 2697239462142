import React, {/* lazy,*/ useState, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { HOST_API } from "../../env";
import {sendRequest} from '../../utils/utils';
import CustomStore from 'devextreme/data/custom_store';
import PickupPointQuickSelectComp from '../../components/PickupPoints/PickupPointQuickSelect';
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  Lookup,
  // Toolbar,
  Form,
  Editing,
  Popup,
  // RequiredRule,
  // PatternRule, 
} from 'devextreme-react/data-grid';

// import { TextBox, Button, Button as TextBoxButton } from 'devextreme-react/text-box';
import {Item, SimpleItem, GroupItem/*, Label*/} from 'devextreme-react/form';
// import { ToolbarItem } from 'devextreme-react/popup';
// import FileUploader from 'devextreme-react/file-uploader';
import ResponsiveBox, { Row, Col, Location } from 'devextreme-react/responsive-box';
import notify from 'devextreme/ui/notify';

import PersonnelTeams from './personnelTeams';
import PersonnelShifts from './personnelShifts';
import PersonnelUploader from './personnelUploader';
import passwordGenerator from 'generate-password';

function ClientPersonnel(props) {
  const {t, clientId ,clientData,clientType} = props;
  const [isPPsPopupOpen, setPPsPopupVisibility] = useState(false);
  // const [passwordValue, setPasswordValue] = useState("");

  let ShowCompany = false;
	if(!isNaN(clientId))
		ShowCompany = true;

  let usernameRef = useRef(null);
  let passwordRef = useRef(null);
  let activeRef = useRef(null);
 
  const screen = (width) => {
    return (width < 700) ? 'sm' : 'lg';
  } 

  const PersonnelsLevelsStore = new CustomStore({
    key: "pl_id_pl",
    loadMode: "raw",
    // load: (loadOptions) => sendRequest(`${HOST_API}/v/personnelslevels`, loadOptions, 'GET', {skip:0, take:99999, requireTotalCount: "false", filter:['["pl_id_ct","=","3"]'], sort: ['[{"selector":"pl_level","asc":true}]'] })
    load: (loadOptions) => {
      let $filterType = (clientType!="all") ? 'filter:[\'["ct_type","=","'+clientType+'"]\']' : "";
      console.log("filterType ==> ",$filterType)
      return sendRequest(`${HOST_API}/v/personnelslevels`, loadOptions, 'GET', {skip:0, take:99999, requireTotalCount: "false", $filterType , sort: ['[{"selector":"pl_level","asc":true}]'] })    
    }
  });

  const TeamsStore = new CustomStore({
    key: "tm_id_tm",
    loadMode: "raw",
    load: (loadOptions) => {
      if(ShowCompany != true)
        return sendRequest(`${HOST_API}/v/teams`, loadOptions, 'GET', {skip:0, take:99999, requireTotalCount: "false", filter:['["tm_id_com","=","'+clientId+'"]'], sort: ['[{"selector":"tm_team","asc":true}]'] });
      return sendRequest(`${HOST_API}/v/teams`, loadOptions, 'GET', {skip:0, take:99999, requireTotalCount: "false", sort: ['[{"selector":"tm_team","asc":true}]'] });
    },
  });
  
  const CompanieStore = new CustomStore({
    key: "com_id_com",
    loadMode: "raw",
    load: (loadOptions) => {
      if(ShowCompany == true)
        return sendRequest(`${HOST_API}/v/companies`, loadOptions, 'GET', {skip:0, take:99999, requireTotalCount: "false", filter:['["com_id_com","=","'+clientId+'"]'], sort: ['[{"selector":"com_name","asc":true}]'] });
      return sendRequest(`${HOST_API}/v/companies`, loadOptions, 'GET', {skip:0, take:99999, requireTotalCount: "false", sort: ['[{"selector":"com_name","asc":true}]'] });
    },
  });

  const ShiftsStore = new CustomStore({
    key: "sh_id_sh",
    loadMode: "raw",
    load: (loadOptions) => {
      if(ShowCompany == true)
        return sendRequest(`${HOST_API}/v/shifts`, loadOptions, 'GET', {skip:0, take:99999, requireTotalCount: "false", filter:['["sh_id_com","=","'+clientId+'"]'], sort: ['[{"selector":"sh_shift","asc":true}]'] });
      return sendRequest(`${HOST_API}/v/shifts`, loadOptions, 'GET', {skip:0, take:99999, requireTotalCount: "false", sort: ['[{"selector":"sh_shift","asc":true}]'] });
    },
  });
  
  const GeoPointStore = new CustomStore({
    key: "gp_id_gp",
    loadMode: "raw",
    //load: (loadOptions) => sendRequest(`${HOST_API}/v/geopoints`, loadOptions, 'GET', {skip:0, take:99999, requireTotalCount: "false", filter:['["gp_id_compv","=","'+clientIdTmp+'"]'], sort: ['[{"selector":"gp_name","asc":true}]'] })
    load: (loadOptions) => sendRequest(`${HOST_API}/v/geopoints`, loadOptions, 'GET', {skip:0, take:99999, requireTotalCount: "false", sort: ['[{"selector":"gp_name","asc":true}]'] })
  });

  const PersonnelsStore = new CustomStore({
    load: (loadOptions) => {
      if(ShowCompany == true)
        return sendRequest(`${HOST_API}/v/personnels`,loadOptions,'GET',{filter: ['["p_id_com","=",'+clientId+']'], sort: ['[{"selector":"p_last_name","asc":true}]']});
      return sendRequest(`${HOST_API}/v/personnels`,loadOptions,'GET',{sort: ['[{"selector":"p_id_com,p_last_name","asc":true}]']});
    },
    key: 'p_id_p',
    // keyUser: 'u_id_u',
    insert: (values) => {
      if(values.u_active !== null && values.u_active !== undefined ){
        // console.log("+active:",values.u_active)
        values.u_active = (values.u_active === true) ? "1" : "0";
        // console.log("+active:",values.u_active)
      }      
      // console.log("+values ==> " , values)
      return sendRequest(`${HOST_API}/personnels`, null, 'POST', {...values,p_id_com:clientId })
    },
    update: function(key, values){
      // console.log("----:",prepareStatusValue,":----")
      if(values.u_active !== null && values.u_active !== undefined ){
        // console.log("-active:",values.u_active)
        values.u_active = (values.u_active === true) ? "1" : "0";
        // console.log("-active:",values.u_active)
      } 
      // console.log("-values ==> " , values)
      // console.log("keyUser ==> " , keyUser)
      // return sendRequest(`${HOST_API}/personnels/update`, null, 'POST', {...values, p_id_p:key})
      let res = sendRequest(`${HOST_API}/personnels/update`, null, 'POST', {...values, p_id_p:key})
      // console.log("res")
      // console.log(res)
      // return "Error";
      return res;
    },
    remove: function(key){
      return sendRequest(`${HOST_API}/personnels`, null, 'DELETE', {key})
    }
  })

  const prepareStatusValue = rowData => {
    // console.log("first_name : ",rowData.p_first_name," , u_username : ",rowData.u_username," , u_active : ",rowData.u_active);
    // document.getElementById(usernameRef.id).disabled=false;
    // document.getElementById(passwordRef.id).disabled=!rowData.value;
    return rowData.u_active === '1' ? true : false;
  };

  const ParentCallBack = (response) => {
    setPPsPopupVisibility(false);
  }

  const ActionPopup = (e) => {
    document.getElementById(usernameRef.id).disabled=!activeRef.value;
    document.getElementById(passwordRef.id).disabled=!activeRef.value;
  }

  const activeEditorOptions = {
    onValueChanged: (e) => {
      document.getElementById(usernameRef.id).disabled=!e.value;
      document.getElementById(passwordRef.id).disabled=!e.value;
      activeRef.setValue(e.value);
    }
  }

  const passwordEditorOptions = {
    onClick: (e) => {
      console.log("******* click *********")
    }, 
    buttons:[
      {
        name: 'generatePassword',
        options:{
          stylingMode:"text",
          type: 'default',
          icon: 'refresh',
          onClick: (e) => {
            // console.log("generatePassword click")
            var password = passwordGenerator.generate({
              length: 11,
              numbers: true
            });
            document.getElementById(passwordRef.id).value = password;
            passwordRef.setValue(password);
          }
        }
      },
      {
        name: 'CopyCredentials',
        options:{
          stylingMode:"text",
          type: 'default',
          icon: 'copy',
          onClick: (e) => {
            // console.log("CopyCredentials click")
            let username = document.getElementById(usernameRef.id).value;
            let password = document.getElementById(passwordRef.id).value;
            console.log(username+" / "+password)
            try {
              navigator.clipboard.writeText(username+" / "+password);
              notify({
                message: t('copied to clipboard!') ,
                width: 450,
              }, 'success', 2000);
            } catch (error) {
              notify({
                message: t('failed to copy to clipboard!') ,
                width: 450,
              }, 'error', 2000);
            }
          }
        }
      }
    ]
  }
  
  const LevelsOptions = (e) => {
    console.log("LevelsOptions click",e)
  }

  const onEditorPrepared = e => {  
    if(e.parentType === "dataRow"){
      if(e.dataField === "u_username") usernameRef = e;
      else if(e.dataField === "password") passwordRef = e;
      else if(e.dataField === "u_active"){
        activeRef = e;
        // console.log("onEditorPrepared");
        // console.log(activeRef);
      } 
    }
  }

  return (
    <React.Fragment>     
      

      <ResponsiveBox
        singleColumnScreen="sm"
        screenByWidth={screen}>
        <Row ratio={1}></Row>
        <Row ratio={2} screen="xs"></Row>
        <Row ratio={2}></Row>
        <Row ratio={1}></Row>

        <Col ratio={1}></Col>
        <Col ratio={1} screen="lg"></Col>
        <Col ratio={1}></Col>
        <Item>
          <Location row={1} col={0} screen="lg" ></Location>
          <Location row={2} col={0} screen="sm" ></Location>
          {
          ShowCompany ? 
            <PersonnelTeams  clientId={clientId} />
          : <h2 className={'content-block'}>{t('personnels')}</h2>  
          }
        </Item>
        <Item>
          <Location row={1} col={1}             screen="lg" ></Location>
          <Location row={1} col={0} colspan={2} screen="sm" ></Location>           
          {
          ShowCompany ? 
            <PersonnelShifts clientId={clientId} />
          : null
          }
        </Item>
        <Item>
          <Location row={1}  col={2}  screen="lg" ></Location>
          <Location row={2}  col={1}  screen="sm" ></Location>
          <PersonnelUploader clientId={clientId} clientData={clientData} />
        </Item>
        <Item>
          <Location row={2} col={0} colspan={3} screen="lg" ></Location>
          <Location row={3} col={0} colspan={2} screen="sm" ></Location>
          <DataGrid
            className={'dx-card wide-card'}
            keyExpr="p_id_p"
            dataSource={PersonnelsStore}
            showBorders={true}
            remoteOperations={false}
            focusedRowEnabled={true}
            defaultFocusedRowIndex={0}
            allowColumnResizing={true}
            // columnResizingMode={"widget"}
            // columnAutoWidth={true}
            columnHidingEnabled={true}
            allowColumnReordering={true}
            cacheEnabled={false}
            //onSaving={onSaving}
            onEditorPrepared={onEditorPrepared}
          >
            <Editing
              mode="popup"
              refreshMode="reshape"
              allowAdding={true}
              allowUpdating={true}
              allowDeleting={true}
              onInitNewRow={true}
              >
              <Popup title={t('Personnel info')} showTitle={true} shading={true} resizeEnabled={true} dragEnabled={true}  onShowing={ActionPopup} />
              <Form>
                <GroupItem colCount={3} colSpan={2}>
                  <SimpleItem dataField="p_first_name" caption="" validationRules />
                  <SimpleItem dataField="p_last_name" caption="" validationRules />
                  <SimpleItem dataField="p_phone" caption=""  />
                </GroupItem>
                <GroupItem colCount={3} colSpan={2}>
                  <SimpleItem dataField="p_id_pl" /> 
                  <SimpleItem dataField="p_id_sh" />
                  <SimpleItem dataField="p_id_tm" />              
                </GroupItem>
                <GroupItem colCount={3} colSpan={2}>
                  <SimpleItem dataField="p_id_gp" />
                  <SimpleItem dataField="p_district" caption=""  />
                  <SimpleItem dataField="p_city" caption=""  />
                  <SimpleItem dataField="p_address" caption=""  />
                </GroupItem> 
                <GroupItem colCount={3} colSpan={2} caption={t('credentials')}>
                  <SimpleItem dataField="u_username" caption="username"/>
                  <SimpleItem dataField="password" editorOptions={passwordEditorOptions}>
                  </SimpleItem>
                  {/* <SimpleItem>
                    <Label text={t('password')}></Label>
                    <TextBox ref={passwordRef} dataField="password" placeholder="password" >
                      <TextBoxButton name="password" location="after" options={GeneratePassword} />
                      <TextBoxButton name="username" location="after" options={CopyCredentials} />
                    </TextBox>
                  </SimpleItem> */}
                  <SimpleItem dataField="u_active" editorType="dxCheckBox" editorOptions={activeEditorOptions} />
                </GroupItem>
              </Form>
            </Editing>
            <Paging defaultPageSize={10} />
            <Pager
              showPageSizeSelector={true}
              showInfo={true}
              allowedPageSizes={[10, 25, 50, 100, 500, 1000]}/>
            <FilterRow visible={true} />
            <Column dataField={'p_id_com'}          caption={t('company name')}   visible={!ShowCompany}>
              <Lookup  dataSource={CompanieStore}   displayExpr="com_name"      valueExpr="com_id_com"   />
            </Column> 
            <Column dataField={'p_first_name'}		  caption={t('first name')}	 />
            <Column dataField={'p_last_name'}		    caption={t('last name')}   />
            <Column dataField={'u_username'}		    caption={t('login')} 	hidingPriority={1}	   />
            <Column dataField={'p_phone'}		    	  caption={t('phone')}      hidingPriority={9}     />
            <Column dataField={'u_active'}          caption={t('auth status')}     visible={true}  hidingPriority={6} encodeHtml={false} calculateCellValue={prepareStatusValue} /> 
            <Column dataField={'password'}		    	caption={t('password')}   visible={false}     />
            <Column dataField={'u_id_u'}		    	  caption={t('u_id_u')}     visible={false}     />
            <Column dataField={'p_id_pl'}           caption={t('level')} hidingPriority={22}>
              <Lookup  dataSource={PersonnelsLevelsStore}     displayExpr="pl_level"      valueExpr="pl_id_pl"   />
            </Column> 
            <Column dataField={'p_id_tm'}           caption={t('team')}  hidingPriority={5} >
              <Lookup  dataSource={TeamsStore}      displayExpr="tm_team"      valueExpr="tm_id_tm"  />
            </Column>
            <Column dataField={'p_id_sh'}           caption={t('shift_short')}  hidingPriority={7}>
              <Lookup  dataSource={ShiftsStore}     displayExpr="sh_shift"     valueExpr="sh_id_sh"  />
            </Column>
            <Column dataField={'p_id_gp'} caption={t('pick-up point')} /* editCellComponent={}*/ hidingPriority={8} >
              <Lookup dataSource={GeoPointStore} displayExpr="gp_name" valueExpr="gp_id_gp"  /> 
            </Column>
            <Column dataField={'p_district'}  caption={t('district')} hidingPriority={4}  />
            <Column dataField={'p_city'}  caption={t('city')} hidingPriority={1}  />
            <Column dataField={'p_address'}   caption={t('address')}  hidingPriority={3}  />
          </DataGrid>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
}
export default withTranslation()(ClientPersonnel);