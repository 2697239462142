import React from 'react';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import Clock from 'react-live-clock';
import Button from 'devextreme-react/button';
import UserPanel from '../user-panel/user-panel';
import './header.scss';
import { Template } from 'devextreme-react/core/template';
import LanguageSelector from '../language-selector/language-selector'
import SwitchCustomize from "../switch-themes/SwitchSwitchCustomize";

export default function Header({ menuToggleEnabled, title, toggleMenu }) {
  return (
    <header className={'header-component'}>
      <Toolbar className={'header-toolbar'}>
        <Item
          visible={menuToggleEnabled}
          location={'before'}
          widget={'dxButton'}
          cssClass={'menu-button'}
        >
          <Button icon="menu" stylingMode="text" onClick={toggleMenu} />
        </Item>
        <Item
          location={'before'}
          cssClass={'header-title'}
          text={title}
          visible={!!title}
        />
        <Item
          locateInMenu={'never'} 
          location={'after'}
        >
         <Clock format="HH:mm:ss" interval={1000} ticking={true} />
        </Item>
        <Item
          location={'after'}
          locateInMenu={'auto'}
          menuItemTemplate={'userPanelTemplate'}
        >
          <Button
            className={'user-button authorization'}
            width={210}
            height={'100%'}
            stylingMode={'text'}
          >
            <UserPanel menuMode={'context'} />
          </Button>
        </Item>
        <Item
          locateInMenu={'auto'} 
          location={'after'}
        >
         <SwitchCustomize/>
        </Item>
        <Item
          locateInMenu={'never'} 
          location={'after'}
        >
         <LanguageSelector/>
        </Item>
        

        <Template name={'userPanelTemplate'}>
          <UserPanel menuMode={'list'} />
        </Template>

        
      </Toolbar>
    </header>
)}
