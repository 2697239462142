// import defaultUser from '../utils/default-user';
import { HOST_API } from "../env";






export async function changePassword(password, new_password) {
  /*
    return {
    isOk: true,
    message: "Password changed successfully"
  };;*/
  try {
    let token = localStorage.getItem("token");
    if(!token){
      return {
        isOk: false,
        message: "Failed to check your session"
      };
    }
    // Send request
    const response = await fetch(`${HOST_API}/changepassword`,{
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
    body: JSON.stringify({
      password: password,
      new_password: new_password
    })
    });
    const res = await response.json()
    if (res.code === 200 && res.success === true) {
      return {
        isOk: true,
        message: res.message
      };
    }else{
      return {
        isOk: false,
        message: res.message
      }
    }
   
  }
  catch {
    return {
      isOk: false,
      message: "Failed to change password"
    }
  }
}



export async function signIn(username, password) {
  try {
    // Send request
    const response = await fetch(`${HOST_API}/login`,{
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        u_username: username,
        password:password
      })
    });
    const res = await response.json()
    if (res.code === 200 && res.success === true) {
      localStorage.setItem("token", res.data.token);
      localStorage.setItem("level", res.data.level);
      localStorage.setItem("firstname", res.data.firstname);
      localStorage.setItem("lastname", res.data.lastname);
      return {
        isOk: true,
        data: {
          token: res.data.token,
          level: res.data.level,
          firstname: res.data.firstname,
          lastname: res.data.lastname,
          email: 'sandra@example.com',
          avatarUrl: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/employees/06.png'
        }
      };
    }else{
      return {
        isOk: false,
        message: res.message
      };
    }
  }
  catch(e) {
    return {
      isOk: false,
      message: "Authentication failed"
    };
  }
}

export async function getUser() {
  try {
    let token = localStorage.getItem("token");
    if(!token){
      return {
        isOk: false,
        message: "Failed to check your session"
      };
    }
    // Send request
    //console.log("eeeeeee",token)
    const response = await fetch(`${HOST_API}/checking`,{
      method: "GET",
      headers: {
        'Content-Type' : 'application/json',
        'Accept' : 'application/json',
        "Authorization": `Bearer ${token}`
      }
    });
    console.log("response",response)
    const res = await response.json()
    if (res.code === 200){
      if(res.success === true) {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("level", res.data.level);
        localStorage.setItem("firstname", res.data.firstname);
        localStorage.setItem("lastname", res.data.lastname);
        return {
          isOk: true,
          data: {
            token: res.data.token,
            level: res.data.level,
            firstname: res.data.firstname,
            lastname: res.data.lastname,
            email: 'sandra@example.com',
            avatarUrl: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/employees/06.png'
          }
        };
      }else{
        return {
          isOk: false,
          message: res.message
        };
      }
    }else{
      return {
        isOk: false,
        message: "Failed to check your session"
      };
    }
  }catch {
    return {
      isOk: false,
      message: "Failed to check your session"
    };
  }
}

export function logOut() {
  localStorage.removeItem("token");
  localStorage.removeItem("level");
  localStorage.removeItem("firstname");
  localStorage.removeItem("lastname");
}

export async function createAccount(email, password) {
  try {
    // Send request
    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to create account"
    };
  }
}


export async function resetPassword(email) {
  try {
    // Send request
    console.log(email);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to reset password"
    };
  }
}
