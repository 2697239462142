import React/*, { useState }*/ from 'react';
import { withTranslation } from 'react-i18next';
import DataSource from 'devextreme/data/data_source';
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  Lookup,
  Form,
  Editing,
  Popup,
  RequiredRule,
  // PatternRule,
  // Label,
   Item
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
// import DataSource from 'devextreme/data/data_source';
// import 'devextreme/data/odata/store';
import { GroupItem, SimpleItem } from 'devextreme-react/form';
import 'devextreme-react/text-area';
import { HOST_API } from "../../env";
import $ from "jquery";

import {sendRequest} from '../../utils/utils';


const VehiclesStore = new DataSource({
    key: 'v_id_v',
    load(_) {
      let token  = localStorage.getItem("token");
      return fetch(`${HOST_API}/v/vehicles`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token
        }
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("data:::", data)
  
          return {
            data: data.data
          }
        })
        .catch(() => { throw new Error('Data Loading Error'); });
    },
  });


  const CompaniesStore = new DataSource({
    key: 'com_id_com',
    load(_) {
      let token  = localStorage.getItem("token");
      return fetch(`${HOST_API}/v/companies`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token
        }
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("data:::", data)
  
          return {
            data: data.data
          }
        })
        .catch(() => { throw new Error('Data Loading Error'); });
    },
  });



function GpsDevice(props) {
  const {t, clientId /*,clientData*/} = props;
  const allowedPageSizes = [8, 12, 20];
  
  const dxDateBoxOptions = {
    displayFormat: "dd/MM/yyyy",
    dateSerializationFormat: "yyyy-MM-dd"
  };

  const prepareStatusValue = rowData => {
    return rowData.v_status === 1 ? true : false;
  };



  const gpsDeviceStore = new CustomStore({
    load: (loadOptions) => {
        //loadOptions  = {...loadOptions,filter:['["gpd_id_gpl","=","'+clientId+'"]'], sort: ['[{"selector":"v_label","asc":true}]'] }
        //console.log("gpsPlatformStore::loadOptions",loadOptions)
        return sendRequest(`${HOST_API}/v/gpsdevice`, loadOptions, 'GET', {skip:0, take:99999, requireTotalCount: "false", filter:['["gpd_id_gpl","=","'+clientId+'"]'], sort: ['[{"selector":"v_label","asc":true}]'] })
    },
    key: 'gpd_id_gpd',
    insert: (values) => {
        let vIDv = 0;
        $(".v_id_v_clss :input").each(function(e){	
            VehiclesStore.items().map( itemT => {
                if (itemT.v_label === this.value) {
                vIDv = itemT.v_id_v
                }
            })
        });
        let CIDC = 0;
        $(".com_id_com_clss :input").each(function(e){	
            CompaniesStore.items().map( itemT => {
                if (itemT.com_name === this.value) {
                  CIDC = itemT.com_id_com
                }
            })
        });
      return sendRequest(`${HOST_API}/gpsdevice`, null, 'POST', {...values, gpd_id_v: vIDv, gpd_id_gpl: clientId, gpd_id_compv :CIDC })
    },
    update: function(key, values){
        let vIDv = 0;
        $(".v_id_v_clss :input").each(function(e){	
            VehiclesStore.items().map( itemT => {
                if (itemT.v_label === this.value) {
                vIDv = itemT.v_id_v
                }
            })
        });
        let CIDC = 0;
        $(".com_id_com_clss :input").each(function(e){	
            CompaniesStore.items().map( itemT => {
                  if (itemT.com_name === this.value) {
                    CIDC = itemT.com_id_com
                  }
              })
        });
      let res = sendRequest(`${HOST_API}/gpsdevice/update`, null, 'POST', {...values, gpd_id_gpd: key, gpd_id_v: vIDv, gpd_id_gpl: clientId, gpd_id_compv :CIDC})
      return res;
    },
    remove: function(key){
      return sendRequest(`${HOST_API}/gpsdevice`, null, 'DELETE', {key})
    }
  })

  const CStore = new CustomStore({
    key: "com_id_com",
    loadMode: "raw",
    load: (loadOptions) => sendRequest(`${HOST_API}/v/companies`, loadOptions, 'GET', {skip:0, take:99999, requireTotalCount: "false", filter:['["com_id_com","=","'+clientId+'"]'], sort: ['[{"selector":"com_name","asc":true}]'] })
});

const setCompanyValue = (rowData, value) =>{
    rowData.v_id_pdrv = null;
    //defaultSetCellValue(rowData, value);
  }
  
  let ShowCompanies = true;
	if(!isNaN(clientId)){
		ShowCompanies = false;
	}


const VStore = new CustomStore({
    key: "v_id_v",
    loadMode: "raw",
    load: (loadOptions) => sendRequest(`${HOST_API}/v/vehicles`, loadOptions, 'GET', {skip:0, take:99999, requireTotalCount: "false" })
});

  const setVehicleValue = (rowData, value) =>{
    rowData.gpd_id_v = null;
    //defaultSetCellValue(rowData, value);
  }


  console.log("VehiclesStore:::",VehiclesStore)
    
  let ShowVehicles = true;
	if(!isNaN(clientId)){
		ShowVehicles = false;
	}

  
//#####################################
const GStore = new CustomStore({
        key: "gpl_id_gpl",
        loadMode: "raw",
        load: (loadOptions) => sendRequest(`${HOST_API}/v/gpsplatform`, loadOptions, 'GET', {skip:0, take:99999, requireTotalCount: "false" })
    });

  const setGPSPlatformsValue = (rowData, value) =>{
    rowData.gpd_id_gpl = null;
    //defaultSetCellValue(rowData, value);
  }
  let ShowGPSPlatforms = true;
  if(!isNaN(clientId)){
      ShowGPSPlatforms = false;
  }


  return (
    <React.Fragment>
    {/* <h2 className={'content-block'}>{t('vehicles')}</h2> */}

    <DataGrid
      className={'dx-card wide-card'}
      keyExpr="v_id_v"
      dataSource={gpsDeviceStore}
      cacheEnabled={false}
      showBorders={true}
      remoteOperations={true}
      focusedRowEnabled={true}
      defaultFocusedRowIndex={0}
      allowColumnResizing={true}
      // columnResizingMode={"widget"}
      // columnAutoWidth={true}
      columnHidingEnabled={true}
      allowColumnReordering={true}
    >
      <Editing
        mode="popup"
        refreshMode="reshape"
        allowAdding={true}
        allowUpdating={true}
        allowDeleting={true}>
        <Popup title={t('gpsdecvice_info')} showTitle={true} shading={true} resizeEnabled={true} dragEnabled={true}  />
        <Form>
          <GroupItem colCount={3} colSpan={2}>
            <SimpleItem dataField="v_label" caption="" validationRules editorOptions={{ disabled: true }}  />
          </GroupItem>
          <GroupItem colCount={3} colSpan={2}>
            <SimpleItem horizontalAlignment="left" dataField="gpd_status" editorType="dxCheckBox">
            </SimpleItem>
            <Item   cssClass="com_id_com_clss" editorType="dxSelectBox" editorOptions={{dataSource: CompaniesStore,valueExpr:'com_id_com',displayExpr:'com_name' } } >
                <RequiredRule message={t('please_choose_a_Gpsplatform')} />
            </Item>
            <Item   cssClass="v_id_v_clss" editorType="dxSelectBox" editorOptions={{dataSource: VehiclesStore,valueExpr:'v_id_v',displayExpr:'v_label' } } >
                <RequiredRule message={t('please_choose_a_vihcule')} />
            </Item>
          </GroupItem>
          <GroupItem caption={t('manufacture_info')} colCount={3} colSpan={2}>
            <SimpleItem dataField="gpd_device_uid" />
            <SimpleItem dataField="gpd_model" />
          </GroupItem>
          <GroupItem caption={t('administration_info')} colCount={2} colSpan={2}>
            <SimpleItem dataField="gpd_owner" />
            <SimpleItem dataField="gpd_phone" />
            <SimpleItem dataField="gpd_email" />
          </GroupItem>
          <GroupItem caption={t('additional_info')} colCount={2} colSpan={2}>
            <SimpleItem dataField="gpd_created_by" caption={t('Created by')} editorOptions={{ disabled: true }} />
            <SimpleItem dataField="gpd_created_at" caption={t('Created at')} editorOptions={{ disabled: true }} />
            <SimpleItem dataField="gpd_updated_by" caption={t('Updated by')} editorOptions={{ disabled: true }} />
            <SimpleItem dataField="gpd_updated_at" caption={t('Updated at')} editorOptions={{ disabled: true }} />
            <SimpleItem dataField="gpd_device_uid" />
            <SimpleItem dataField="gpd_device_type" />
            <SimpleItem dataField="gpd_server_address" />
          </GroupItem>
        </Form>
      </Editing>
      <Paging defaultPageSize={10} />
      <Pager showPageSizeSelector={true} showInfo={true} />
      <FilterRow visible={true} />

      <Column
        dataField={'v_label'}
        caption={t('label')}
      />

      <Column
        dataField={'v_brand'}
        caption={t('brand')}
        hidingPriority={10}
      />
      <Column
        visible={ShowVehicles} 
        dataField={'gpd_id_v'}
        caption={t('vehicle')}
        // cellTemplate={cellTemplate}
        setCellValue={setVehicleValue}
      >
        <Lookup
          dataSource={VStore}
          displayExpr="v_label"
          valueExpr="v_id_v"
        />
      </Column>
      <Column
        dataField={'gpd_model'}
        caption={t('model')}
        hidingPriority={9}
      >
      </Column>
      

      <Column
        visible={ShowCompanies} 
        dataField={'gpd_id_compv'}
        caption={t('company')}
        // cellTemplate={cellTemplate}
        setCellValue={setCompanyValue}
      >
          <Lookup
          dataSource={CStore}
          displayExpr="com_name"
          valueExpr="com_id_com"
        />
      </Column>

      <Column
        visible={ShowGPSPlatforms} 
        dataField={'gpd_id_gpl'}
        caption={t('gpsplatforms')}
        // cellTemplate={cellTemplate}
        setCellValue={setGPSPlatformsValue}
      >
          <Lookup
          dataSource={GStore}
          displayExpr="gpl_name"
          valueExpr="gpl_id_gpl"
        />
      </Column>



      <Column
        dataField={'gpd_device_uid'}
        caption={t('device_uid')}
        hidingPriority={2}
      >
      </Column>
      <Column
        dataField={'gpd_owner'}
        caption={t('owner')}
        hidingPriority={6}
      />
      <Column
        dataField={'gpd_phone'}
        caption={t('phone')}
        hidingPriority={4}
      />
      <Column
        dataField={'gpd_email'}
        caption={t('email')}
        hidingPriority={1}
      />
      <Column
        dataField={'gpd_status'}
        caption={t('status')}
        encodeHtml={false}
        calculateCellValue={prepareStatusValue}
      >
      </Column>
      <Column
        dataField={'gpd_created_by'}
        caption={t('Created by')}
        visible={false}
      >
      </Column>
      <Column
        dataField={'gpd_created_at'}
        caption={t('Created at')}
        visible={false}
      >
      </Column>
      <Column
        dataField={'gpd_updated_by'}
        caption={t('Updated by')}
        visible={false}
      >
      </Column>
      <Column
        dataField={'gpd_updated_at'}
        caption={t('Updated at')}
        visible={false}
      >
      </Column>

      <Paging defaultPageSize={8} />
      <Pager
        showPageSizeSelector={true}
        allowedPageSizes={allowedPageSizes}
      />
    </DataGrid>
  </React.Fragment>
  );
}

export default withTranslation()(GpsDevice);