import React /*, { useState, useRef }*/ from 'react';
import { withTranslation } from 'react-i18next';
// import {ErrorBoundary} from 'react-error-boundary'
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  Popup,
  // Lookup,
  Form,
  Editing,
  RequiredRule,
  PatternRule,
  MasterDetail
} from 'devextreme-react/data-grid';
// Popup,
// import { Popup, Position } from 'devextreme-react/popup';
import { TabPanel, Item } from 'devextreme-react/tab-panel';
// import { formatDate } from 'devextreme/localization';

import 'devextreme/data/odata/store';
import CustomStore from 'devextreme/data/custom_store';
// import DataSource from 'devextreme/data/data_source';

import {/*logRequest, isNotEmpty,*/ sendRequest} from '../../utils/utils';
import { HOST_API } from "../../env";
 
// import $ from "jquery";

// import clientDetailView from './clientDetailView';

import ClientInfo from '../../components/infos/info';
// import ClientInfo from './clientInfo';
import ClientPickupPoints from './clientPickupPoints';
// import ClientPersonnel from './clientPersonnel';
import ClientPersonnel from '../../components/personnels/personnels';
import ClientTrips from './clientTrips';
// import ClientSites from './clientSites';
// import Uplode from './uplode'; 
// import ClientTeam from './clientTeam';
// import ClientContract from './clientContract';
// import Site from './sites';
 
function Clients({t}) {
  const ClientsStore  = new CustomStore({
    key: 'com_id_com',
    load: (loadOptions) => sendRequest(`${HOST_API}/v/companies`, loadOptions, 'GET', {filter:['["ct_type","=","client"]'] }),
    insert: (values) => {
      console.log(values)
      if(values.com_status !== null && values.com_status !== undefined ){
        values.com_status = (values.com_status === true) ? 1 : 0;
      } 
      if(values.com_taxable !== null && values.com_taxable !== undefined ){
        values.com_taxable = (values.com_taxable === true) ? 1 : 0;
      }  
      return sendRequest(`${HOST_API}/companies`,null, 'POST', {...values, com_id_ct: 3})
    },
    update: function(key, values){
      if(values.com_status !== null && values.com_status !== undefined ){
        values.com_status = (values.com_status === true) ? 1 : 0;
      } 
      if(values.com_taxable !== null && values.com_taxable !== undefined ){
        values.com_taxable = (values.com_taxable === true) ? 1 : 0;
      } 
      return  sendRequest(`${HOST_API}/companies/update`, null, 'POST', {...values, com_id_com: key })
    },
    remove: (key) => sendRequest(`${HOST_API}/companies`,null, 'DELETE', {
      key,
    }),
  })
  
  const clientDetails = (e) => {
    return (
      <TabPanel>
        <Item title={t('Info')} icon="fa-solid fa-address-card">
          <ClientInfo clientId={e.data.key}></ClientInfo>
        </Item>
        <Item title={t('personnel')} icon="group">
          <ClientPersonnel clientId={e.data.key} clientData={e.data} clientType="client"/>
        </Item>
        <Item title={t('pick-up points')} icon="fa-solid fa-location-dot">
          <ClientPickupPoints clientId={e.data.key} clientData={e.data}></ClientPickupPoints>
        </Item>
        <Item title={t('trips')} icon="fa-solid fa-route">
          <ClientTrips clientId={e.data.key}></ClientTrips>
        </Item>
        {/* <Item title="Uplode" >
          <Uplode clientId={e.data.key}></Uplode>
        </Item> */}
      </TabPanel>
    );
  }
  
  const prepareTaxableValue = rowData => {
    return rowData.com_taxable === 1 ? true : false;
  };
  const prepareStatusValue = rowData => {
    return rowData.com_status === 1 ? true : false;
  };

  return (
    <React.Fragment>
      <h2 className={'content-block'}>{t('clients')}</h2>
      <DataGrid
        className={'dx-card wide-card'}
        keyExpr="com_id_com"
        dataSource={ClientsStore}
        cacheEnabled={false}
        showBorders={true}
        remoteOperations={true}
        focusedRowEnabled={true}
        // defaultFocusedRowIndex={0}
        rowAlternationEnabled={true}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        allowColumnReordering={true}
        allowColumnResizing={true}

        onRowExpanding={function(e){
          e.component.collapseAll(-1);
        }}
        onRowDblClick={function(e){
          // e.component.collapseAll(-1);
          // e.component.expandRow(e.key);
        }}
        >
        <MasterDetail
          enabled={true}
          component={clientDetails}
        />
        <Editing
          mode="popup"
          allowAdding={true}
          allowUpdating={true}
          allowDeleting={true}>
          <Popup title={t('info')} showTitle={true} shading={true} resizeEnabled={true} dragEnabled={true}  />
          <Form>
            <Item itemType="group" colCount={2} colSpan={2}>
              <Item dataField="com_name" caption="" validationRules />
              <Item dataField="com_ICE" />
              <Item dataField="com_activity" />
              <Item dataField="com_responsible" />
              <Item dataField="com_phone" />
              <Item dataField="com_email" />
              <Item dataField="com_note" />
              <Item dataField="com_status" editorType="dxCheckBox" verticalAlignment={false} />
            </Item>
            
            <Item itemType="group" caption="Tax Options" colCount={2} colSpan={2}>
              <Item dataField="com_taxable" editorType="dxCheckBox" verticalAlignment={false} />
              <Item dataField="com_tax_rate" />
            </Item>

            <Item itemType="group" caption={t('company_address')} colCount={2} colSpan={2}>
              <Item dataField="com_district" />
              <Item dataField="com_city" />
              <Item dataField="com_address" />
            </Item>
          </Form>
        </Editing>
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        <FilterRow visible={true} />
        <Column
          dataField={'com_name'}
          width={190}
          caption={t('company name')}
          sortIndex={0}
          sortOrder="asc"
        >
          <RequiredRule />
          <PatternRule pattern={/^\s*(?:\S\s*){3,100}$/} message={t('please_Enter_a_valid_company_ame')} />
        </Column>
        <Column
          dataField={'com_ICE'}
          caption={t('ice')}
          hidingPriority={0}
        />
        <Column
          dataField={'com_activity'}
          caption={t('activity')}
          hidingPriority={8}
        />
        <Column
          dataField={'com_responsible'}
          caption={t('responsible')}
          allowSorting={false}
          hidingPriority={10}
        />
        <Column
          dataField={'com_phone'}
          caption={t('phone')}
          hidingPriority={7}
          rtlEnabled={true}
        />
        <Column
          dataField={'com_email'}
          caption={t('email')}
          hidingPriority={9}
        />
        <Column
          dataField={'com_address'}
          caption={t('address')}
          hidingPriority={6}
        />
        <Column
          dataField={'com_district'}
          caption={t('district')}
          hidingPriority={5}
        />
        <Column
          dataField={'com_city'}
          caption={t('city')}
          hidingPriority={4}
        />
        <Column
          dataField={'com_taxable'}
          caption={t('taxable')}
          hidingPriority={3}
          calculateCellValue={prepareTaxableValue}
        />
        <Column
          dataField={'com_tax_rate'}
          caption={t('tax rate')}
          hidingPriority={2}
        />
        <Column 
          dataField={'com_status'}
          caption={t('status')}     
          visible={true}  
          hidingPriority={10} 
          encodeHtml={false} 
          calculateCellValue={prepareStatusValue} 
        /> 
        <Column
          dataField={'com_note'}
          caption={t('note')}
          hidingPriority={1}
        />
        <Paging defaultPageSize={8} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={10,20,50,100}
        />
      </DataGrid>
    </React.Fragment>
)}

export default withTranslation()(Clients);

