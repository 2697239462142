import React from 'react';
import { withTranslation } from 'react-i18next';
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  Lookup,
  Form,
  Editing,
  Popup,
  RequiredRule,
  PatternRule,
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
// import DataSource from 'devextreme/data/data_source';
// import 'devextreme/data/odata/store';
import { GroupItem, SimpleItem } from 'devextreme-react/form';
import 'devextreme-react/text-area';
import { HOST_API } from "../../env";
// import $ from "jquery";

import {sendRequest} from '../../utils/utils';

const allowedPageSizes = [8, 12, 20];
const dxDateBoxOptions = {
  displayFormat: "dd/MM/yyyy",
  dateSerializationFormat: "yyyy-MM-dd"
};

const prepareStatusValue = rowData => {
  return rowData.v_status === 1 ? true : false;
};

const FuelTypeStore = ["diesel","gasoline"]

const VehiclesStore = new CustomStore({
  load: (loadOptions) => sendRequest(`${HOST_API}/v/vehicles`, loadOptions, 'GET', null),
  key: 'v_id_v',
  insert: (values) => {
    return sendRequest(`${HOST_API}/vehicles`, null, 'POST', {...values })
  },
  update: function(key, values){
    // return sendRequest(`${HOST_API}/vehicles/update`, null, 'POST', {...values, v_id_v:key})
    let res = sendRequest(`${HOST_API}/vehicles/update`, null, 'POST', {...values, v_id_v:key})
    // console.log("res")
    // console.log(res)
    // return "Error";
    return res;
  },
  remove: function(key){
    return sendRequest(`${HOST_API}/vehicles`, null, 'DELETE', {key})
  }
})

const CompaniesStore = new CustomStore({
    key: "com_id_com",
    loadMode: "raw",
    load: (loadOptions) => sendRequest(`${HOST_API}/v/companies`, loadOptions, 'GET', {skip:0, take:99999, requireTotalCount: "false", filter:['["ct_type","!=","client"]'], sort: ['[{"selector":"com_name","asc":true}]'] })
});

class Vehicles extends React.Component {
  constructor(props) {
    super(props);
    // this.getFilteredDrivers = this.getFilteredDrivers.bind(this);
  }

  getFilteredDrivers(options) {
    let driverFilter = options.data ? '["p_id_com","=","'+options.data.v_id_com+'"]' : null;
    return {
      store: new CustomStore({
        key: "v_id_pdrv",
        loadMode: "raw",
        load: (options) => sendRequest(`${HOST_API}/v/drivers`, options, 'GET', {skip:0, take:99999, requireTotalCount: "false", filter:[driverFilter], sort: ['[{"selector":"p_first_name","asc":true}]'] })
      }),
      filter: options.data ? ['p_id_com', '=', options.data.v_id_com] : null,
    };
  }

  /*
  cellTemplate(container, options) {
    // const text = (options.value || []).map((element) => options.column.lookup.calculateCellValue(element)).join(', ');
    const text = options.column.lookup.calculateCellValue(options.value);
    container.textContent = text;
    container.title = text;
  }
  */

  setCompanyValue(rowData, value) {
    rowData.v_id_pdrv = null;
    this.defaultSetCellValue(rowData, value);
  }
  
  render() {
    const {t} = this.props;
    
    return (
      <React.Fragment>
      <h2 className={'content-block'}>{t('vehicles')}</h2>

      <DataGrid
        className={'dx-card wide-card'}
        keyExpr="v_id_v"
        dataSource={VehiclesStore}
        cacheEnabled={false}
        showBorders={true}
        remoteOperations={true}
        focusedRowEnabled={true}
        defaultFocusedRowIndex={0}
        allowColumnResizing={true}
        // columnResizingMode={"widget"}
        // columnAutoWidth={true}
        columnHidingEnabled={true}
        allowColumnReordering={true}
      >
        <Editing
          mode="popup"
          refreshMode="reshape"
          allowAdding={true}
          allowUpdating={true}
          allowDeleting={true}>
          <Popup title={t('vehicle_info')} showTitle={true} shading={true} resizeEnabled={true} dragEnabled={true}  />
          <Form>
            <GroupItem colCount={3} colSpan={2}>
              <SimpleItem dataField="v_label" caption="" validationRules />
              <SimpleItem dataField="v_registration_plate" editorOptions={{ placeholder: '98765-B-40'/*, labelMode:'floating'*/}} />
              <SimpleItem dataField="v_circulation_date" editorType="dxDateBox" editorOptions={dxDateBoxOptions} />
            </GroupItem>
            <GroupItem colCount={3} colSpan={2}>
              <SimpleItem dataField="v_status" editorType="dxCheckBox"/>
              <SimpleItem dataField="v_id_com"/>
              <SimpleItem dataField="v_id_pdrv"/>
            </GroupItem>
            <GroupItem caption={t('manufacture_info')} colCount={3} colSpan={2}>
              <SimpleItem dataField="v_brand" />
              <SimpleItem dataField="v_model" />
              <SimpleItem dataField="v_vin" />
              <SimpleItem dataField="v_fuel_type"/>
              <SimpleItem dataField="v_tax_horsepower" editorType="dxNumberBox" editorOptions={{format:"#0.## CV", min:1, max:5000, showSpinButtons:true}}/>
              <SimpleItem dataField="v_capacity" />
              <SimpleItem dataField="v_max_capacity" />
            </GroupItem>
            <GroupItem caption={t('administration_info')} colCount={2} colSpan={2}>
              <SimpleItem dataField="v_owner" />
              <SimpleItem dataField="v_responsible" />
              <SimpleItem dataField="v_phone" />
              <SimpleItem dataField="v_email" />
            </GroupItem>
            <GroupItem caption={t('additional_info')} colCount={2} colSpan={2}>
              <SimpleItem dataField="v_created_by" caption={t('Created by')} editorOptions={{ disabled: true }} />
              <SimpleItem dataField="v_created_at" caption={t('Created at')} editorOptions={{ disabled: true }} />
              <SimpleItem dataField="v_updated_by" caption={t('Updated by')} editorOptions={{ disabled: true }} />
              <SimpleItem dataField="v_updated_at" caption={t('Updated at')} editorOptions={{ disabled: true }} />
              <SimpleItem dataField="v_note" editorType="dxTextArea" colSpan={2}/>
            </GroupItem>
          </Form>
        </Editing>
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        <FilterRow visible={true} />

        <Column
          dataField={'v_label'}
          caption={t('label')}
        />
        <Column
          dataField={'v_registration_plate'}
          width={120}
          caption={t('reg. plate')}
          >
          <RequiredRule />
          <PatternRule pattern={/^[0-9]{1,5}-[A-Z]{1,2}-[0-9]{1,2}$/} message={t('please_Enter_a_valid_registration_plate')}/>
        </Column>
        <Column
          dataField={'v_brand'}
          caption={t('brand')}
          hidingPriority={10}
        />
        <Column
          dataField={'v_model'}
          caption={t('model')}
          hidingPriority={9}
        >
        </Column>
        <Column
          dataField={'v_fuel_type'}
          caption={t('fuel type')}
          hidingPriority={1}
        >
          <Lookup
            dataSource={FuelTypeStore} 
            defaultValue={FuelTypeStore[0]}
            searchEnabled={false}
          />
        </Column>
        <Column
          dataField={'v_vin'}
          caption={t('vin')}
          hidingPriority={2}
        >
        </Column>
        <Column
          dataField={'v_tax_horsepower'}
          caption={t('tax horsepower')}
          hidingPriority={2}
          cellTemplate={function(container, options) {
            container.textContent = container.title = options.value+" CV";
          }}
        >
        </Column>
        <Column
          dataField={'v_capacity'}
          caption={t('capacity')}
          hidingPriority={8}
        >
        </Column>
        <Column
          dataField={'v_max_capacity'}
          caption={t('max capacity')}
          hidingPriority={7}
        >
        </Column>
        <Column
          dataField={'v_circulation_date'}
          caption={t('circulation date')}
          hidingPriority={11}
        >
        </Column>
        <Column
          dataField={'v_id_com'}
          caption={t('company')}
          // cellTemplate={this.cellTemplate}
          setCellValue={this.setCompanyValue}
        >
          <Lookup
            dataSource={CompaniesStore}
            displayExpr="com_name"
            valueExpr="com_id_com"
          />
        </Column>
        <Column
          dataField={'v_id_pdrv'}
          caption={t('driver')}
          hidingPriority={20}
        >
          <Lookup
            // dataSource={DriversStore}
            // displayExpr="p_first_name"
            dataSource={this.getFilteredDrivers}
            displayExpr={function(item) {
              return item && item.p_first_name + ' ' + item.p_last_name;
            }}
            valueExpr="p_id_p"
          />
        </Column>
        <Column
          dataField={'v_owner'}
          caption={t('owner')}
          hidingPriority={6}
        />
        <Column
          dataField={'v_responsible'}
          caption={t('responsible')}
          hidingPriority={5}
        />
        <Column
          dataField={'v_phone'}
          caption={t('phone')}
          hidingPriority={4}
        />
        <Column
          dataField={'v_email'}
          caption={t('email')}
          hidingPriority={1}
        />
        <Column
          dataField={'v_status'}
          caption={t('status')}
          encodeHtml={false}
          calculateCellValue={prepareStatusValue}
        >
        </Column>
        <Column
          dataField={'v_created_by'}
          caption={t('Created by')}
          visible={false}
        >
        </Column>
        <Column
          dataField={'v_created_at'}
          caption={t('Created at')}
          visible={false}
        >
        </Column>
        <Column
          dataField={'v_updated_by'}
          caption={t('Updated by')}
          visible={false}
        >
        </Column>
        <Column
          dataField={'v_updated_at'}
          caption={t('Updated at')}
          visible={false}
        >
        </Column>
        <Column
          dataField={'v_note'}
          caption={t('note')}
          hidingPriority={0}
        />

        <Paging defaultPageSize={8} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={allowedPageSizes}
        />
      </DataGrid>
    </React.Fragment>
    );
  }

}

export default withTranslation()(Vehicles);