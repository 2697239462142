import React /*,{useState}*/ from 'react';
import $ from "jquery";
import './css/language-selector.css'
function LanguageSelector () {

  const languages = [{
    ID: 1,
    Name: 'العربية',
    src : "ar.svg",
    lng: "ar"
  },{
    ID: 2,
    Name: 'English',
    src : "en.svg",
    lng:"en"
  },{
    ID: 3,
    Name: 'French',
    src : "fr.svg",
    lng: "fr"
  }];

  function DropDown(el) {
      this.dd = el;
      this.placeholder = this.dd.children('span');
      this.opts = this.dd.find('ul.dropdown > li');
      this.val = '';
      this.index = -1;
      this.initEvents();
  }

  DropDown.prototype = {
    initEvents : function() {
      var obj = this;
      obj.dd.on('click', function(event){
        $(this).toggleClass('active');
        return false;
      });

      obj.opts.on('click',function(){
        let pattern = /alt="(.*?).svg"/i;
        var opt = $(this);
        obj.val = opt[0].innerHTML.match(pattern)[1]
        obj.index = opt.index();
        obj.placeholder.text(obj.val);
        //Change Language Here
        console.log('obj.val:::',)
        languages.map( language => {
          if(language.lng === obj.val ){
            window.localStorage.setItem("lang", language.lng);
            window.location.reload();
          }
        })
      });
    },
    getValue : function() {
      return this.val;
    },
    getIndex : function() {
      return this.index;
    }
  }

  $(function() {
    var dd = new DropDown( $('#dd') );
    $(document).click(function() {
      // all dropdowns
      $('.wrapper-dropdown-3').removeClass('active');
    });
  });

    
  const generateDropDown = () => {
    return languages.map(language => {
      return <li key={language.ID}>
              <a href="#"><i className="icon-large"><img draggable="false" role="img" className="emoji" alt={language.src} src={"./images/icons/"+language.src}></img></i></a>
            </li>
    })
  }
  //charge current language
  const lng = window.localStorage.getItem("lang") || "en"
  let lang  = "English"
  languages.map( language => {
    if(language.lng ===  lng ){
      lang = language
    }
  })

  return <div id="dd" className="wrapper-dropdown-3" tabIndex="1">
          <img draggable="false" role="img" className="emoji" alt={lang.src} src={"./images/icons/"+lang.src}></img>
          <ul className="dropdown" style={{display:"block"}}>
              {generateDropDown()}
          </ul>
        </div>;
}

export default LanguageSelector;