import React /*, { useState, useRef }*/ from 'react';
import { withTranslation } from 'react-i18next';
import { HOST_API } from "../../env";
import ClientPersonnel from '../../components/personnels/personnels';
function Personnel({t}) {
  return (
    <React.Fragment>
      <ClientPersonnel clientType="all"/>
    </React.Fragment>
)}
export default withTranslation()(Personnel);