import "./styles.scss";
import "leaflet/dist/leaflet.css";
import "leaflet-geosearch/dist/geosearch.css";
import "react-leaflet-fullscreen/dist/styles.css";
import "leaflet.locatecontrol/dist/L.Control.Locate.min.css";

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import {ErrorBoundary} from 'react-error-boundary';
// import ReactDOM from 'react-dom';
import { MapContainer, TileLayer, Marker, useMap } from "react-leaflet";
import L, {LatLng, latLngBounds, FeatureGroup} from 'leaflet';
import MarkerClusterGroup from "react-leaflet-markercluster";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
import { FullscreenControl } from "react-leaflet-fullscreen";
import Locate from "leaflet.locatecontrol";
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import { Button } from 'devextreme-react/button';
import { Switch } from 'devextreme-react/switch';
import { LoadPanel } from 'devextreme-react/load-panel';
import { confirm } from 'devextreme/ui/dialog';

import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  Lookup,
  Form,
  Editing,
  RequiredRule,
  PatternRule,
  RemoteOperations,
  Selection
} from 'devextreme-react/data-grid';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import CustomStore from 'devextreme/data/custom_store';
// import DataSource from 'devextreme/data/data_source';
// import 'devextreme/data/odata/store';
import { GroupItem, SimpleItem, Label} from 'devextreme-react/form';

// import 'devextreme-react/text-area';
import { HOST_API } from "../../env";
// import $ from "jquery";

import {logRequest, sendRequest, isNotEmpty} from '../../utils/utils';

function PickupPointQuickSelect(props) {
  const { t, isPopupOpen, clientId, clientData, editMode, returnPPs, callBack } = props;
  // 
  // const [markers, setMarkers] = useState(0);
  // let PPsLoaded = true;
  const PPsDataGridRef = useRef(null);
  // const FormGeoGroup = useRef(null);
  let PPsLatEditor = useRef(null);
  let PPsLngEditor = useRef(null);
  let PPsMapLoadPanel = useRef(null);
  let PPsPopupValidate = useRef(null);
  // let PPsPopupClear = useRef(null);
  // useEffect(() => {
    // Anything in here is fired on component mount.
    // return () => {
    //   PPsLoaded = false;
      // Anything in here is fired on component unmount.
      // console.log("fired on component unmount");
      // console.log("PPsLoaded:"+PPsLoaded)
      // if(document.getElementById('PPsMapDiv')) document.getElementById('PPsMapDiv').remove();
  //   }
  // }, [PPsLoaded])

  let PPsFocusedRowChanged = false;
  let PPsMap = null;
  let PPsMapInitialized = false;
  let PPsStoreData = null;
  let PPsMapMarkers = null;
  let PPsMapOnEditMode = false;
  let PPsActivePointData = null;
  // let GridMapSyncEventSource = null;
  
  var MarkerIcon = L.Icon.extend({
    options: {
      customId: "",
      iconUrl: '//raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
      shadowUrl: '//cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
      iconSize: [17, 28],
      iconAnchor: [8, 28],
      popupAnchor: [1, -34],
      shadowSize: [28, 28]
    }
  });

  let redMarker = new MarkerIcon({iconUrl: "//raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png"});
  let searchMarker = new MarkerIcon({iconUrl: "//raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-orange.png"});
  
  const PPsScreen = (width) => {
    return (width < 700) ? 'sm' : 'lg';
  }

  const PPsPopupSwitchButtons = (disabled) => {
    let PPsPopupValidateObject = PPsPopupValidate.current.instance;
    // let PPsPopupClearObject = PPsPopupClear.current.instance;
    PPsPopupValidateObject.option("disabled", disabled);
    // PPsPopupClearObject.option("disabled", disabled);
  }
  
  const PPsonClearButtonClicked = (e) => {
    console.log("e");
  }
  
  const PPsStore = new CustomStore({
    key: 'gp_id_gp',
    load: (loadOptions) => sendRequest(`${HOST_API}/v/geopoints`,loadOptions,'GET',{sort: ['[{"selector":"gp_name","asc":true}]'] }),
    onLoaded: function (result) {
      PPsStoreData = result.data;
      PPsBindPointsMarkers(result.data);
      PPsActivePointData = null;
      PPsPopupSwitchButtons(true);
    },
    insert: (values) => {
      return sendRequest(`${HOST_API}/geopoints`, null, 'POST', {...values })
    },
    update: function(key, values){
      let res = sendRequest(`${HOST_API}/geopoints/update`, null, 'POST', {...values, gp_id_gp:key})
      return res;
    },
    remove: function(key){
      return sendRequest(`${HOST_API}/geopoints`, null, 'DELETE', {key})
    }
  })
  
  const PPsInitializeMap = () => {
    const { t } = withTranslation();
    let PPsMapContainer = document.getElementById('PPsMapDiv');
    if(PPsMapContainer === null){
      PPsMapInitialized = false;
      return false;
    }
    // console.log(PPsMapContainer);
    PPsMapMarkers = L.markerClusterGroup();
    
    let PPsattribution = '&copy; <a href="https://navetrack.com">NaveTrack</a>';
    let PPsBaseLayers = {
      "OSM Mapnik": L.tileLayer('//{s}.tile.osm.org/{z}/{x}/{y}.png', {attribution: PPsattribution}),
      "Satellite ArcGIS": L.tileLayer("//server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}", {attribution: PPsattribution}),
      // "Stadia Dark": L.tileLayer('//tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png', {attribution: attribution}),
      // "Landscape": L.tileLayer('//{s}.tile.thunderforest.com/landscape/{z}/{x}/{y}.png', {attribution: attribution}),
    };
    
    try {
      if (PPsMap && PPsMap.remove) {
        PPsMap.off();
        PPsMap.remove();
      }
    } catch (error) {
      console.log("Error: PPsMapContainer appears to be not yet initialized");
    }
    // console.log(PPsMap);
    PPsMap = L.map(PPsMapContainer, {
      fullscreenControl: true,
      attributionControl: false,
      center: [35.7545,-5.8324],
      zoom: 12,
      layers: [PPsBaseLayers["OSM Mapnik"]]
    });
    L.control.layers(PPsBaseLayers).addTo(PPsMap);

    // PPsMap.on('moveend zoomend', (e) => { 
    //   console.log("Map moveend/zoomend");
    // });
    
    // Setup Search option
    PPsMap.addControl(
      new GeoSearchControl({
        provider: new OpenStreetMapProvider(),
          marker: {
            icon: searchMarker
          }
      })
    );
    
    // Setup GeoLocation option
    PPsMap.addControl(L.control.locate(
      {
        position: 'topleft', //topright
        strings: {
          title: 'Get my current location'
        },
        onActivate: () => {} 
      }
    ));
    
    PPsMapInitialized = true;
  }
  
  const PPsBindPointsMarkers = markersData => {
    // GridMapSyncEventSource = "Grid";
    let PPsLatLngsArray = [];
    if(PPsMapInitialized === false || PPsMap === null){
      PPsInitializeMap()
    }
    try {
      PPsMapMarkers.clearLayers();
    } catch (error) {
      PPsInitializeMap();
    }
    PPsMapMarkers.clearLayers();
    if(Object.keys(markersData).length == 0) return null;
    
    markersData.map((item, i) => {
      if(item.gp_lat !== null && item.gp_lng !== null){
        PPsLatLngsArray.push([item.gp_lat, item.gp_lng]);
        // L.marker([item.gp_lat, item.gp_lng], {icon: redMarker, title:item.gp_name}).addTo(PPsMap).bindTooltip(item.gp_name);
        PPsMapMarkers.addLayer(
          L.marker(
          [item.gp_lat, item.gp_lng],
          {
            customId:item.gp_id_gp,
            icon: redMarker,
            geofence:item.gp_geofence,
          })
          .on('click', PPsMarkerOnClick)
          .bindPopup("<h6>"+item.gp_name+"</h6><ul>"+
          "<li><b>Description:</b> "+item.gp_description+"</li>"+
          "<li><b>District:</b> "+item.gp_district+", <b>City:</b> "+item.gp_city+"</li>"+
          "<li><b>GeoPoint:</b> "+item.gp_lat+", "+item.gp_lng+", <b>GeoFence:</b> "+item.gp_geofence+"</li>"+
          "<li><b>Last update:</b> "+item.gp_updated_at+"</li>"+
          "</ul>")
          .bindTooltip('<div class="FocusedMarkerTooltip">'+item.gp_name+'</div>')
        );

        // PPsMapMarkers.addLayer(L.circle([item.gp_lat, item.gp_lng], 150, {color: 'blue',fillColor: 'blue'}));
      }
    });
    // console.log("PPsLatLngsArray")
    // console.log(PPsLatLngsArray)
    // console.log(PPsLatLngsArray.length)
    if(PPsLatLngsArray.length>0){
      PPsMap.addLayer(PPsMapMarkers);
      var PPsBounds = new L.LatLngBounds(PPsLatLngsArray);
      // console.log(PPsBounds);
      setTimeout(() => {
        PPsMap.flyToBounds(PPsBounds,   {padding: [20, 20], duration:0.30} );
      }, 100);
    }
    PPsMap.invalidateSize();
  }
  
  const PPsMarkerOnClick = e => {
    let PPsDataGridObject = PPsDataGridRef.current.instance;
    PPsDataGridObject.navigateToRow(e.target.options.customId);
    // PPsDataGridObject.selectRows(e.target.options.customId, false);
    PPsDataGridObject.option('focusedRowKey', e.target.options.customId)
    //  = e.target.options.customId
  }
  
  const PPsGetMapClickLatLng = loc => {
    PPsMapMarkers.clearLayers();
    PPsMapMarkers.addLayer(L.marker([loc.latlng.lat, loc.latlng.lng], {icon: redMarker}));

    let PPsDataGridObject = PPsDataGridRef.current.instance;
    let rowKey = PPsDataGridObject.option('focusedRowKey')
    if(rowKey !== null){
      let row_index = PPsDataGridObject.getRowIndexByKey(rowKey);
      PPsDataGridObject.cellValue(row_index, PPsDataGridObject.getVisibleColumnIndex("gp_lat"), loc.latlng.lat);
      PPsDataGridObject.cellValue(row_index, PPsDataGridObject.getVisibleColumnIndex("gp_lng"), loc.latlng.lng);
    }else{
      PPsLatEditor.setValue( loc.latlng.lat );
      PPsLngEditor.setValue( loc.latlng.lng );
    }
  }

  const PPsOnInitNewRow = e => {
    let PPsDataGridObject = PPsDataGridRef.current.instance;
    PPsDataGridObject.option('focusedRowKey', null)
    PPsPrepareMapLocationPicker(e);
  }

  const PPsPrepareMapLocationPicker = e => {
    PPsMapMarkers.clearLayers();
    if(Object.keys(e.data).length > 0){
      PPsBindPointsMarkers([e.data])
    }
    PPsMap.on("click", PPsGetMapClickLatLng);
    L.DomUtil.addClass(PPsMap._container, 'crosshair-cursor-enabled');

    PPsMapOnEditMode = true;
  };

  const PPsResetMap = e => {
    if(PPsMapOnEditMode === false) return null;
    PPsMap.off('click', "");
    /*
    if (!L.Browser.touch) {
      L.DomEvent.disableClickPropagation(PPsMap);
      L.DomEvent.on(PPsMap, 'mousewheel', L.DomEvent.stopPropagation);
    } else {
      L.DomEvent.on(PPsMap, 'click', L.DomEvent.stopPropagation);
    }
    */
    L.DomUtil.removeClass(PPsMap._container, 'crosshair-cursor-enabled');
    PPsBindPointsMarkers(PPsStoreData);
    PPsMapOnEditMode = true;
  };
  
  const PPsOnRowClick = e => {
    if(!PPsFocusedRowChanged){
      const dataRow = e.data;
      var dataGrid = e.component;
      dataGrid.clearSelection();
      dataGrid.option("focusedRowIndex", -1);
    }
    PPsFocusedRowChanged = false;
  }

  const PPsOnFocusedRowChanged = e => {
    PPsFocusedRowChanged = true;
    // GridMapSyncEventSource = "Grid";
    if(e.rowIndex >= 0){
      const dataRow = e.row && e.row.data;
      PPsMapMarkers.eachLayer(function(layer){
        if(layer.options.customId === dataRow.gp_id_gp){
          let layerLatLng = [layer._latlng.lat, layer._latlng.lng];
          PPsMap.flyTo(layerLatLng, 15, {duration:1} );
          setTimeout(() => {
            layer.openPopup()
          }, 1000);
        }else{
          layer.closePopup();
        }
      });

      PPsActivePointData = dataRow;
      PPsPopupSwitchButtons(false);
    }else{
      PPsBindPointsMarkers(PPsStoreData); // to be optimized (a correct rezoom will be enough)
      PPsActivePointData = null;
      PPsPopupSwitchButtons(true);
    }
  }

  const PPsOnSelectionChanged = e => {
    console.log("PPsOnSelectionChanged")
    // let PPsPopupValidateObject = PPsPopupValidate.current.instance;
    // PPsFocusedRowChanged = true;
    // // GridMapSyncEventSource = "Grid";
    // if(e.rowIndex >= 0){
    //   const dataRow = e.row && e.row.data;
    //   PPsMapMarkers.eachLayer(function(layer){
    //     if(layer.options.customId === dataRow.gp_id_gp){
    //       let layerLatLng = [layer._latlng.lat, layer._latlng.lng];
    //       PPsMap.flyTo(layerLatLng, 15, {duration:1} );
    //       setTimeout(() => {
    //         layer.openPopup()
    //       }, 1000);
    //     }else{
    //       layer.closePopup();
    //     }
    //   });

    //   PPsActivePointData = dataRow;
    //   PPsPopupValidateObject.option("disabled", false);
    // }else{
    //   PPsBindPointsMarkers(PPsStoreData); // to be optimized (a correct rezoom will be enough)
    //   PPsActivePointData = null;
    //   PPsPopupValidateObject.option("disabled", true);
    // }
  }

  const PPsOnEditorPrepared = e => {
    if(e.parentType ==="dataRow"){
      if(e.dataField === "gp_lat") PPsLatEditor = e;
      else if(e.dataField === "gp_lng") PPsLngEditor = e;
    }
  }
  
  const PPsRenderGeoData = data => {
    return (
      <div>
        Use Map to set point marker
      </div>
    );
  }
  
  const preCallBack = data => {
    try {
      let PPsDataGridObject = PPsDataGridRef.current.instance;
      PPsDataGridObject.cancelEditData()
    } catch (error) {}
    callBack(data);
  }
  
  
  const closePopup = e => {
    let PPsDataGridObject = PPsDataGridRef.current.instance;
    const focusedRowKey = PPsDataGridObject.option('focusedRowKey');
    preCallBack("test data ");
  }
  
  const renderSwitch = e => {
    // console.log("renderSwitch ");
    // console.log(e.data.cn_id_comcl);
    let PPsEnabledClients = e.data.cn_id_comcl;
    let PPsmatched = false;
    if( PPsEnabledClients &&  PPsEnabledClients.match(new RegExp("(?:^|,)"+clientId+"(?:,|$)"))) {
      PPsmatched = true;
    }
    return (
      <Switch defaultValue={PPsmatched} switchData={e.data} onValueChanged={SwitchValueChanged} />
    )
  }
  
  const SwitchValueChanged = (e) => {
    // console.log("SwitchValueChanged e")
    // console.log(e)
    let switchData = e.component.option('switchData');
    let params = {
      cn_id_comcl: clientId,
      cp_id_gp: switchData.gp_id_gp,
      cp_status: (e.value === true) ? '1' : '0'
    };
    if(e.value === false){
      let result = confirm(t("Are you sure?"), t("Disabling pickup point!"));
      result.then((dialogResult) => {
        if(!dialogResult){
          console.log(e.component)
          e.component.option("value", true);
          return false;
        }else{
          validateAsync(params)
        }
      });
    }else{
      validateAsync(params)
    }
    
  }
  const validateAsync = function(values) {
    console.log("validateAsync")
    console.log(clientData)
    return new Promise((resolve, reject) => {
      sendRequest(`${HOST_API}/contractspoints`, null, 'POST', {...values })
      .then(response => {
        console.log("validateAsync response")
        console.log(response)
        // if (!response.ok) {
        //   throw new Error(`HTTP error: ${res.status} ${res.statusText}`);
        // }
        // return response.json();
      })
      // .then(res => {
      //     // res.message contains validation error message
      //     res.isValid ? resolve() : reject(res.message);

      //     // ===== or if "res" is { isValid: Boolean, message: String } =====
      //     resolve(res);
      // })
      // .catch(error => {
      //     console.error("Server-side validation error", error);

      //     reject("Cannot contact validation server");
      // });
    });
  };

  return (
    <React.Fragment>
      <Popup
        visible={true}
        title={t("Select/Add pickup points")}
        showCloseButton={false}
        height={"75vh"}
        resizeEnabled={true}
        showTitle={true}
        >
        <ToolbarItem location="after">
          <Button icon="close"
            onClick={(e) => {
              preCallBack(null);
            }}
          />
        </ToolbarItem>
        <ToolbarItem
          toolbar="bottom"
          location="after"
          visible={returnPPs}
          >
          {/* <Button
            ref={PPsPopupClear}
            text={t("Clear Selection")}
            stylingMode="contained"
            onClick={PPsonClearButtonClicked}
          /> */}
          <Button
            ref={PPsPopupValidate}
            text={t("Validate")}
            type="default"
            stylingMode="contained"
            onClick={(e) => {
              preCallBack(PPsActivePointData);
            }}
          />
        </ToolbarItem>
        <ResponsiveBox
          singleColumnScreen="sm"
          screenByWidth={PPsScreen}>
          <Row ratio={1.3} screen="xs"></Row>
          <Row ratio={2}></Row>
          <Col ratio={1.2}></Col>
          <Col ratio={2} screen="lg"></Col>
          <Item>
            <Location row={0} col={0} screen="lg"></Location>
            <Location row={1} col={0} screen="sm"></Location>
            <DataGrid
              className={'dx-card wide-card PPsGrid'}
              id="PPsGrid"
              ref={PPsDataGridRef}
              dataSource={PPsStore}
              cacheEnabled={false}
              showBorders={true}
              remoteOperations={true}
              focusedRowEnabled={true}
              // defaultFocusedRowIndex={0}
              // autoNavigateToFocusedRow={true}
              allowColumnResizing={true}
              showColumnLines={true}
              rowAlternationEnabled={true}
              // columnResizingMode={"widget"}
              // columnAutoWidth={true}
              columnHidingEnabled={true}
              allowColumnReordering={true}

              onInitNewRow={PPsOnInitNewRow}
              onEditingStart={PPsPrepareMapLocationPicker}
              onRowInserted={PPsResetMap}
              onEditCanceled={PPsResetMap}
              onRowInserted={PPsResetMap}
              onRowUpdated={PPsResetMap}
              onRowRemoved={PPsResetMap}
              onRowUpdated={PPsResetMap}
              onEditorPrepared={PPsOnEditorPrepared}
              onRowClick={PPsOnRowClick}
              onFocusedRowChanged={PPsOnFocusedRowChanged}
              onSelectionChanged={PPsOnSelectionChanged}

              // onRowInserting={CheckMapUpdate}
              // onRowUpdating={updateRow}
              // onSaving={CheckMapUpdate2}
              // onSaved={CheckMapUpdate}
              // onRowClick={}
            >
              <Editing
                mode="form"
                refreshMode="reshape"
                // allowAdding={true}
                allowAdding={editMode === 'full'}
                allowUpdating={editMode === 'full'}
                allowDeleting={editMode === 'full'}
                >
                <Form>
                  <GroupItem colCount={1} colSpan={2}>
                    <SimpleItem dataField="gp_name" validationRules />
                    <SimpleItem dataField="gp_description" />
                    <SimpleItem dataField="gp_address" />
                  </GroupItem>
                  <GroupItem colCount={2} colSpan={2}>
                    <SimpleItem dataField="gp_district" />
                    <SimpleItem dataField="gp_city"/>
                  </GroupItem>
                  <GroupItem colCount={3} colSpan={2}>
                    <Item dataField="gp_lat" editorOptions={{ readOnly: true }}/>
                    <SimpleItem dataField="gp_lng" editorOptions={{ readOnly: true }} />
                    <SimpleItem dataField="gp_geofence"/>
                  </GroupItem>
                  <GroupItem render={PPsRenderGeoData}></GroupItem>
                </Form>
              </Editing>
              {/* <RemoteOperations paging={true}/> */}
              {/* <Scrolling mode="virtual" rowRenderingMode="virtual" /> */}
              <Paging defaultPageSize={100}/>
              <Pager
                visible={true}
                displayMode="compact"
                showPageSizeSelector={true}
                showInfo={true}
                infoText={2}
                allowedPageSizes={[10, 25, 50, 100, 500, 1000]}/>
              <FilterRow visible={true} />
              {/* <Selection mode="multiple" /> */}
              <Column dataField={'gp_name'} caption={t('Point name')} width={200} >
                <RequiredRule />
              </Column>
              <Column dataField={'cn_id_comcl'} caption={t('Enabled')} cellRender={renderSwitch} width={50} />
              <Column dataField={'gp_description'} caption={t('Description')} hidingPriority={11} />
              <Column dataField={'gp_address'} caption={t('Address')} hidingPriority={8} />
              <Column dataField={'gp_district'} caption={t('District')} hidingPriority={10} />
              <Column dataField={'gp_city'} caption={t('City')} hidingPriority={9} />
              <Column dataField={'gp_lat'} caption={t('Latitude')} hidingPriority={7} editorOptions={{ visbible: false, hidden: true, style:{...{hidden: true}} }}/>
              <Column dataField={'gp_lng'} caption={t('Longitude')} hidingPriority={6} />
              <Column dataField={'gp_point'} caption={t('Geopoint')} visible={false} />
              <Column dataField={'gp_geofence'} caption={t('Geofence')} hidingPriority={1} />
              {/* <Column dataField={'gp_status'} caption={t('Status')} encodeHtml={false} calculateCellValue={prepareStatusValue} ></Column> */}
              <Column dataField={'gp_created_by'} caption={t('Created by')} visible={false} />
              <Column dataField={'gp_created_at'} caption={t('Created at')} visible={false} />
              <Column dataField={'gp_updated_by'} caption={t('Updated By')} visible={false} />
              <Column dataField={'gp_updated_at'} caption={t('Updated at')} visible={false} />

            </DataGrid>
          </Item>
          <Item>
            <Location row={0} col={1} screen="lg"></Location>
            <Location row={2} col={0} screen="sm"></Location>
            <div id="PPsMapDiv" className="dx-card PPsMap">
              {/* <LoadPanel
                ref={PPsMapLoadPanel}
                position={{ of: '#PPsMapDiv' }}
                shading={true}
                shadingColor="rgba(0,0,0,0.4)"
                visible={true}
                /> */}
            </div>
          </Item>
        </ResponsiveBox>
      </Popup>
    </React.Fragment>
  );
}
export default withTranslation()(PickupPointQuickSelect);