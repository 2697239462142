import React from 'react';
import { withTranslation } from 'react-i18next';
import CustomStore from 'devextreme/data/custom_store';
import { GroupItem, SimpleItem ,Item } from 'devextreme-react/form';
import { sendRequest } from '../../utils/utils';
import { HOST_API } from "../../env";

import ClientContractPrices from './contractPrices';

import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  Lookup,
  Form,
  Toolbar,
  Editing,
  Popup,
  RequiredRule,
  PatternRule,
  MasterDetail
} from 'devextreme-react/data-grid';

function ClientContracts({t, clientId}) {
  const dxDateBoxOptions = {
    displayFormat: "dd/MM/yyyy",
    dateSerializationFormat: "yyyy-MM-dd"
  };
  
  const ContractsStore  = new CustomStore({
    key: 'cn_id_cn',
    load: (loadOptions) => sendRequest(`${HOST_API}/v/contracts`,loadOptions,'GET',{filter: ['["cn_id_comcl","=",'+clientId+']'] }),
    insert: (values) => {
      if(values.cn_status !== null && values.cn_status !== undefined ){
        values.cn_status = (values.cn_status === true) ? '1' : '0';
      }
      return sendRequest(`${HOST_API}/contracts`, null, 'POST', {...values, cn_id_comcl:clientId })
    },
    update: function(key, values){
      if(values.cn_status !== null && values.cn_status !== undefined ){
        values.cn_status = (values.cn_status === true) ? '1' : '0';
      }
      let res = sendRequest(`${HOST_API}/contracts/update`, null, 'POST', {...values,cn_id_comcl:clientId,cn_id_cn:key})
      // console.log("res = " , res)
      // return "Error";
      return res;
    },
    remove: (key) => sendRequest(`${HOST_API}/contracts`,null, 'DELETE', {
      key,
    }),
  })

  const prepareStatusValue = rowData => {
    return rowData.cn_status === '1' ? true : false;
  };

  return (
    <React.Fragment>
      <DataGrid
        className={'dx-card wide-card'}
        keyExpr="cn_id_cn"
        dataSource={ContractsStore}
        showBorders={true}
        remoteOperations={false}
        focusedRowEnabled={true}
        // defaultFocusedRowIndex={0}
        rowAlternationEnabled={true}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        showColumnHeaders={true}
      >
      <MasterDetail
        component={ClientContractPrices}
        enabled={true}
        autoExpandAll={true}
        />
      <Editing 
        mode="raw"
        allowAdding={true} 
        allowUpdating={true}
        allowDeleting={true}
        />
      <Toolbar className="GridToolbar">
        <Item location="before">
          <h5>{t('contracts')}</h5>
        </Item>
        <Item location="after" name="addRowButton"/>
        {/* <Item name="columnChooserButton"/> */}
      </Toolbar>
        <Column
          dataField={'cn_started_at'}
          caption={t('started at')}
          dataType="date"
          editorType="dxDateBox"
          editorOptions={dxDateBoxOptions}
          sortIndex={1}
          sortOrder="asc"
        />
        <Column
          dataField={'cn_terminated_at'}
          caption={t('terminated at')}
          dataType="date"
          editorType="dxDateBox"
          editorOptions={dxDateBoxOptions}
        />
        <Column
          dataField={'cn_status'}
          caption={t('status')}
          encodeHtml={false}
          calculateCellValue={prepareStatusValue}
          editorType="dxCheckBox"
          isValid={true}
          sortIndex={0}
          sortOrder="desc"
        />
      </DataGrid>
  </React.Fragment>
)}

export default withTranslation()(ClientContracts);

