import React from 'react';
import { withTranslation } from 'react-i18next';
import CustomStore from 'devextreme/data/custom_store';
import { sendRequest } from '../../utils/utils';
import { HOST_API } from "../../env";

import Accordion from 'devextreme-react/accordion';
import DataGrid, {
  Column,
  Pager,
  Paging,
  // FilterRow,
  // Lookup,
  // Form,
  // Toolbar,
  Editing,
  // Popup,
  // RequiredRule,
  // PatternRule,
  // MasterDetail
} from 'devextreme-react/data-grid';

function personnelTeams({t, clientId}) {
  const CustomItem = () => {
    return (
      <DataGrid
        className={'dx-card wide-card'}
        keyExpr="tm_id_tm"
        dataSource={TeamsStore}
        showBorders={true}
        remoteOperations={false}
        focusedRowEnabled={true}
        // defaultFocusedRowIndex={0}
        rowAlternationEnabled={true}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        showColumnHeaders={false}
        >
        <Editing
          mode="raw"
          allowAdding={true} 
          allowUpdating={true}
          allowDeleting={true}
        />
        <Paging defaultPageSize={10} />
        <Pager
          showPageSizeSelector={true}
          showInfo={true}
          allowedPageSizes={[10,50,100]}
        />
        <Column dataField={'tm_team'} caption={t('team')} />
        {/*
        <Toolbar className="GridToolbar">
          <Item location="before">
            <h5>{t('teams')}</h5>
          </Item>
          <Item location="after" name="addRowButton" groupIndex={0}/>
          <Item name="columnChooserButton"/>
        </Toolbar> */}
      </DataGrid>
    );
  }

  const TeamsStore = new CustomStore({
    key: 'tm_id_tm',
    load: (loadOptions) => sendRequest(`${HOST_API}/v/teams`,loadOptions,'GET',{filter: ['["tm_id_com","=",'+clientId+']'], sort: ['[{"selector":"tm_team","asc":true}]'] }),
    insert: (values) => {
      return sendRequest(`${HOST_API}/teams`, null, 'POST', {...values, tm_id_com:clientId })
    },
    update: function(key, values){
      let res = sendRequest(`${HOST_API}/teams/update`, null, 'POST', {...values, tm_id_com:clientId, tm_id_tm:key})
      // console.log("res = " , res)
      // return "Error";
      return res;
    },
    remove: (key) => sendRequest(`${HOST_API}/teams`,null, 'DELETE', {
      key,
    }),
  })

  return (
    <React.Fragment>
        <Accordion
          className={"TeamsShiftsAccordion"}
          dataSource={[{ID: 1}]}
          collapsible={true}
          //animationDuration={animationDuration}
          itemTitleRender={e =>{
            return <span>{t('teams')}</span>
          }}
          itemRender={CustomItem}
          onContentReady={e => {
            e.component.collapseItem(0);
          }}
        />
    </React.Fragment>
  )
}

export default withTranslation()(personnelTeams);