import React, { useState, useRef, useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import notify from 'devextreme/ui/notify';
import { useAuth } from '../../contexts/auth';

import './login-form.scss';

function LoginForm() {
  // const { t } = withTranslation();
  const t = (v) => {return v} // TMP DECLARATION

  
  const history = useHistory();
  const { signIn } = useAuth();
  const [loading, setLoading] = useState(false);
  const formData = useRef({});


  const onSubmit = useCallback(async (e) => {
    e.preventDefault();
    const { username, password } = formData.current;
    setLoading(true);
    const result = await signIn(username, password);
    //console.log(result)
    if (!result.isOk) {
      setLoading(false);
      notify(result.message, 'error', 2000);
      return;
    }
    //history.push('/home');
  // window.history.pushState({}, '', '/');
	window.location.reload()
  },[history, signIn]);
  /*
  const onSubmit = useCallback(async (e) => {
    e.preventDefault();
    const { username, password } = formData.current;
    setLoading(true);
    const result = await signIn(username, password);
    if (!result.isOk) {
      setLoading(false);
      notify(result.message, 'error', 2000);
    }
    history.push('/home');
  // window.history.pushState({}, '', '/');
	//window.location.reload()
  }, [signIn]);*/
  const emailEditorOptions = { stylingMode: 'filled', placeholder: t('Nom d\'utilisateur'), mode: 'text' };
  const passwordEditorOptions = { stylingMode: 'filled', placeholder: t('Mot de passe'), mode: 'password' };

  const onCreateAccountClick = useCallback(() => {
    history.push('/create-account');
  }, [history]);

  return (
    <form className={'login-form'} onSubmit={onSubmit}>
      <Form formData={formData.current} disabled={loading}>
        <Item
          dataField={t('username')}
          editorType={'dxTextBox'}
          editorOptions={emailEditorOptions}
        >
          <RequiredRule message={t('Nom d\'utilisateur est nécessaire')} />
          <Label visible={false} />
        </Item>
        <Item
          dataField={t('password')}
          editorType={'dxTextBox'}
          editorOptions={passwordEditorOptions}
        >
          <RequiredRule message={t('Mot de passe est nécessaire')} />
          <Label visible={false} />
        </Item>
        <ButtonItem>
          <ButtonOptions
            width={'100%'}
            type={'default'}
            useSubmitBehavior={true}
          >
            <span className="dx-button-text">
              {
                loading
                  ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                  : t('se connecter')
              }
            </span>
          </ButtonOptions>
        </ButtonItem>
        {/* <Item>
          <div className={'link'}>
            <Link to={'/reset-password'}>Forgot password?</Link>
          </div>
        </Item> */}
      </Form>
    </form>
  );
}

export default withTranslation()(LoginForm);
