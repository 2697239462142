import React /*,{useState}*/ from 'react';
import { withTranslation } from 'react-i18next';
import 'devextreme/data/odata/store';
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  // Lookup,
  Form,
  Editing,
  Popup,
  RequiredRule,
  PatternRule,
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { Item } from 'devextreme-react/form';
import { formatDate } from 'devextreme/localization';
import 'whatwg-fetch';
import { HOST_API } from "../../env";
import $ from "jquery";
function isNotEmpty(value) {
  return value !== undefined && value !== null && value !== '';
}

// const notesEditorOptions = { height: 100 };
const companiesTypes = new DataSource({
  key: 'ct_id_ct',
  load(_) {
    let token  = localStorage.getItem("token");
    return fetch(`${HOST_API}/v/companiestypes`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token
      }
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("data:::", data)

        return {
          data: data.data
        }
      })
      .catch(() => { throw new Error('Data Loading Error'); });
  },
});

function Companies({t}) {

  return (
    <React.Fragment>
      <h2 className={'content-block'}>{t('companies')}</h2>

      <DataGrid
        className={'dx-card wide-card'}
        keyExpr="com_id_com"
        dataSource={store}
        showBorders={true}
        remoteOperations={true}
        focusedRowEnabled={true}
        defaultFocusedRowIndex={0}
        columnAutoWidth={true}
        columnHidingEnabled={true}
      >
      <Editing
        mode="popup"
        allowAdding={true}
        allowUpdating={true}
        allowDeleting={true}>
        <Popup title={t('company_info')} showTitle={true} width={1000} height={600}  shading={true}   />
        <Form>
          <Item itemType="group" colCount={2} colSpan={2}>
            <Item dataField="com_name" caption="" validationRules />
            <Item dataField="com_ICE" />
            <Item   cssClass="com_id_ct_clss" editorType="dxSelectBox" editorOptions={{dataSource: companiesTypes,valueExpr:'ct_id_ct',displayExpr:'ct_type' } } >
                <RequiredRule message={t('please_choose_a_company_type')} />
            </Item>
            <Item dataField="com_activity" />
            <Item dataField="com_responsible" />
            <Item dataField="com_phone" />
            <Item dataField="com_email" />
            <Item dataField="com_note" />
          </Item>
          
          <Item itemType="group" caption="Tax Options" colCount={2} colSpan={2}>
              <Item dataField="com_taxable" editorType="dxCheckBox" verticalAlignment={false} />
                <Item dataField="com_tax_rate" />
            </Item>
          <Item itemType="group" caption={t('company_address')}  colCount={2} colSpan={2}>
            <Item dataField="com_district" />
            <Item dataField="com_city" />
            <Item dataField="com_address" />
          </Item>
        </Form>
        </Editing>
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        <FilterRow visible={true} />

        <Column
          dataField={'ct_type'}
          caption={t('company_type')}
          visible={true}
        >
          </Column>
        <Column
              dataField={'com_name'}
              width={190}
              caption={t('name')}
            >
            <RequiredRule message="please Name" />
            <PatternRule pattern={/^\s*(?:\S\s*){3,100}$/} message={t('please_Enter_a_valid_name')} />
        </Column>
        <Column
          dataField={'com_ICE'}
          caption={t('ice')}
          hidingPriority={0}
        />
        <Column
          dataField={'com_activity'}
          caption={t('activity')}
          hidingPriority={1}
        >
        </Column>
        <Column
          dataField={'com_responsible'}
          caption={t('responsible')}
          allowSorting={false}
          hidingPriority={2}
        />
        <Column
          dataField={'com_phone'}
          caption={t('phone')}
          hidingPriority={3}
        />
        <Column
          dataField={'com_email'}
          caption={t('email')}
          hidingPriority={4}
        />
        <Column
          dataField={'com_address'}
          caption={t('address')}
          hidingPriority={5}
        />
        <Column
          dataField={'com_district'}
          caption={t('district')}
          hidingPriority={6}
        />
        <Column
          dataField={'com_city'}
          caption={t('city')}
          hidingPriority={7}
        />
        <Column
          dataField={'com_taxable'}
          caption={t('taxable')}
          hidingPriority={8}
        />
        <Column
          dataField={'com_tax_rate'}
          caption={t('tax_rate')}
          hidingPriority={9}
        />

        <Column
          dataField={'com_status'}
          caption={t('status')}
          hidingPriority={10}
          encodeHtml={false}
          calculateCellValue={
            function(data) {
              if(data.com_status === 1)
                  return `<i class='fas fa-check green-color'></i>`
              return `<i class='dx-icon dx-icon-close red-color'></i>`

          }
          }
        >
          
        </Column>
        <Column
          dataField={'com_note'}
          caption={t('note')}
          hidingPriority={11}
        />
         <Paging defaultPageSize={8} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={allowedPageSizes}
        />
      </DataGrid>
    </React.Fragment>
)}

export default withTranslation()(Companies);


const store  = new CustomStore({
  load: (loadOptions) => sendRequest(`${HOST_API}/v/companies`,loadOptions,'GET', null),
  insert: (values) => {
    let comIdCt = 0;
    $(".com_id_ct_clss :input").each(function(e){	
      companiesTypes.items().map( itemT => {
        if (itemT.ct_type === this.value) {
          comIdCt = itemT.ct_id_ct
        }
      })
    });
    return sendRequest(`${HOST_API}/companies`,null, 'POST', {...values, com_id_ct: comIdCt })
  },
  key: 'com_id_com',
  update: function(key, values){
      let comIdCt = 0;
      $(".com_id_ct_clss :input").each(function(e){	
        companiesTypes.items().map( itemT => {
          if (itemT.ct_type === this.value) {
            comIdCt = itemT.ct_id_ct
          }
        })
      });
      return  sendRequest(`${HOST_API}/companies/update`, null, 'POST', {...values, com_id_ct: comIdCt, com_id_com: key })
      
     //console.log(key, )
   // return sendRequest(`${HOST_API}/companies/update`, null,  'PUT',{...values, com_id_com:6})
  },
  remove: (key) => sendRequest(`${HOST_API}/companies`,null, 'DELETE', {
    key,
  }),
})

const   sendRequest = async (url, loadOptions = null, method = 'GET', data = {})  =>{
  //console.log("companiesTypes::",companiesTypes.items())
 logRequest(method, url, data);
//$('.com_id_ct_test :input');
 let token  = localStorage.getItem("token");
  if (method === 'GET') {
    let params = '?';
    [
      'skip',
      'take',
      'requireTotalCount',
      'requireGroupCount',
      'sort',
      'filter',
      'totalSummary',
      'group',
      'groupSummary',
    ].forEach((i) => {

      //console.log("i+++",i, loadOptions[i])
      if (i in loadOptions && isNotEmpty(loadOptions[i])) {
        params += `${i}=${JSON.stringify(loadOptions[i])}&`;

         }
    });
    params = params.slice(0, -1);
    return fetch(url+params, {
      method,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token
      }
    }).then((result) => result.json().then((json) => {
      console.log(json)
      if (result.ok) {
        return {
          data: json.data,
          totalCount: json.totalCount,
          summary: json.summary,
          groupCount: json.groupCount,
        };
      }
      throw json.Message;
    }));
  }

  // const params = Object.keys(data).map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`).join('&');
  if(method === "DELETE"){
    url = `${url}/${data.key}`
  }
  return fetch(url, {
    method,
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    }
  }).then((result) => {
    if (result.ok) {
      return result.text().then((text) => text && JSON.parse(text));
    }
    return result.json().then((json) => {
      throw json.Message;
    });
  });
}
const  logRequest = (method, url, data)  => {
  const args = Object.keys(data || {}).map((key) => `${key}=${data[key]}`).join(' ');

  const time = formatDate(new Date(), 'HH:mm:ss');
  const request = [time, method, url.slice(URL.length), args].join(' ');
  console.log(request)
}
/*
const store = new CustomStore({
  key: 'com_id_com',
  load(loadOptions) {
    let params = '?';
    [
      'skip',
      'take',
      'requireTotalCount',
      'requireGroupCount',
      'sort',
      'filter',
      'totalSummary',
      'group',
      'groupSummary',
    ].forEach((i) => {

      console.log("i+++",i, loadOptions[i])
      if (i in loadOptions && isNotEmpty(loadOptions[i])) {
        params += `${i}=${JSON.stringify(loadOptions[i])}&`;

         }
    });
    params = params.slice(0, -1);
    let token  = localStorage.getItem("token");
    console.log(`${HOST_API}/v/companies${params}`)
    return fetch(`${HOST_API}/v/companies${params}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token
      }
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("data:::", data)

        return {
          data: data.data,
          totalCount: data.totalCount,
          summary: data.summary,
          groupCount: data.groupCount,
        }
      })
      .catch(() => { throw new Error('Data Loading Error'); });
  },
});
*/
const allowedPageSizes = [8, 12, 20];


/*
const dataSource = {
  store: {
    type: 'odata',
    key: 'Task_ID',
    url: 'https://js.devexpress.com/Demos/DevAV/odata/Tasks'
  },
  select: [
    'com_id_com',
    'com_name',
    'com_ICE',
    'com_activity',
    'com_responsible',
    'com_phone',
    'com_email',
    'com_address',
    'com_city',
  ]
};*/

// const priorities = [
//   { name: 'High', value: 4 },
//   { name: 'Urgent', value: 3 },
//   { name: 'Normal', value: 2 },
//   { name: 'Low', value: 1 }
// ];
