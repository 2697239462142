import { levels } from "./contexts/levels";

const navigation = [
  {
    text: 'home',
    path: '/home',
    // icon: 'fa-solid fa-house',
    icon: 'home'
  },/*
  {
    text: 'companies',
    path: '/companies',
    icon: 'box'
  },
  {
    text: 'clients',
    path: '/clients',
    icon: 'product'
  },*/
  {
    text: 'companies',
    icon: 'product',
    items: [
      {
        text: 'companies',
        path: '/companies',
      },
      {
        text: 'clients',
        path: '/clients',
        // icon: 'fa-solid fa-user-tile',
      },
      {
        text: 'subcontractors',
        path: '/subcontractors'
      }
    ]
  },
  {
    text: 'personnel',
    path: '/personnel',
    icon: 'group'
  },
  {
    text: 'vehicles',
    path: '/vehicles',
    icon: 'fa-solid fa-bus-simple'
  },
  {
    text: 'pick-up points',
    path: '/pickuppoints',
    icon: 'fa-solid fa-location-dot'
  },
  {
    text: 'trips',
    path: '/trips',
    icon: 'fa-solid fa-route'
  },
  {
    text: 'gpsplatforms',
    path: '/gpsplatforms',
    icon: 'fa-solid fa-location-crosshairs'
  },
  {
    text: 'settings',
    icon: 'fa-solid fa-gear',
    items: [
      {
        text: 'profile',
        path: '/profile',
        icon: 'fa-solid fa-user',
      },/*
      {
        text: 'Tasks',
        path: '/tasks'
      }*/
    ]
  }
];

const lvl  = parseInt(window.localStorage.getItem("level")) || 0;
const routesWithLevels = navigation.map(route => {
  let lastRoute = null;
  if (route.path != undefined) {
    let level = levels.filter( l => l.path == route.path);
    if(level[0].level.includes(lvl) || level[0].level.includes(0))
      lastRoute = {...route}
  }else{
    let items = route.items.filter(item => {
      let level = levels.filter( l => l.path == item.path);
      if(level[0].level.includes(lvl) || level[0].level.includes(0))
        return {...item}
    })
    let lengthBefore = items.length;

    if (lengthBefore != 0) {
      lastRoute = {...route, items: items}
    }
  }
  return lastRoute;
})

//console.log("routesWithLevels:::",routesWithLevels)

export default routesWithLevels.filter(x => x !== null);
