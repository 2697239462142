import React from 'react';
import { withTranslation } from 'react-i18next';
import CustomStore from 'devextreme/data/custom_store';
import { GroupItem, SimpleItem ,Item } from 'devextreme-react/form';
import { sendRequest } from '../../utils/utils';
import { HOST_API } from "../../env";

import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  Lookup,
  Form,
  Toolbar,
  Editing,
  Popup,
  RequiredRule,
  PatternRule,
  MasterDetail
} from 'devextreme-react/data-grid';

function ClientSites({t, clientId}) {
  const GeoPointStore = new CustomStore({
    key: "gp_id_gp",
    loadMode: "raw",
    load: (loadOptions) => sendRequest(`${HOST_API}/v/geopoints`, loadOptions, 'GET', {skip:0, take:99999, requireTotalCount: "false", filter:['["cn_id_comcl","=","'+clientId+'"]'], sort: ['[{"selector":"gp_name","asc":true}]'] })
  });

  
  const SitesStore  = new CustomStore({
    key: 'cos_id_cos',
    load: (loadOptions) => sendRequest(`${HOST_API}/v/sites`,loadOptions,'GET',{filter: ['["cos_id_com","=",'+clientId+']'], sort: ['[{"selector":"cos_name","asc":true}]'] }),
    insert: (values) => {
      if(values.cos_status !== null && values.cos_status !== undefined ){
        values.cos_status = (values.cos_status === true) ? '1' : '0';
      }
      return sendRequest(`${HOST_API}/sites`, null, 'POST', {...values, cos_id_com:clientId })
    },
    update: function(key, values){
      if(values.cos_status !== null && values.cos_status !== undefined ){
        values.cos_status = (values.cos_status === true) ? '1' : '0';
      } 
      let res = sendRequest(`${HOST_API}/sites/update`, null, 'POST', {...values,cos_id_com:clientId,cos_id_cos:key})
      // console.log("res = " , res)
      // return "Error";
      return res;
    },
    remove: (key) => sendRequest(`${HOST_API}/sites`,null, 'DELETE', {
      key,
    }),
  })

  const prepareStatusValue = rowData => {
    return rowData.cos_status === '1' ? true : false;
  };

  return (
    <React.Fragment>
      <DataGrid
        className={'dx-card wide-card'}
        keyExpr="cos_id_cos"
        dataSource={SitesStore}
        showBorders={true}
        remoteOperations={false}
        focusedRowEnabled={true}
        // defaultFocusedRowIndex={0}
        rowAlternationEnabled={true}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        showColumnHeaders={true}
        >
        <Editing 
          mode="raw"
          allowAdding={true} 
          allowUpdating={true}
          allowDeleting={true}
          />
        <Paging defaultPageSize={10} />
        <Pager
          showInfo={true} 
          showPageSizeSelector={true}
          allowedPageSizes={[10, 50, 100]}
        />
        <Toolbar className="GridToolbar">
          <Item location="before">
            <h5>{t('sites')}</h5>
          </Item>
          <Item location="after" name="addRowButton"/>
          {/* <Item name="columnChooserButton"/> */}
        </Toolbar>
        <Column
          dataField={'cos_name'} 
          caption={t('site name')}
        />
        <Column
          dataField={'cos_id_gp'}
          caption={t('point name')}
          >
          <Lookup
            dataSource={GeoPointStore}
            displayExpr="gp_name"
            valueExpr="gp_id_gp"
          />
        </Column>
        {/*
        <Column
          dataField={'gp_district'}
          caption={t('district')}
        />
        */}
        <Column
          dataField={'cos_status'}
          caption={t('status')}
          encodeHtml={false}
          calculateCellValue={prepareStatusValue}
          editorType="dxCheckBox"
        />
      </DataGrid>
    </React.Fragment>
)}

export default withTranslation()(ClientSites);
