import React, { useMemo } from 'react';
import { useHistory } from "react-router-dom";
import ContextMenu, { Position } from 'devextreme-react/context-menu';
import List from 'devextreme-react/list';
import { useAuth } from '../../contexts/auth';
import { withTranslation } from 'react-i18next';
import './user-panel.scss';

function UserPanel({ t, menuMode }) {
  const { user, signOut } = useAuth();
  const history = useHistory();
 // let currentTheme = window.localStorage.getItem("dx-theme") || "material.orange.light";
  function navigateToProfile() {
    history.push("/profile");
  }
  
  const menuItems = useMemo(() => ([
    {
      text: t('profile'),
      icon: 'user',
      onClick: navigateToProfile
    },
    {
      text: t('logout'),
      icon: 'runner',
      onClick: signOut
    }
  ]), [signOut]);

/*
  let color = 'black';
  if(currentTheme === 'material.orange.dark'){
    color = 'white'
  }*/
  return (
    <div className={'user-panel'}>
      <div className={'user-info'}>
        <div className={'image-container'}>
          <div
            style={{
              background: `url(https://icon-library.com/images/free-user-icon/free-user-icon-21.jpg) no-repeat #fff`,
              backgroundSize: 'cover'
            }}
            className={'user-image'} />
        </div>
        <div className={'user-name'} >{user.firstname} {user.lastname}</div>
      </div>

      {menuMode === 'context' && (
        <ContextMenu
          items={menuItems}
          target={'.user-button'}
          showEvent={'dxclick'}
          width={210}
          cssClass={'user-menu'}
        >
          <Position my={'top center'} at={'bottom center'} />
        </ContextMenu>
      )}
      {menuMode === 'list' && (
        <List className={'dx-toolbar-menu-action'} items={menuItems} />
      )}
    </div>
  );
}

export default withTranslation ()(UserPanel);