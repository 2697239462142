import React, {useState} from 'react';
import "./SwitchSwitchCustomize.css"
const SwitchCustomize  = ()  =>{

  const [ currentTheme, setCurrentTheme] = useState(window.localStorage.getItem("dx-theme") || "material.orange.light.compact")
  const switchTheme = function(themeName) {
      window.localStorage.setItem("dx-theme", themeName);
      window.location.reload();
  }

  let  CurrentMode =  <a id="lightMode" role="button" onClick={() => switchTheme('material.orange.light.compact')} className="btn-customize light-mode-switch-customize"></a>
  
  if(currentTheme ==='material.orange.light.compact'){
    CurrentMode =  <a id="nightMode" role="button" onClick={() => switchTheme('material.orange.dark.compact')}  className="btn-customize night-mode-switch-customize"></a>
  }
  return <div className="p-2 switch-mode-customize ">
            {CurrentMode}
          </div>
}



export default SwitchCustomize