import '../../pages/home/home.scss';
import React from 'react';
import { withTranslation } from 'react-i18next';
import Toolbar from 'devextreme-react/toolbar';
import Clock from 'react-live-clock';
import Button from 'devextreme-react/button';
import UserPanel from '../user-panel/user-panel';
// import './header.scss';
import { Template } from 'devextreme-react/core/template';
import LanguageSelector from '../language-selector/language-selector'
import SwitchCustomize from "../switch-themes/SwitchSwitchCustomize";

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import GPSMap from '../../components/dashboard/gps-map/gps-map';
import ActivityOverviewChart from '../../components/dashboard/activity-overview-chart/activity-overview-chart';
import TripsTimelineChart from '../../components/dashboard/trips-timeline-chart/trips-timeline-chart';
import MTDTripsChart from '../../components/dashboard/trips-pie-chart/trips-pie-chart';


function Admin({ menuToggleEnabled, title, toggleMenu, t }) {
  return (
    <React.Fragment>
    <div className={'responsive-paddings'}>
      <ResponsiveBox
        singleColumnScreen="sm"
        // screenByWidth={screen}
        >
        <Row></Row>
        <Row></Row>
        <Row></Row>

        <Col></Col>
        <Col></Col>
        <Col></Col>
        <Col></Col>


        {/****  Notifications WIDGET ****/}
        <Item>
          <Location row={0} col={0} colspan={4} screen="lg"></Location>
          <Location row={0} col={0} colspan={2} screen="sm"></Location>
          <div>
            {/* <h2 className={'content-block'}>{t('home')}</h2> */}
          </div>
        </Item>
        {/* *************************** */}


        {/****  GPS MAP WIDGET ****/}
        <Item>
          <Location row={1} col={0} colspan={2} screen="lg"></Location>
          <Location row={2} col={0} screen="sm"></Location>
          <GPSMap/>
        </Item>
        {/* ********************** */}


        {/****  ACTIVITY OVERVIEW CHART WIDGET ****/}
        <Item>
          <Location row={1} col={2} colspan={2} screen="lg" ></Location>
          <Location row={2} col={1} screen="sm" ></Location>
          <ActivityOverviewChart/>
        </Item>
        {/* ************************************* */}


        {/****  TRIPS TIMELINE CHART WIDGET ****/}
        <Item>
          <Location row={2} col={0} colspan={3} screen="lg" ></Location>
          <Location row={3} col={0} colspan={2} screen="sm" ></Location>
          <TripsTimelineChart/>
        </Item>
        {/* ************************************* */}


        {/****  TRIPS BY CLIENT - MDT CHART WIDGET ****/}
        <Item>
          <Location row={2} col={3} screen="lg" ></Location>
          <Location row={3} col={0} screen="sm" ></Location>
          <MTDTripsChart/>
        </Item>
        {/*********************************************/}

      </ResponsiveBox>
    </div>

  </React.Fragment>
)}
export default withTranslation ()(Admin);
