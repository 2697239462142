import "./styles.scss";
import "leaflet/dist/leaflet.css";
import "leaflet-geosearch/dist/geosearch.css";
import "react-leaflet-fullscreen/dist/styles.css";
import "leaflet.locatecontrol/dist/L.Control.Locate.min.css";

import React, { lazy, useState, useRef } from 'react';
import { withTranslation } from 'react-i18next';
// import {ErrorBoundary} from 'react-error-boundary';

// import ReactDOM from 'react-dom';
import { MapContainer, TileLayer, Marker, useMap } from "react-leaflet";
import L, {LatLng, latLngBounds, FeatureGroup} from 'leaflet';
import MarkerClusterGroup from "react-leaflet-markercluster";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
import { FullscreenControl } from "react-leaflet-fullscreen";
import Locate from "leaflet.locatecontrol";
import { Button } from 'devextreme-react/button';
import { LoadPanel } from 'devextreme-react/load-panel';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import PickupPointQuickSelectComp from '../../components/PickupPoints/PickupPointQuickSelect';
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  Lookup,
  Toolbar,
  Form,
  Editing,
  RequiredRule,
  PatternRule,
  RemoteOperations
} from 'devextreme-react/data-grid';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import CustomStore from 'devextreme/data/custom_store';
// import DataSource from 'devextreme/data/data_source';
// import 'devextreme/data/odata/store';
import { GroupItem, SimpleItem} from 'devextreme-react/form';

// import 'devextreme-react/text-area';
import { HOST_API } from "../../env";
// import $ from "jquery";
import { sendRequest } from '../../utils/utils';


function ClientPickupPoints(props) {
  const {t, clientId, clientData} = props;
  const [isPPsPopupOpen, setPPsPopupVisibility] = useState(false);
  const DataGridRef = useRef(null);
  let LatEditor = useRef(null);
  let LngEditor = useRef(null);
  // let mapLoadPanel = useRef(null);

  let focusedRowChanged = false;

  const dxDateBoxOptions = {
    displayFormat: "dd/MM/yyyy",
    dateSerializationFormat: "yyyy-MM-dd"
  };

  let PickupPointsMap = null;
  let MapInitialized = false;
  let ClientPickupPointsStoreData = null;
  let PickupPointsMapMarkers = null;
  let MapOnEditMode = false;
  let GridMapSyncEventSource = null;

  const screen = (width) => {
    return (width < 700) ? 'sm' : 'lg';
  }

  var MarkerIcon = L.Icon.extend({
    options: {
      customId: "",
      iconUrl: '//raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
      shadowUrl: '//cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
      iconSize: [17, 28],
      iconAnchor: [8, 28],
      popupAnchor: [1, -34],
      shadowSize: [28, 28]
    }
  });

  let redMarker = new MarkerIcon({iconUrl: "//raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png"});
  let searchMarker = new MarkerIcon({iconUrl: "//raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-orange.png"});


  const ClientPickupPointsStore = new CustomStore({
    key: 'cp_id_cp',
    load: (loadOptions) => sendRequest(`${HOST_API}/v/contractspoints`, loadOptions, 'GET',{filter: ['["cn_id_comcl","=",'+clientId+'],["cp_status","=",1]'], sort: ['[{"selector":"gp_name","asc":true}]'] }),
    onLoaded: function (result) {
      ClientPickupPointsStoreData = result.data;
      bindPointsMarkers(result.data);
    },
    insert: (values) => {
      return sendRequest(`${HOST_API}/contractspoints`, null, 'POST', {...values, cn_id_comcl:clientId})
    },
    update: function(key, values){
      let res = sendRequest(`${HOST_API}/contractspoints/update`, null, 'POST', {...values, cp_id_cp:key, cn_id_comcl:clientId})
      return res;
    },
    remove: function(key){
      return sendRequest(`${HOST_API}/contractspoints`, null, 'DELETE', {key})
      return null;
    }
  })

  
  const InitializeMap = () => {
    // console.log("InitializeMap");
    const { t } = withTranslation();
    let mapContainer = document.getElementById('client_PP_Map_'+clientId);
    PickupPointsMapMarkers = L.markerClusterGroup();
    
    let attribution = '&copy; <a href="https://navetrack.com">NaveTrack</a>';
    let baseLayers = {
      "OSM Mapnik": L.tileLayer('//{s}.tile.osm.org/{z}/{x}/{y}.png', {attribution: attribution}),
      "Satellite ArcGIS": L.tileLayer("//server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}", {attribution: attribution}),
      // "Stadia Dark": L.tileLayer('//tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png', {attribution: attribution}),
      // "Landscape": L.tileLayer('//{s}.tile.thunderforest.com/landscape/{z}/{x}/{y}.png', {attribution: attribution}),
    };
    
    try {
      if (PickupPointsMap && PickupPointsMap.remove) {
        PickupPointsMap.off();
        PickupPointsMap.remove();
      }
    } catch (error) {
      console.log("Error: mapContainer appears to be not yet initialized");
    }
    
    PickupPointsMap = L.map(mapContainer, {
      fullscreenControl: true,
      attributionControl: false,
      center: [35.7545,-5.8324],
      zoom: 13,
      layers: [baseLayers["OSM Mapnik"]]
    });
    L.control.layers(baseLayers).addTo(PickupPointsMap);

    // PickupPointsMap.on('moveend zoomend', (e) => { 
    //   console.log("Map moveend/zoomend");
    // });
    
    // Setup Search option
    const search = new GeoSearchControl({
      provider: new OpenStreetMapProvider(),
        marker: {
          icon: searchMarker
        }
    });
    PickupPointsMap.addControl(search);
    
    // Setup GeoLocation option
    const locateOptions = {
      position: 'topleft', //topright
      strings: {
        title: 'Get my current location'
      },
      onActivate: () => {} 
    }
    PickupPointsMap.addControl(L.control.locate(locateOptions));
    
    // let mapLoadPanelObject = mapLoadPanel.current.instance;
    // mapLoadPanelObject.dispose();

    MapInitialized = true;
  }
  
  const bindPointsMarkers = markersData => {
    GridMapSyncEventSource = "Grid";
    let arrayOfLatLngs = [];
    if(MapInitialized === false || PickupPointsMap === null){
      InitializeMap()
    }
    try {
      PickupPointsMapMarkers.clearLayers();
    } catch (error) {
      InitializeMap();
    }
    PickupPointsMapMarkers.clearLayers();
    if(Object.keys(markersData).length == 0) return null;
    
    markersData.map((item, i) => {
      if(item.gp_lat !== null && item.gp_lng !== null){
        arrayOfLatLngs.push([item.gp_lat, item.gp_lng]);
        // L.marker([item.gp_lat, item.gp_lng], {icon: redMarker, title:item.gp_name}).addTo(PickupPointsMap).bindTooltip(item.gp_name);
        PickupPointsMapMarkers.addLayer(
          L.marker(
          [item.gp_lat, item.gp_lng],
          {
            customId:item.gp_id_gp,
            icon: redMarker,
            geofence:item.gp_geofence,
          })
          .on('click', markerOnClick)
          .bindPopup("<h6>"+item.gp_name+"</h6><ul>"+
          "<li><b>Description:</b> "+item.gp_description+"</li>"+
          "<li><b>District:</b> "+item.gp_district+", <b>City:</b> "+item.gp_city+"</li>"+
          "<li><b>GeoPoint:</b> "+item.gp_lat+", "+item.gp_lng+", <b>GeoFence:</b> "+item.gp_geofence+"</li>"+
          "<li><b>Last update:</b> "+item.gp_updated_at+"</li>"+
          "</ul>")
          .bindTooltip('<div class="FocusedMarkerTooltip">'+item.gp_name+'</div>')
        );
        // PickupPointsMapMarkers.addLayer(L.circle([item.gp_lat, item.gp_lng], 150, {color: 'blue',fillColor: 'blue'}));
      }
    });
    console.log("arrayOfLatLngs")
    console.log(arrayOfLatLngs)
    if(arrayOfLatLngs.length>0){
      let bounds = new L.LatLngBounds(arrayOfLatLngs);
      PickupPointsMap.addLayer(PickupPointsMapMarkers);
      // PickupPointsMap.fitBounds(bounds, {padding: [20, 20]});
      PickupPointsMap.flyToBounds(bounds, {padding: [20, 20], duration:0.30} );
    }
    PickupPointsMap.invalidateSize();
  }
  
  const markerOnClick = e => {
    let DataGridObject = DataGridRef.current.instance;
    DataGridObject.navigateToRow(e.target.options.customId);
    // DataGridObject.selectRows(e.target.options.customId, false);
    DataGridObject.option('focusedRowKey', e.target.options.customId)
  }
  
  const getMapClickLatLng = loc => {
    PickupPointsMapMarkers.clearLayers();
    PickupPointsMapMarkers.addLayer(L.marker([loc.latlng.lat, loc.latlng.lng], {icon: redMarker}));

    let DataGridObject = DataGridRef.current.instance;
    let rowKey = DataGridObject.option('focusedRowKey')
    if(rowKey !== null){
      let row_index = DataGridObject.getRowIndexByKey(rowKey);
      DataGridObject.cellValue(row_index, DataGridObject.getVisibleColumnIndex("gp_lat"), loc.latlng.lat);
      DataGridObject.cellValue(row_index, DataGridObject.getVisibleColumnIndex("gp_lng"), loc.latlng.lng);
    }else{
      LatEditor.setValue( loc.latlng.lat );
      LngEditor.setValue( loc.latlng.lng );
    }
  }
  
  const onInitNewRow = e => {
    let DataGridObject = DataGridRef.current.instance;
    DataGridObject.option('focusedRowKey', null)
    PrepareMapLocationPicker(e);
  }

  const PrepareMapLocationPicker = e => {
    PickupPointsMapMarkers.clearLayers();
    if(Object.keys(e.data).length > 0){
      bindPointsMarkers([e.data])
    }
    PickupPointsMap.on("click", getMapClickLatLng);
    L.DomUtil.addClass(PickupPointsMap._container, 'crosshair-cursor-enabled');

    MapOnEditMode = true;
  };

  const ResetMap = e => {
    if(MapOnEditMode === false) return null;
    PickupPointsMap.off('click', "");
    /*
    if (!L.Browser.touch) {
      L.DomEvent.disableClickPropagation(PickupPointsMap);
      L.DomEvent.on(PickupPointsMap, 'mousewheel', L.DomEvent.stopPropagation);
    } else {
      L.DomEvent.on(PickupPointsMap, 'click', L.DomEvent.stopPropagation);
    }
    */
    L.DomUtil.removeClass(PickupPointsMap._container, 'crosshair-cursor-enabled');
    bindPointsMarkers(ClientPickupPointsStoreData);
    MapOnEditMode = true;
  };
  
  const onRowClick = e => {
    if(!focusedRowChanged){
      const dataRow = e.data;
      var dataGrid = e.component;
      dataGrid.clearSelection();
      dataGrid.option("focusedRowIndex", -1);
    }
    focusedRowChanged = false;
  }

  const onFocusedRowChanged = e => {
    focusedRowChanged = true;
    GridMapSyncEventSource = "Grid";
    if(e.rowIndex >= 0){
      const dataRow = e.row && e.row.data;
      PickupPointsMapMarkers.eachLayer(function(layer){
        if(layer.options.customId === dataRow.gp_id_gp){
          let layerLatLng = [layer._latlng.lat, layer._latlng.lng];
          PickupPointsMap.flyTo(layerLatLng, 15, {duration:1} );
          setTimeout(() => {
            layer.openPopup()
          }, 1000);
        }else{
          layer.closePopup();
        }
      });
    }else{
      bindPointsMarkers(ClientPickupPointsStoreData); // to be optimized (a correct rezoom will be enough)
    }
  }

  const onEditorPrepared = e => {
    if(e.parentType ==="dataRow"){
      if(e.dataField === "gp_lat") LatEditor = e;
      else if(e.dataField === "gp_lng") LngEditor = e;
    }
  }
  
  const renderGeoData = data =>{
    return (
      <div id="map_set_point_message">
        Use Map to set point marker
      </div>
    );
  }
  
  const addPoint = () => {
    setPPsPopupVisibility(true);
  };
  
  const ParentCallBack = (response) => {
    // console.log("This is an alert from the Child Component")
    // console.log(response)
    setPPsPopupVisibility(false);
  }

  return (
    <React.Fragment>

      {
      isPPsPopupOpen ? 
        <PickupPointQuickSelectComp
          isPPsPopupOpen={isPPsPopupOpen}
          clientId={clientId}
          clientData={clientData}
          editMode={"full"}
          returnPPs={false}
          // callBack={ParentCallBack}
          callBack={ParentCallBack}
        />
      : null
      }

      <ResponsiveBox
        singleColumnScreen="sm"
        screenByWidth={screen}>
        <Row ratio={1.2} screen="xs"></Row>
        <Row ratio={2}></Row>
        <Col ratio={1}></Col>
        <Col ratio={2} screen="lg"></Col>
        <Item>
          <Location row={0} col={0} screen="lg"></Location>
          <Location row={1} col={0} screen="sm"></Location>
          <DataGrid
            className={'dx-card wide-card client_PP_Grid'}
            ref={DataGridRef}
            dataSource={ClientPickupPointsStore}
            cacheEnabled={false}
            showBorders={true}
            remoteOperations={true}
            focusedRowEnabled={true}
            // defaultFocusedRowIndex={0}
            // autoNavigateToFocusedRow={true}
            allowColumnResizing={true}
            showColumnLines={true}
            rowAlternationEnabled={true}
            // columnResizingMode={"widget"}
            // columnAutoWidth={true}
            columnHidingEnabled={true}
            allowColumnReordering={true}

            onInitNewRow={onInitNewRow}
            onEditingStart={PrepareMapLocationPicker}
            onRowInserted={ResetMap}
            onEditCanceled={ResetMap}
            onRowInserted={ResetMap}
            onRowUpdated={ResetMap}
            onRowRemoved={ResetMap}
            onRowUpdated={ResetMap}
            onEditorPrepared={onEditorPrepared}
            onRowClick={onRowClick}
            onFocusedRowChanged={onFocusedRowChanged}

            // onRowInserting={CheckMapUpdate}
            // onRowUpdating={updateRow}
            // onSaving={CheckMapUpdate2}
            // onSaved={CheckMapUpdate}
            // onRowClick={}
          >
            <Editing
              refreshMode="reshape"
              allowAdding={true}
              allowDeleting={true}
              >
              <Form>
                <GroupItem colCount={1} colSpan={2}>
                  <SimpleItem dataField="gp_name" validationRules />
                  <SimpleItem dataField="gp_description" />
                  <SimpleItem dataField="gp_address" />
                </GroupItem>
                <GroupItem colCount={2} colSpan={2}>
                  <SimpleItem dataField="gp_district" />
                  <SimpleItem dataField="gp_city"/>
                </GroupItem>
                <GroupItem colCount={3} colSpan={2}>
                  <Item dataField="gp_lat" editorOptions={{ readOnly: true }}/>
                  <SimpleItem dataField="gp_lng" editorOptions={{ readOnly: true }} />
                  <SimpleItem dataField="gp_geofence"/>
                </GroupItem>
                <GroupItem render={renderGeoData}></GroupItem>
              </Form>
            </Editing>
            {/* <RemoteOperations paging={true}/> */}
            {/* <Scrolling mode="virtual" rowRenderingMode="virtual" /> */}
            <Paging defaultPageSize={100}/>
            <Pager
              visible={true}
              displayMode="compact"
              showPageSizeSelector={true}
              showInfo={true}
              infoText={2}
              allowedPageSizes={[10, 25, 50, 100, 500, 1000]}/>
            {/* <HeaderFilter
              dataSource={customizeHeaderFilterData}
              visible={true}
              allowSearch={true}
              groupInterval={1000}
              /> */}
            <Toolbar className="GridToolbar">
                <Item location="after">
                  <Button icon="preferences" text={t("Manage")} onClick={addPoint}/>
                </Item>
            </Toolbar>
            <FilterRow visible={true} />
            <Column dataField={'gp_name'} caption={t('Point name')} width={200} >
              <RequiredRule />
            </Column>
            <Column dataField={'gp_description'} caption={t('Description')} hidingPriority={11} />
            <Column dataField={'gp_address'} caption={t('Address')} hidingPriority={8} />
            <Column dataField={'gp_district'} caption={t('District')} hidingPriority={10} />
            <Column dataField={'gp_city'} caption={t('City')} hidingPriority={9} />
            <Column dataField={'gp_lat'} caption={t('Latitude')} hidingPriority={7} editorOptions={{ visbible: false, hidden: true, style:{...{hidden: true}} }}/>
            <Column dataField={'gp_lng'} caption={t('Longitude')} hidingPriority={6} />
            <Column dataField={'gp_point'} caption={t('Geopoint')} visible={false} />
            <Column dataField={'gp_geofence'} caption={t('Geofence')} hidingPriority={1} />
            {/* <Column dataField={'gp_status'} caption={t('Status')} encodeHtml={false} calculateCellValue={prepareStatusValue} ></Column> */}
            <Column dataField={'gp_created_by'} caption={t('Created by')} visible={false} />
            <Column dataField={'gp_created_at'} caption={t('Created at')} visible={false} />
            <Column dataField={'gp_updated_by'} caption={t('Updated By')} visible={false} />
            <Column dataField={'gp_updated_at'} caption={t('Updated at')} visible={false} />

          </DataGrid>
        </Item>
        <Item>
          <Location row={0} col={1} screen="lg"></Location>
          <Location row={2} col={0} screen="sm"></Location>
          <div id={"client_PP_Map_"+clientId} className="dx-card client_PP_Map">
            {/* <LoadPanel
              ref={mapLoadPanel}
              position={{ of: '#client_PP_Map_'+clientId }}
              shading={true}
              shadingColor="rgba(0,0,0,0.4)"
              visible={true}
              /> */}
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
}
export default withTranslation()(ClientPickupPoints);