import React, { useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { sendRequest } from '../../utils/utils';
import { HOST_API } from "../../env";

import CustomStore from 'devextreme/data/custom_store';
import Accordion from 'devextreme-react/accordion';
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  Lookup,
  Form,
  Toolbar,
  Editing,
  Popup,
  RequiredRule,
  PatternRule,
  MasterDetail
} from 'devextreme-react/data-grid';

import TagBox from 'devextreme-react/tag-box';
import SelectBox from 'devextreme-react/select-box';
import FileUploader from 'devextreme-react/file-uploader';
import Button from 'devextreme-react/button';
import TextBox from 'devextreme-react/text-box';
import { CheckBox } from 'devextreme-react/check-box';
import notify from 'devextreme/ui/notify';
import $ from "jquery";

function PersonnelUploader(props) {
  const{t, clientId, clientData} = props;
  console.log("clientId ==> ",clientId);
  console.log("clientData ==> ",clientData);

	// const [ShowCompany] = useState(false);

  let FileUploaderRef = useRef(null);
  let TagBoxRef = useRef(null);
  let selectBoxRef = useRef(null);
  let selectedFileColumns = [];
  let uploaderCustomData = {
    p_id_com: null,
    p_id_pl: 10,
    columns: "",
    ignore_first_line: "0",
    setUsername: "0"
  };
  
  const onClick = () => {
    console.log("onClick")
    uploaderCustomData.columns = "";
    let FileUploaderObject = FileUploaderRef.current.instance;
    FileUploaderObject.reset()
    FileUploaderObject.option("readOnly", true)
    let TagBoxObj = TagBoxRef.current.instance;
    TagBoxObj.reset()
    let selectBoxObj = selectBoxRef.current.instance;
    selectBoxObj.reset()
  }
  
  const FileColumns = [
    {
    //   Id: "p_full_name",
    //   Name: t('full name'),
    // }, {
      Id: "p_first_name",
      Name: t('first name'),
    }, {
      Id: "p_last_name",
      Name: t('last name'),
    }, {
      Id: "p_phone",
      Name: t('phone'),
    }, {
      Id: "p_address",
      Name: t('address'),
    }, {
      Id: "p_district",
      Name: t('district'),
    }, {
      Id: "p_city",
      Name: t('city'),
    }, {
      Id: "u_username",
      Name: t('login'),
    }, {
      Id: "password",
      Name: t('password'),
    }, {
      Id: "skip1",
      Name: t('skip'),
    }, {
      Id: "skip2",
      Name: t('skip'),
    }, {
      Id: "skip3",
      Name: t('skip'),
    }
  ]
  
  const onValueChanged = (e) => {
    uploaderCustomData.columns = "";
    let values = e.value;
    let FileUploaderObject = FileUploaderRef.current.instance;
    if(values.length === 0){
      FileUploaderObject.reset()
      FileUploaderObject.option("readOnly", true)
    }else{
      FileUploaderObject.option("readOnly", false)
      uploaderCustomData.columns = values.join(',');
      FileUploaderObject.option("uploadCustomData", uploaderCustomData);
    }
  }
  
  const onCompanyChanged = (e) => {
    uploaderCustomData.p_id_com = e.value;
  }

  // const uploaderGetCustomData = (e) => {
  //   console.log("uploaderGetCustomData")
  //   console.log(uploaderCustomData)
  //   return uploaderCustomData;
  // }

  const onBeforeSend = (e) => {
    console.log("onBeforeSend")
    console.log(e)
    console.log(uploaderCustomData.columns)
    if(uploaderCustomData.columns === ''){
      let FileUploaderObject = FileUploaderRef.current.instance;
      console.log("uploaderCustomData.columns empty")
      // FileUploaderObject.abortUpload();
      // e.request.abort();
    }
    // console.log(e)
    // e.request.withCredentials = true;
  };

  const onContentReady = (e) => {
    console.log("onContentReady")
    console.log(e)
    // let FileUploaderObject = FileUploaderRef.current.instance;
    // FileUploaderObject.option("readOnly", true)

    
    // let button = e.element.find(".dx-fileuploader-upload-button").instance;
    // console.log(button)
    // button.option("onClick", function(){ alert("Cutom handler!") });

  };

  const onUploadError = (e) => {
    console.log("onUploadError")
    console.log(e)
  };
  
  // {"status":false,"code":"Excel : 1018","message":"Excel :Columns not matching with [p_first_name]"}
  // {"success":true,"code":200,"message":{"message":"data has been successfuly inserted","data":[{"p_first_name":"ans2","u_username":"ans2140561","password":"XhhdvALf"},{"p_first_name":"ans3","u_username":"ans2140423","password":"kN4zBUz1"}]}}
  const convertToTabCsv = (arr) => {
    const keys = Object.keys(arr[0]);
    let headers = keys.join('\t');
    for (const [key, value] of Object.entries(FileColumns)) {
      headers = headers.replace(value.Id, value.Name)
    }
    const replacer = (_key, value) => value === null ? '' : value;
    const processRow = row => keys.map(key => row[key]).join('\t');
    return [ headers, ...arr.map(processRow) ].join("\r\n");
  };

  const onUploaded = (e) => {
    //uploadedMessage = "hello test" ;
    console.log("onUploaded")
    console.log(e)
    console.log(e.request.responseText);
    
    var statusMessageObject = $('.dx-fileuploader-file')
    .find('.dx-fileuploader-file-status-message');
    // .has('.dx-fileuploader-file-name:contains("' + e.file.name + '")')
    
    let response = JSON.parse(e.request.responseText);
    
    e.message = response.message;
    if(response.status === false){
      statusMessageObject.css('color', 'red');
    }else if(response.success === true){
      e.message = response.message.message;
      statusMessageObject.css('color', 'green');
      console.log("data-Upload");

      console.log(response.message.data);

      // const csvReport = {
      //   data: response.message.data,
      //   headers: uploaderCustomData.columns = values.join(','),
      //   filename: 'Personnels.csv'
      // };
    let downloadData = convertToTabCsv(response.message.data);
    // FileColumns
    console.log(downloadData)
    console.log(encodeURIComponent(downloadData))
    var link = document.createElement('a');
    // link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(downloadData));
    link.setAttribute('href', 'data:application/vnd.ms-excel,' + escape(downloadData));
    // var url = 'data:application/vnd.ms-excel,' + escape(downloadData); // Set your html table into url 
    link.setAttribute('download', 'users_Personnels.xls');
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

      /*
      link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(data));
      const url = window.URL.createObjectURL(new Blob([response.message.data])) 
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', "yourfilename.csv")
      document.body.appendChild(link)
      link.click()
      */

    }else if(response.success === false){
      statusMessageObject.css('color', 'red');
    }    
  }
  
  const uploadedMessage = (e) => {
    return "test ...."; 
  }
  // const uploaderCustomData = {
  //   p_id_com: clientId,
  //   p_id_pl: 10,
  //   // columns: "test,"+JSON.stringify(selectedFileColumns)
  // };
  
  const onIgnoreFirstLineValue = (e) => {
    let uploaderValue = 0;
    if(e.value === true)
      uploaderValue = 1;
    console.log("uploaderValue : ",uploaderValue);
    uploaderCustomData.ignore_first_line = uploaderValue;
    // uploaderCustomData.ignore_first_line = e.value;
    let FileUploaderObject = FileUploaderRef.current.instance;
    FileUploaderObject.option("uploadCustomData", uploaderCustomData);
  };

  const onAddLoginValue = (e) => {
    let uploaderValue = 0;
    if(e.value === true)
      uploaderValue = 1;
    console.log("AddLoginValue : ",uploaderValue);
    uploaderCustomData.setUsername = uploaderValue;
    // uploaderCustomData.ignore_first_line = e.value;
    let FileUploaderObject = FileUploaderRef.current.instance;
    FileUploaderObject.option("uploadCustomData", uploaderCustomData);
  };

  const uploaderHeaders = {
    Authorization: "Bearer " + localStorage.getItem("token")
  };
  
  const fileExtensions = ['.xlsx', '.xls', '.csv'/*, '.txt'*/];

  const CompaniesStore = new CustomStore({
    key: "com_id_com",
    loadMode: "raw",
    load: (loadOptions) =>  sendRequest(`${HOST_API}/v/companies`, loadOptions, 'GET', {skip:0, take:99999, requireTotalCount: "false",/* filter:['["ct_type","!=","client"]'],*/ sort: ['[{"selector":"com_name","asc":true}]'] })
  });

	let ShowCompanies = true;
	if(!isNaN(clientId)){
		ShowCompanies = false;
	}

  const CustomItem = () => {
    return (
      <form id="form" /*ref={this.formElement}*/ method="post" action="" encType="multipart/form-data" >
        <div className="dx-fieldset">
       
					{
          ShowCompanies ? 
            <div className="">
              <div>{t('company')}</div>
              <SelectBox dataSource={CompaniesStore}
                displayExpr="com_name"
                valueExpr="com_id_com"
                onValueChanged={onCompanyChanged}
                ref={selectBoxRef}
                />
            </div>
          : null
          }

          <div className="">
            <div>{t('fields in file')}</div>
            <TagBox dataSource={FileColumns}
              displayExpr="Name"
              valueExpr="Id"
              multiline={true}
              onValueChanged={onValueChanged}
              ref={TagBoxRef}
              />
            <div>                
              <CheckBox className="ignoreFirstLine" defaultValue={false} onValueChanged={onIgnoreFirstLineValue} text={t('ignore first line')} />
            </div>
            <div>                
              <CheckBox className="ignoreFirstLine" defaultValue={false} onValueChanged={onAddLoginValue} text={t('add login')} />
            </div>
          </div>
          
          <div className="">
            <FileUploader
              id="FileUploader"
              name="file"
              ref={FileUploaderRef}
              multiple={false}
              selectButtonText={t('select file')}
              uploadMode="useButtons"
              // onBeforeSend={onBeforeSend}
              uploadUrl={`${HOST_API}/personnels/import`}
              uploadHeaders={uploaderHeaders}
              labelText=""
              onContentReady={onContentReady}
              onUploaded={onUploaded}
              // uploadedMessage={}
              onUploadError={onUploadError}
              /*maxFileSize={4000000}/*4 MB*/ 
              allowedFileExtensions={fileExtensions}
              invalidFileExtensionMessage={t('file type is not allowed')}
              // invalidMaxFileSizeMessage="File is too large"
              // invalidMinFileSizeMessage="File is too small"
            />
          </div>
          <div id="Notif-Resp" className="dx-field-label"></div>
          <Button text={t('reset')} onClick={onClick} style={{ float: 'right' }}/> 
        </div>
      </form>
    );
  }

  return (
    <React.Fragment>
      {/* <div><br/><br/></div> */}
      <Accordion
        className={"TeamsShiftsAccordion"}
        dataSource={[{ID: 1}]}
        collapsible={true}
        //animationDuration={animationDuration}
        itemTitleRender={e =>{
          return <span>{t('import file')}</span>
        }}
        itemRender={CustomItem}
        onContentReady={e => {
          e.component.collapseItem(0);
        }}
      />
    </React.Fragment>
  )
}
export default withTranslation()(PersonnelUploader);