export const levels = [
    {
        level:[0],
        path: '/home',
    },
    {
        level:[0],
        path: '/profile',
    },
    {
        level:[1],
        path: '/companies',
    },
    {
        level:[1,2,3],
        path: '/clients',
    },
    {
        level:[1,2,3],
        path: '/subcontractors',
    },
    {
        level:[1,2,3,5,6,8,9],
        path: '/personnel',
    },
    {
        level:[1,2,3,5,6],
        path: '/vehicles',
    },
    {
        level:[1,2,5],
        path: '/geopoints',
    },
    {
        level:[1,2,5],
        path: '/pickuppoints',
    },
    {
        level:[1,2,3,4,5,6,7,8,9],
        path: '/trips',
    },
    {
        level:[1,2,5],
        path: '/gpsplatforms',
    }
];

/**
 * 
 * level 0  ==> all levels

+-------------+------------------+----------+-------------+
| u_username  | ct_type          | pl_id_pl | pl_level    |
+-------------+------------------+----------+-------------+
| super_admin | ADMIN            |        1 | super_admin |
| sp_admin    | service provider |        2 | admin       |
| sp_resp     | service provider |        3 | responsible |
| sp_driver2  | service provider |        4 | driver      |
| sb_admin    | subcontractors   |        5 | admin       |
| sb_resp     | subcontractors   |        6 | responsible |
| sb_driver   | subcontractors   |        7 | driver      |
| opm_admin   | client           |        8 | admin       |
| opm_resp    | client           |        9 | responsible |
| opm_user    | client           |       10 | personnel   |
+-------------+------------------+----------+-------------+
 * 
 */

