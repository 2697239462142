import './styles.scss';
import React, {useEffect, useState} from 'react';
import { withTranslation } from 'react-i18next';

import Chart, {
  ArgumentAxis,
  CommonSeriesSettings,
  ConstantLine,
  Export,
  Grid,
  Label,
  Legend,
  MinorTick,
  LoadingIndicator,
  Point,
  Series,
  SeriesTemplate,
  Size,
  Strip,
  StripStyle,
  Tick,
  Title,
  Tooltip,
  ValueAxis,
} from 'devextreme-react/chart';


import { Item } from 'devextreme-react/responsive-box';

import DateBox from 'devextreme-react/date-box';
// import Toolbar from 'devextreme-react/toolbar';
// import CustomStore from 'devextreme/data/custom_store';
// import { activityOverviewData } from './test_data.js';
import { formatDate } from 'devextreme/localization';

import { HOST_API } from "../../../env";
import { sendRequest } from '../../../utils/utils';

import CustomStore from 'devextreme/data/custom_store';
// import DataSource from 'devextreme/data/data_source';

import { TripsTimelineData } from '../../../pages/home/test_data.js';

function TripsTimelineChart({t}) {
  // const [activeMonth, setActiveMonth] = useState(0);
  
  const currentDate = new Date();
  const testDate = new Date(2022, 2, 27, 16, 4, 0)

  useEffect(() => {
    // setActiveMonth(formatDate(testDate, "yyyy-MM"));
  }, []);
  
  // const chartDataSource = new CustomStore({
  //   load: (loadOptions) => sendRequest(`${HOST_API}/v/fuelActivity/${activeMonth}`, loadOptions, 'GET'),
  //   onLoaded: function (result) {
  //     // clientTripDetailsStoreData = result.data;
  //   }
  // })

  // const onMonthValueChanged = (data) => {
  //   setActiveMonth(formatDate(data.value, "yyyy-MM"));
  // };

  function customizeTimelineChartSeries(valueFromNameField) {
    let color = '#76adec';
    if(valueFromNameField === 'planned') color = '#ffc493';
    if(valueFromNameField === 'SYS') color = 'transparent';
    return { color: color }
    // return valueFromNameField === 'confirmed' ? { color: '#76adec' } : {color: '#ffc493'};
  }
  
  // function TimelineChartTooltip(info) {
  const TimelineChartTooltip = (info) => {
    if(info.seriesName === 'SYS') return false;
    // console.log(info.point.data);
    const rangeValue1 = formatDate(info.rangeValue1, "shortTime");
    const rangeValue2 = formatDate(info.rangeValue2, "shortTime");
    const status = info.seriesName;
    // console.log("rangeValue1:"+rangeValue1+"   -  rangeValue2:"+rangeValue2+"  -  status:"+status);
    return (
      <div className="trips-timeline-tooltip">
        <div className="tripVehicle">
          <span className="tooltip-label">{t('vehicle')}: </span>
          <span>{info.point.data.vehicle}</span>
        </div>
        <div className="tripClient">
          <span className="tooltip-label">{t('client')}: </span>
          <span>{info.point.data.client}</span>
        </div>
        <div className="tripDirection">
          <span className="tooltip-label">{t('direction')}: </span>
          <span>{info.point.data.direction}</span>
        </div>
        <div className="tripStart">
          <span className="tooltip-label">{t('start')}: </span>
          <span>{rangeValue1}</span>
        </div>
        <div className="tripEnd">
          <span className="tooltip-label">{t('end')}: </span>
          <span>{rangeValue2}</span>
        </div>
        <div className="tripStatus">
          <span className="tooltip-label">{t('status')}: </span>
          <span className={status}>{status}</span>
        </div>
      </div>
    );
  }
  
  const onDrawn = (e) =>{
    console.log("onDrawn");
    console.log(e)
  }

  return (
    <React.Fragment>
      <div id="TripsTimelineChartContainer" className={'dx-card responsive-paddings'}>
        <Chart
          id="TripsTimelineChart"
          dataSource={TripsTimelineData}
          title={t('today trips timeline')}
          rotated={true}
          hoverMode="onlyPoint"
          cornerRadius="10"
          onDrawn={onDrawn}
          >
          <LoadingIndicator enabled={true} />
          <Size height={250}/>
          <ValueAxis >
            <Tick visible={true} />
            <MinorTick visible={true} />
            <ConstantLine width={2} color="#ffc493" dashStyle="dash" value={new Date(2022, 2, 27, 6, 0, 0)} />
            <ConstantLine width={2} color="#ffc493" dashStyle="dash" value={new Date(2022, 2, 27, 14, 0, 0)} />
            <ConstantLine width={2} color="#ffc493" dashStyle="dash" value={new Date(2022, 2, 27, 22, 0, 0)} />
            {/* <ConstantLine width={2} color="#ff9564" dashStyle="solid" value={new Date(2022, 2, 27, 19, 20, 0)} /> */}
            {/* <Strip endValue={testDate} color="#d6e9ffb9"/> */}
          </ValueAxis>
          <CommonSeriesSettings
            type="rangeBar"
            argumentField="vehicle"
            rangeValue1Field="start"
            rangeValue2Field="end"
            clientField="client"
            barOverlapGroup="vehicle"
          >
          </CommonSeriesSettings>
          <Legend visible={false}/>
          <SeriesTemplate nameField="status" customizeSeries={customizeTimelineChartSeries}/>
          
          <Tooltip
            enabled={true}
            contentRender={TimelineChartTooltip}
          />
        </Chart>

      </div>
    </React.Fragment>
  )
}

export default withTranslation()(TripsTimelineChart);