import React /*, { useState, useRef }*/ from 'react';
import { withTranslation } from 'react-i18next';
// import {ErrorBoundary} from 'react-error-boundary'
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  Popup,
  // Lookup,
  Form,
  Editing,
  RequiredRule,
  PatternRule,
  MasterDetail
} from 'devextreme-react/data-grid';
// Popup,
// import { Popup, Position } from 'devextreme-react/popup';
import { TabPanel, Item } from 'devextreme-react/tab-panel';
// import { formatDate } from 'devextreme/localization';

import 'devextreme/data/odata/store';
import CustomStore from 'devextreme/data/custom_store';
// import DataSource from 'devextreme/data/data_source';

import {/*logRequest, isNotEmpty,*/ sendRequest} from '../../utils/utils';
import { HOST_API } from "../../env";

// import $ from "jquery";

import GpsDevice from '../../components/device/index';

function GPSPlatforms({t}) {
  const GpsStore  = new CustomStore({
    key: 'gpl_id_gpl',
    load: (loadOptions) =>{
      //console.log("GPSPlatforms::loadOptions",loadOptions)
      loadOptions = {}
      return  sendRequest(`${HOST_API}/v/gpsplatform`, loadOptions, 'GET', {})
    },
    insert: (values) => {  
      return sendRequest(`${HOST_API}/gpsplatform`,null, 'POST', {...values})
    },
    update: function(key, values){
      
      return  sendRequest(`${HOST_API}/gpsplatform/update`, null, 'POST', {...values, gpl_id_gpl: key })
    },
    remove: (key) => sendRequest(`${HOST_API}/gpsplatform`,null, 'DELETE', {
      key,
    }),
  })

  const prepareStatusValue = rowData => {
    return rowData.gpl_status === 1 ? true : false;
  };
  
  const GpsDetails = (e) => {
    return (
      <TabPanel>
        <Item title={t('gpsdevices')} icon="fa-solid fa-bus-simple">
          <GpsDevice clientId={e.data.key} clientData={e.data} />
        </Item>
      </TabPanel>
    );
  }
  
  return (
    <React.Fragment>
      <h2 className={'content-block'}>{t('gpsplatforms')}</h2>
      <DataGrid
        className={'dx-card wide-card'}
        keyExpr="gpl_id_gpl"
        dataSource={GpsStore}
        cacheEnabled={false}
        showBorders={true}
        remoteOperations={true}
        focusedRowEnabled={true}
        // defaultFocusedRowIndex={0}
        rowAlternationEnabled={true}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        allowColumnReordering={true}
        allowColumnResizing={true}

        onRowExpanding={function(e){
          e.component.collapseAll(-1);
        }}
        onRowDblClick={function(e){
          // e.component.collapseAll(-1);
          // e.component.expandRow(e.key);
        }}
        >
        <MasterDetail
          enabled={true}
          component={GpsDetails}
        />
        <Editing
          mode="popup"
          allowAdding={true}
          allowUpdating={true}
          allowDeleting={true}>
          <Popup title={t('info')} showTitle={true} shading={true} resizeEnabled={true} dragEnabled={true}  />
          <Form>
            <Item itemType="group" colCount={2} colSpan={2}>
              <Item dataField="gpl_name" caption="" validationRules />
              <Item dataField="gpl_uid" />
              <Item dataField="gpl_type" />
              <Item dataField="gpl_auth_url" />
              <Item dataField="gpl_api_multi_keys" />
              <Item dataField="gpl_api_main_url" />
              <Item dataField="com_status" editorType="dxCheckBox" verticalAlignment={false} />
            </Item>
            
            <Item itemType="group" caption={t('credentials')} colCount={2} colSpan={2}>
              <Item dataField="gpl_auth_login" />
              <Item dataField="gpl_auth_pass" />
            </Item>
            
            <Item itemType="group" caption={t('company_address')} colCount={2} colSpan={2}>
              <Item dataField="gpl_contact_email" />
              <Item dataField="gpl_tracking_status" />
            </Item>
          </Form>
        </Editing>
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        <FilterRow visible={true} />
        <Column
          dataField={'gpl_name'}
          width={190}
          caption={t('name')}
          sortIndex={0}
          sortOrder="asc"
        >
          <RequiredRule />
          <PatternRule pattern={/^\s*(?:\S\s*){3,100}$/} message={t('please_Enter_a_valid_name')} />
        </Column>
        <Column
          dataField={'gpl_type'}
          caption={t('type')}
          hidingPriority={0}
        />
        <Column
          dataField={'gpl_auth_url'}
          caption={t('auth_url')}
          hidingPriority={8}
        />
        <Column
          dataField={'gpl_auth_login'}
          caption={t('login')}
          allowSorting={false}
          hidingPriority={1}
        />
        <Column
          dataField={'gpl_auth_pass'}
          caption={t('password')}
          hidingPriority={7}
          rtlEnabled={true}
        />
        <Column
          dataField={'gpl_api_main_url'}
          caption={t('api_main_url')}
          hidingPriority={9}
        />
        <Column
          dataField={'gpl_contact_email'}
          caption={t('email')}
          hidingPriority={6}
        />
        <Column 
          dataField={'gpl_status'}
          caption={t('status')}     
          visible={true}  
          hidingPriority={10} 
          encodeHtml={false} 
          calculateCellValue={prepareStatusValue} 
        /> 
        <Paging defaultPageSize={8} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={10,20,50,100}
        />
      </DataGrid>
    </React.Fragment>
)}

export default withTranslation()(GPSPlatforms);






/*import React from 'react';
import { withTranslation } from 'react-i18next';


function GPSPlatforms({t}) {

  return (
    <React.Fragment>
      <h2 className={'content-block'}>{t('gpsplatforms')}</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>

          <p>GPS Platform.</p>

        </div>
      </div>
    </React.Fragment>
)}

export default withTranslation ()(GPSPlatforms);




import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import {
  SortingState, EditingState, PagingState, SummaryState,
  IntegratedPaging, IntegratedSorting, IntegratedSummary,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table, TableHeaderRow, TableEditRow, TableEditColumn,
  PagingPanel, DragDropProvider, TableColumnReordering,
  TableFixedColumns, TableSummaryRow,
} from '@devexpress/dx-react-grid-material-ui';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TableCell from '@mui/material/TableCell';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/material/styles';

import { ProgressBarCell } from '../../../theme-sources/material-ui/components/progress-bar-cell';
import { HighlightedCell } from '../../../theme-sources/material-ui/components/highlighted-cell';
import { CurrencyTypeProvider } from '../../../theme-sources/material-ui/components/currency-type-provider';
import { PercentTypeProvider } from '../../../theme-sources/material-ui/components/percent-type-provider';

import {
  generateRows,
  globalSalesValues,
} from '../../../demo-data/generator';

const PREFIX = 'Demo';
const classes = {
  lookupEditCell: `${PREFIX}-lookupEditCell`,
  dialog: `${PREFIX}-dialog`,
  inputRoot: `${PREFIX}-inputRoot`,
  selectMenu: `${PREFIX}-selectMenu`,
};
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${classes.lookupEditCell}`]: {
    padding: theme.spacing(1),
  },
  [`& .${classes.dialog}`]: {
    width: 'calc(100% - 16px)',
  },
  [`& .${classes.inputRoot}`]: {
    width: '100%',
  },
  [`& .${classes.selectMenu}`]: {
    position: 'absolute !important',
  },
}));

const AddButton = ({ onExecute }) => (
  <div style={{ textAlign: 'center' }}>
    <Button
      color="primary"
      onClick={onExecute}
      title="Create new row"
    >
      New
    </Button>
  </div>
);

const EditButton = ({ onExecute }) => (
  <IconButton onClick={onExecute} title="Edit row" size="large">
    <EditIcon />
  </IconButton>
);

const DeleteButton = ({ onExecute }) => (
  <IconButton
    onClick={() => {
      // eslint-disable-next-line
      if (window.confirm('Are you sure you want to delete this row?')) {
        onExecute();
      }
    }}
    title="Delete row"
    size="large"
  >
    <DeleteIcon />
  </IconButton>
);

const CommitButton = ({ onExecute }) => (
  <IconButton onClick={onExecute} title="Save changes" size="large">
    <SaveIcon />
  </IconButton>
);

const CancelButton = ({ onExecute }) => (
  <IconButton color="secondary" onClick={onExecute} title="Cancel changes" size="large">
    <CancelIcon />
  </IconButton>
);

const commandComponents = {
  add: AddButton,
  edit: EditButton,
  delete: DeleteButton,
  commit: CommitButton,
  cancel: CancelButton,
};

const Command = ({ id, onExecute }) => {
  const CommandButton = commandComponents[id];
  return (
    <CommandButton
      onExecute={onExecute}
    />
  );
};

const availableValues = {
  product: globalSalesValues.product,
  region: globalSalesValues.region,
  customer: globalSalesValues.customer,
};

const LookupEditCell = ({
  availableColumnValues, value, onValueChange,
}) => (
  <StyledTableCell
    className={classes.lookupEditCell}
  >
    <Select
      value={value}
      onChange={event => onValueChange(event.target.value)}
      MenuProps={{
        className: classes.selectMenu,
      }}
      input={(
        <Input
          classes={{ root: classes.inputRoot }}
        />
      )}
    >
      {availableColumnValues.map(item => (
        <MenuItem key={item} value={item}>
          {item}
        </MenuItem>
      ))}
    </Select>
  </StyledTableCell>
);

const Cell = (props) => {
  const { column } = props;
  if (column.name === 'discount') {
    return <ProgressBarCell {...props} />;
  }
  if (column.name === 'amount') {
    return <HighlightedCell {...props} />;
  }
  return <Table.Cell {...props} />;
};

const EditCell = (props) => {
  const { column } = props;
  const availableColumnValues = availableValues[column.name];
  if (availableColumnValues) {
    return <LookupEditCell {...props} availableColumnValues={availableColumnValues} />;
  }
  return <TableEditRow.Cell {...props} />;
};

const getRowId = row => row.id;

export default () => {
  const [columns] = useState([
    { name: 'product', title: 'Product' },
    { name: 'region', title: 'Region' },
    { name: 'amount', title: 'Sale Amount' },
    { name: 'discount', title: 'Discount' },
    { name: 'saleDate', title: 'Sale Date' },
    { name: 'customer', title: 'Customer' },
  ]);
  const [rows, setRows] = useState(generateRows({
    columnValues: { id: ({ index }) => index, ...globalSalesValues },
    length: 12,
  }));
  const [tableColumnExtensions] = useState([
    { columnName: 'product', width: 200 },
    { columnName: 'region', width: 180 },
    { columnName: 'amount', width: 180, align: 'right' },
    { columnName: 'discount', width: 180 },
    { columnName: 'saleDate', width: 180 },
    { columnName: 'customer', width: 200 },
  ]);
  const [sorting, getSorting] = useState([]);
  const [editingRowIds, getEditingRowIds] = useState([]);
  const [addedRows, setAddedRows] = useState([]);
  const [rowChanges, setRowChanges] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(0);
  const [pageSizes] = useState([5, 10, 0]);
  const [columnOrder, setColumnOrder] = useState(['product', 'region', 'amount', 'discount', 'saleDate', 'customer']);
  const [currencyColumns] = useState(['amount']);
  const [percentColumns] = useState(['discount']);
  const [leftFixedColumns] = useState([TableEditColumn.COLUMN_TYPE]);
  const [totalSummaryItems] = useState([
    { columnName: 'discount', type: 'avg' },
    { columnName: 'amount', type: 'sum' },
  ]);

  const changeAddedRows = value => setAddedRows(
    value.map(row => (Object.keys(row).length ? row : {
      amount: 0,
      discount: 0,
      saleDate: new Date().toISOString().split('T')[0],
      product: availableValues.product[0],
      region: availableValues.region[0],
      customer: availableValues.customer[0],
    })),
  );

  const deleteRows = (deletedIds) => {
    const rowsForDelete = rows.slice();
    deletedIds.forEach((rowId) => {
      const index = rowsForDelete.findIndex(row => row.id === rowId);
      if (index > -1) {
        rowsForDelete.splice(index, 1);
      }
    });
    return rowsForDelete;
  };

  const commitChanges = ({ added, changed, deleted }) => {
    let changedRows;
    if (added) {
      const startingAddedId = rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
      changedRows = [
        ...rows,
        ...added.map((row, index) => ({
          id: startingAddedId + index,
          ...row,
        })),
      ];
    }
    if (changed) {
      changedRows = rows.map(row => (changed[row.id] ? { ...row, ...changed[row.id] } : row));
    }
    if (deleted) {
      changedRows = deleteRows(deleted);
    }
    setRows(changedRows);
  };

function GPSPlatforms({t}) {

  return (
    <React.Fragment>
      <h2 className={'content-block'}>{t('gpsplatforms')}</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>

          <p>GPS Platform.</p>
          <Paper>
          <Grid
            rows={rows}
            columns={columns}
            getRowId={getRowId}
          >
            <SortingState
              sorting={sorting}
              onSortingChange={getSorting}
            />
            <PagingState
              currentPage={currentPage}
              onCurrentPageChange={setCurrentPage}
              pageSize={pageSize}
              onPageSizeChange={setPageSize}
            />
            <EditingState
              editingRowIds={editingRowIds}
              onEditingRowIdsChange={getEditingRowIds}
              rowChanges={rowChanges}
              onRowChangesChange={setRowChanges}
              addedRows={addedRows}
              onAddedRowsChange={changeAddedRows}
              onCommitChanges={commitChanges}
            />
            <SummaryState
              totalItems={totalSummaryItems}
            />

            <IntegratedSorting />
            <IntegratedPaging />
            <IntegratedSummary />

            <CurrencyTypeProvider for={currencyColumns} />
            <PercentTypeProvider for={percentColumns} />

            <DragDropProvider />

            <Table
              columnExtensions={tableColumnExtensions}
              cellComponent={Cell}
            />
            <TableColumnReordering
              order={columnOrder}
              onOrderChange={setColumnOrder}
            />
            <TableHeaderRow showSortingControls />
            <TableEditRow
              cellComponent={EditCell}
            />
            <TableEditColumn
              width={170}
              showAddCommand={!addedRows.length}
              showEditCommand
              showDeleteCommand
              commandComponent={Command}
            />
            <TableSummaryRow />
            <TableFixedColumns
              leftColumns={leftFixedColumns}
            />
            <PagingPanel
              pageSizes={pageSizes}
            />
          </Grid>
          </Paper>
        </div>
      </div>
    </React.Fragment>
)}

export default withTranslation ()(GPSPlatforms);
*/