import './styles.scss';
import React, {useEffect, useState} from 'react';
import { withTranslation } from 'react-i18next';
import {default as UUID} from "node-uuid";
import Toolbar, {Item} from 'devextreme-react/toolbar';

import L from 'leaflet';

import { HOST_API } from "../../../env";
import { sendRequest } from '../../../utils/utils';

function GPSMap({t}) {


  // const [GPSMapObjReady, setGPSMapObjReady] = useState(false);
  
  let GPSMapMarkers = null;
  let GPSMapObj = null;
  let GPSMapContainer = null;
  let GPSMapLatLngs = [];
  let GPSMapOldLatLngs = [];
  let GPSAutoRefreshId = null;
  let maxGPSAutoRefresh = 40;
  let nbGPSRefresh = 0;
  // var MarkerIcon = L.Icon.extend({
  //   options: {
  //     iconUrl: '//raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
  //     shadowUrl: '//cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  //     iconSize: [17, 28],
  //     iconAnchor: [8, 28],
  //     popupAnchor: [1, -34],
  //     shadowSize: [28, 28]
  //   }
  // });

  // let redMarker = new MarkerIcon({iconUrl: "//raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png"});
  

  const movingIcon = L.divIcon({
    html: `
    <svg viewBox="223.563 107.024 39.716 49.361" xmlns="http://www.w3.org/2000/svg">
  <path style="stroke-opacity: 0.75; paint-order: markers; stroke-width: 5px; stroke: rgba(255, 249, 239, 0.87); fill: rgba(251, 130, 0, 0.99);" d="M 263.279 107.024 L 223.563 138.083 L 247.906 135.449 L 256.754 156.385 L 263.279 107.024 Z"/>
</svg>`,
    className: "",
    iconSize: [18, 30],
    iconAnchor: [17, 0],
  });

  const stationaryIcon = L.divIcon({
    html: `
    <svg viewBox="216.887 248.998 17.224 18.085" xmlns="http://www.w3.org/2000/svg">
  <rect x="216.887" y="248.998" width="17.224" height="18.085" style="stroke-miterlimit: 1; stroke-linecap: round; fill-opacity: 0.78; stroke-dashoffset: -50px; stroke-width: 3px; stroke: rgb(255, 246, 223); paint-order: fill; stroke-linejoin: round; fill: rgb(255, 132, 0);"/>
</svg>`,
    className: "",
    iconSize: [14, 24],
    iconAnchor: [17, 1],
  });

  const generateKey = (pre) => {
    return `${ pre }_${ new Date().getTime() }`;
  }

  const InitializeGPSMap = () =>{
    // if(GPSMapObjReady === true) return null;
    GPSMapContainer = document.getElementById('GPSMapDiv');
    GPSMapMarkers = L.layerGroup();
    
    let attribution = '&copy; <a href="https://navetrack.com">NaveTrack</a>';
    let baseLayers = {
      "OSM Mapnik": L.tileLayer('//{s}.tile.osm.org/{z}/{x}/{y}.png', {attribution: attribution}),
      "Satellite ArcGIS": L.tileLayer("//server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}", {attribution: attribution}),
      "Dark": L.tileLayer('//{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png', {attribution: attribution}),
      // "Stadia Dark": L.tileLayer('//tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png', {attribution: attribution}),
      // "Landscape": L.tileLayer('//{s}.tile.thunderforest.com/landscape/{z}/{x}/{y}.png', {attribution: attribution}),
    };
    console.log("** InitializeGPSMap **")
    // return null;
    GPSMapObj = L.map(GPSMapContainer, {
      fullscreenControl: true,
      attributionControl: false,
      center: [35.7545,-5.8324],
      zoom: 12,
      layers: [baseLayers["OSM Mapnik"]]
    });
    L.control.layers(baseLayers).addTo(GPSMapObj);
    
    // Setup GeoLocation option
    const locateOptions = {
      position: 'topleft', //topright
      strings: {
        title: 'Get my current location'
      },
      onActivate: () => {} 
    }
    GPSMapObj.addControl(L.control.locate(locateOptions));


    let GPSMapTitle = L.control({ position: "topleft" });
    GPSMapTitle.onAdd = function() {
      var div = L.DomUtil.create("div", "title");
      div.innerHTML += "<h4>"+t("Real-time Vehicle' tracking")+"</h4>";
      return div;
    };
    GPSMapTitle.addTo(GPSMapObj);
    
    let GPSMapLastUpdate = L.control({ position: "bottomright" });
    GPSMapLastUpdate.onAdd = function() {
      var div = L.DomUtil.create("div", "GPSLastUpdate");
      div.innerHTML += "<div>"+t("Refresh rate: 30s")+"</div>"+
      '<div id="GPSLastUpdate"></div>';
      return div;
    };
    GPSMapLastUpdate.addTo(GPSMapObj);

    // GPSMapObj.on("load", () => {
      // setGPSMapObjReady(true);
      // console.log("VIEW RESET")
      // clearInterval(GPSAutoRefreshId);
      // RefreshMarkers();
      // GPSAutoRefreshId = setInterval(RefreshMarkers, 30000);
    // });

  }

  const RefreshMarkers = () => {
    // console.log("RefreshMarkers")
    // return null;
    nbGPSRefresh++;
    if(nbGPSRefresh>=maxGPSAutoRefresh){
      clearInterval(GPSAutoRefreshId);
      return null;
    }
    let freshUUID = UUID.v4();
    GPSMapContainer.setAttribute('uuid', freshUUID)

    GPSMapLatLngs = [];
    sendRequest(`${HOST_API}/v/vehicles/gps/`, null, 'GET', {uuid: freshUUID})
    .then(response => {
      // console.log("response.uuid");
      // console.log(response.uuid);
      // console.log("response.uuid:"+response.uuid+ "<->"+GPSMapContainer.getAttribute('uuid'));
      if(!response.uuid  ||  GPSMapContainer.getAttribute('uuid') !== response.uuid){
        console.log("response uuid does not match current map uuid");
        return false;
      }
      let GPSLastUpdateVal = null;
      
      try {
        GPSMapMarkers.clearLayers();
      } catch (error) {}
      GPSMapMarkers.clearLayers();

      response.data.forEach((item, i) => {
        GPSMapLatLngs.push([item.glts_geo_lat, item.glts_geo_lng]);
        GPSLastUpdateVal = item.glts_updated;
        let markerIcon = (item.glts_geo_state === 'stationary') ? stationaryIcon : movingIcon;
        GPSMapMarkers.addLayer(
          L.marker(
            [item.glts_geo_lat, item.glts_geo_lng],
            {
              id: item.v_id_v,
              icon: markerIcon,
            }
          )
          .bindPopup("<h6>"+item.v_label+" ( "+item.v_registration_plate+")</h6><ul>"+
          "<li><b>State:</b> "+item.glts_geo_state+"</li>"+
          "<li><b>Last update:</b> "+item.glts_geo_time+"</li>"+
          "</ul>")
          .bindTooltip('<div class="">'+item.v_label+ ' ( '+item.v_registration_plate+')</div>'/*, {permanent: true, offset: [-70, 40] }*/)
        );
        document.getElementById('GPSLastUpdate').innerHTML = GPSLastUpdateVal;
      });
      // console.log("lentgh::"+GPSMapLatLngs.length)
      // console.log(GPSMapLatLngs)
      if(GPSMapLatLngs.length>0){
        if(JSON.stringify(GPSMapLatLngs) !== JSON.stringify(GPSMapOldLatLngs)){
          GPSMapOldLatLngs = GPSMapLatLngs;
          GPSMapObj.addLayer(GPSMapMarkers);
          let GPSMAPsBounds = new L.LatLngBounds(GPSMapLatLngs);
          // console.log("lentgh::"+GPSMapLatLngs.length)
          // console.log(GPSMapLatLngs)
          // console.log("GPSMAPsBounds")
          // console.log(GPSMAPsBounds)
          setTimeout(() => {
            try {
              console.log("response.uuid:"+response.uuid+ "<->"+GPSMapContainer.getAttribute('uuid'));
              GPSMapObj.flyToBounds(GPSMAPsBounds,  {padding: [30, 40], duration:0.35} );
            } catch (error) {
              
            }
          }, 200);
        }
      }
    })
  }

  useEffect(() => {
    // setTimeout(InitializeGPSMap, 3000);
    InitializeGPSMap()
    RefreshMarkers()
    GPSAutoRefreshId = setInterval(RefreshMarkers, 30000);
    return () => clearInterval(GPSAutoRefreshId);
  }, []);

  return (
    <React.Fragment>
      
      <div className={'dx-card GPSMap'}>
        {/* <Toolbar>
          <Item>
            <h4>{t("Real time Vehicles' tracking")}</h4>
          </Item>
          <Item location="after">
            <span className="label">
              {t("Auto-refresh every 30s")}
              <div id="GPSLastUpdate"></div>
            </span>
          </Item>
          <Item location="after">
            
          </Item>
        </Toolbar> */}
        <div id="GPSMapDiv">
          Loading Map ..
        </div>
      </div>
      
    </React.Fragment>
)}

export default withTranslation ()(GPSMap);
